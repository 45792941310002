import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PaymentSandbox } from '../../../../../../core/payment/payment.sandbox';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
    Validators,
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxPaypalComponent, PayPalScriptService } from 'ngx-paypal';
import { PaymentService } from '../../../../../../core/payment/payment.service';
import { CommonSandbox } from '../../../../../../core/common/common.sandbox';
import { GatewaySettingModel } from '../../../../../../core/payment/payment-model/gateway-setting.mode';


@Component({
    selector: 'app-payment-settings',
    templateUrl: './payment-settings.component.html',
    styleUrls: ['./payment-settings.component.scss']
})
export class PaymentSettingsComponent implements OnInit, OnDestroy {
    public userDetails = JSON.parse(localStorage.getItem('vendor-settings'));
    private subscriptions: Array<Subscription> = [];
    public settingsForm: FormGroup
    public stripeSettingsForm: FormGroup
    public selectionForm: FormGroup
    private gatewayData: any;
    public payPalConfig: any;
    public userData: any = {};
    public showIncomplete = true;
    public routeUrl = '';
    public payPalApi = null;
    public showPay = false;
    public width = 220;
    public height = 35;
    public shape = 'rect';
    public color = 'gold';
    public label = 'paypal';
    public layout = 'vertical';
    @ViewChild('payPalElem1') paypalComponent1?: NgxPaypalComponent;
    @Output() dataSubmitted = new EventEmitter();
    constructor(
        public paymentSandbox: PaymentSandbox,
        public formBuilder: FormBuilder,
        public router: Router,
        public toastr: ToastrService,
        public payPalScriptService: PayPalScriptService,
        public paymentService: PaymentService,
        public commonSandbox: CommonSandbox,
        public cd: ChangeDetectorRef
    ) { }

    public initConfig(clientId): void {

        this.showPay = true;
        this.payPalConfig = {
            currency: 'USD',
            clientId: clientId,

            createOrderOnServer: (data) => this.paymentService.createTestOrder({}).toPromise().then(response => {
                console.log(" ****** response ", response);
                return response.data.result.id;
            }),
            advanced: {
                commit: 'true'
            },

            onApprove: (data, actions) => {

            },

            onClientAuthorization: (data) => {
                this.toastr.success('Credentials verified successfully', 'Success');
            },
            onCancel: (data, actions) => {
            },
            onError: err => {
                console.log('OnError', err);
            },
        }

        // if (this.payPalApi) {
        //     this.payPalScriptService.destroyPayPalScript();
        //     this.payPalScriptService.registerPayPalScript(this.payPalConfig, (payPalApi) => {
        //         if (this.paypalComponent1) {
        //             this.paypalComponent1.customInit(payPalApi);
        //             this.cd.detectChanges();
        //         }
        //     });
        // } else {
        //     this.payPalScriptService.registerPayPalScript(this.payPalConfig, (payPalApi) => {
        //         if (this.paypalComponent1) {
        //             this.paypalComponent1.customInit(payPalApi);
        //         }
        //     });
        // }




    }

    ngOnInit() {
        this.routeUrl = this.router.url;
        this.initPaypalSettingsForm();
        this.initStripeSettingsForm();

        this.selectionForm = this.formBuilder.group({
            gatewayType: ['paypal']
        })

        this.subscriptions.push(
            this.commonSandbox.getProfile$.subscribe(data => {
                if (data && Object.keys(data).length) {
                    this.userData = data;
                }
            })
        );

        this.paymentService.getGatewaySetting({ key: 'paypal' }).toPromise().then((response: any) => {
            if (Object.keys(response.data).length > 0) {
                this.gatewayData = response.data;
                this.setSettingsForm(response.data);
            }
        });

        this.paymentService.getGatewaySetting({ key: 'stripe' }).toPromise().then((response: any) => {
            if (Object.keys(response.data).length > 0) {
                this.gatewayData = response.data;
                this.setStripeSettingsForm(response.data);
            }
        });


        $('button.save').on('click', function () {
            $('html, body').animate({
              'scrollTop': $('.bd-tab-content').position()['top']
            });
          });
    }

    initPaypalSettingsForm() {
        this.settingsForm = this.formBuilder.group({
            key: ['paypal', Validators.required],
            clientId: ['', Validators.required],
            clientSecret: ['', Validators.required],
            status: ['', Validators.required]
        });
    }

    initStripeSettingsForm() {
        this.stripeSettingsForm = this.formBuilder.group({
            key: ['stripe', Validators.required],
            stripeClientSecret: ['', Validators.required],
            stripeApiKey: ['', Validators.required],
            status: ['', Validators.required]
        });
    }

    setStripeSettingsForm(data) {
        this.initConfig(data.settings.clientId);
        this.stripeSettingsForm.controls['stripeApiKey'].setValue(data.settings.stripeApiKey);
        this.stripeSettingsForm.controls['stripeClientSecret'].setValue(data.settings.stripeClientSecret);
        this.stripeSettingsForm.controls['status'].setValue(data.status);
    }

    setSettingsForm(data) {
        this.initConfig(data.settings.clientId);
        this.settingsForm.controls['clientId'].setValue(data.settings.clientId);
        this.settingsForm.controls['clientSecret'].setValue(data.settings.clientSecret);
        this.settingsForm.controls['status'].setValue(data.status);
    }


    submit() {
        if (!this.settingsForm.valid) {
            return;
        }
        this.showPay = false;
        let data: any = { settings: {}, key: '' };
        data.key = this.settingsForm.controls['key'].value;
        data.status = this.settingsForm.controls['status'].value;
        data.settings.clientId = this.settingsForm.controls['clientId'].value;
        data.settings.clientSecret = this.settingsForm.controls['clientSecret'].value;

       return this.paymentService.saveGatewaySetting(new GatewaySettingModel(data)).toPromise().then((response: any) => {
            if (response) {
                this.toastr.success('Settings updated successfully', 'Success');
                this.paymentSandbox.getPaymentGatewaySettings({ key: 'paypal' });
                //window.location.reload();
                this.initConfig(data.settings.clientId);
                this.commonSandbox.doGetProfile();
                //  this.dataSubmitted.emit('complete');
            }
        });

    }

    submitAndComplete(){
       // this.submit().then(() => {
            this.dataSubmitted.emit('complete');
       // })
    }


    submitStripeAndComplete(){
       // this.submitStripe().then(() => {
            this.dataSubmitted.emit('complete');
      //  })
    }

    onDataSubmitted() {
        this.dataSubmitted.emit('complete');
    }

    submitStripe() {
        if (!this.stripeSettingsForm.valid) {
            return;
        }
        this.showPay = false;
        let data: any = { settings: {}, key: '' };
        data.key = this.stripeSettingsForm.controls['key'].value;
        data.status = this.stripeSettingsForm.controls['status'].value;
        data.settings.stripeApiKey = this.stripeSettingsForm.controls['stripeApiKey'].value;
        data.settings.stripeClientSecret = this.stripeSettingsForm.controls['stripeClientSecret'].value;


       return this.paymentService.saveGatewaySetting(new GatewaySettingModel(data)).toPromise().then((response: any) => {
            if (response) {
                this.toastr.success('Settings updated successfully', 'Success');
                this.paymentSandbox.getPaymentGatewaySettings({ key: 'paypal' });
                //window.location.reload();
                this.initConfig(data.settings.clientId);
                this.commonSandbox.doGetProfile();
                // this.dataSubmitted.emit('complete');
            }
        });


    }

    ngOnDestroy() {
        this.subscriptions.forEach(each => each.unsubscribe());
    }
}
