import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl
} from '@angular/forms';
import { ConfigService } from '../../../../../../core/services/config.service';
import { SettingsSandbox } from '../../../../../../core/settings/settings.sandbox';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlatRateShippingModel } from '../../../../../../core/settings/models/flat-rate-shipping.model';
import { CommonSandbox } from '../../../../../../core/common/common.sandbox';

@Component({
    selector: 'app-flat-rate-shipping',
    templateUrl: './flat-rate-shipping.component.html',
    styleUrls: ['./flat-rate-shipping.component.scss']
})
export class FlatRateShippingMethodComponent implements OnInit, OnDestroy {

    public shippingMethodForm: FormGroup;
    public subscriptions: Array<any>;
    public userData: any = {};
    public showIncomplete = true;
    public routeUrl = '';
    @Output() dataSubmitted = new EventEmitter();
    constructor(
        public modal: NgbModal,
        public router: Router,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder,
        public configService: ConfigService,
        public settingSandbox: SettingsSandbox,
        public toastr: ToastrService,
        public commonSandbox: CommonSandbox
    ) { }

    ngOnInit() {
        this.subscriptions = [];
        this.initiateShippingMethodForm();
        this.routeUrl = this.router.url;

        this.settingSandbox.getShippingMethod({ key: "flat-rate-shipping" });


        this.subscriptions.push(
            this.commonSandbox.getProfile$.subscribe(data => {
                if (data && Object.keys(data).length) {
                    this.userData = data;
                }
            })
        );

        this.subscriptions.push(this.settingSandbox.shippingMethod$.subscribe((data) => {
            if (Object.keys(data).length > 0) {
                this.setShippingMethodFormData(new FlatRateShippingModel(data));
            } else {
                this.setShippingMethodFormData(new FlatRateShippingModel(data));
            }
        }));
    }

    initiateShippingMethodForm() {
        this.shippingMethodForm = this.formBuilder.group({
            title: ['', Validators.required],
            methodName: ['', Validators.required],
            key: ['flat-rate-shipping', Validators.required],
            status: [0, Validators.required],
            amount: [0, Validators.required],
        });

    }

    setShippingMethodFormData(data) {
        this.shippingMethodForm.controls['key'].setValue(data.key);
        this.shippingMethodForm.controls['title'].setValue(data.title);
        this.shippingMethodForm.controls['methodName'].setValue(data.methodName);
        this.shippingMethodForm.controls['status'].setValue(data.status);
        this.shippingMethodForm.controls['amount'].setValue(data.settings.amount);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }


    submit() {
        if (!this.shippingMethodForm.valid) {
            this.validateAllFormFields(this.shippingMethodForm);
            return;
        }

        const params: any = { settings: {} };

        params.title = this.shippingMethodForm.controls['title'].value;
        params.key = this.shippingMethodForm.controls['key'].value;
        params.status = this.shippingMethodForm.controls['status'].value;
        params.methodName = this.shippingMethodForm.controls['methodName'].value;
        params.settings.amount = this.shippingMethodForm.controls['amount'].value;

        this.settingSandbox.updateShippingMethod(new FlatRateShippingModel(params));

        this.subscriptions.push(this.settingSandbox.updateShippingMethodLoaded$.subscribe(data => {
            if (data === true) {
                this.settingSandbox.getShippingMethod({ key: "flat-rate-shipping" });
                this.toastr.success('Shipping method updated successfully', 'Success');
                this.commonSandbox.doGetProfile();
                this.dataSubmitted.emit('payment');
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(each => each.unsubscribe());
    }
}
