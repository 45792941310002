import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl
} from '@angular/forms';
import { ConfigService } from '../../../../../../core/services/config.service';
import { SettingsSandbox } from '../../../../../../core/settings/settings.sandbox';
import { ActivatedRoute, Router } from '@angular/router';
import { FreeShippingModel } from '../../../../../../core/settings/models/free-shipping.model';
import { ToastrService } from 'ngx-toastr';
import { CommonSandbox } from '../../../../../..//core/common/common.sandbox';

@Component({
    selector: 'app-free-shipping-method',
    templateUrl: './free-shipping-method.component.html',
    styleUrls: ['./free-shipping-method.component.scss']
})
export class FreeShippingMethodComponent implements OnInit, OnDestroy {

    public shippingMethodForm: FormGroup;
    public subscriptions: Array<any>;
    public userData: any = {};
    public showIncomplete = true;
    public isShipping: 0;
    public isPublishFreeShipping: 0;
    public routeUrl = '';
    @Output() dataSubmitted = new EventEmitter();
    constructor(
        public modal: NgbModal,
        public router: Router,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder,
        public configService: ConfigService,
        public settingSandbox: SettingsSandbox,
        public toastr: ToastrService,
        public commonSandbox: CommonSandbox
    ) { }

    ngOnInit() {
        this.subscriptions = [];
        this.initiateShippingMethodForm();
        this.routeUrl = this.router.url;
        this.subscriptions.push(
            this.commonSandbox.getProfile$.subscribe(data => {
                if (data && Object.keys(data).length) {
                    this.userData = data;
                }
            })
        );

        this.settingSandbox.getShippingMethod({ key: "free-shipping" });

        this.subscriptions.push(this.settingSandbox.shippingMethod$.subscribe((data) => {
            if (Object.keys(data).length > 0) {
                console.log("data");
                console.log(data);
                this.isShipping = data?.settings?.isShipping;
                this.isPublishFreeShipping = data?.settings?.isPublishFreeShipping; 
                this.setShippingMethodFormData(new FreeShippingModel(data));
            } else {
                this.setShippingMethodFormData(new FreeShippingModel(data));
            }
        }));

        $('button.save').on('click', function () {

            $('html, body').animate({
              'scrollTop': $('.bd-tab-content').position()['top']
            });
          });

    }

    initiateShippingMethodForm() {
        this.shippingMethodForm = this.formBuilder.group({
            title: ['', Validators.required],
            methodName: ['', Validators.required],
            key: ['free-shipping', Validators.required],
            status: [0, Validators.required],
            minimumOrderAmount: ['', Validators.required]
        });

    }

    setShippingMethodFormData(data) {
        this.shippingMethodForm.controls['key'].setValue(data.key);
        this.shippingMethodForm.controls['title'].setValue(data.title);
        this.shippingMethodForm.controls['status'].setValue(data.status);
        this.shippingMethodForm.controls['methodName'].setValue(data.methodName);
        this.shippingMethodForm.controls['minimumOrderAmount'].setValue(data.settings.minimumOrderAmount);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    doAction(event) {
        if (event.target.checked) {
            const params: any = { settings: {} };

            params.title = 'Free Shipping';
            params.key = 'free-shipping';
            params.status = this.shippingMethodForm.controls['status'].value || 1;
            params.methodName = 'Free Shipping';
            params.settings.minimumOrderAmount = this.shippingMethodForm.controls['minimumOrderAmount'].value || 0;
            params.settings.isShipping = 0;

            this.settingSandbox.updateShippingMethod(new FreeShippingModel(params));

            this.subscriptions.push(this.settingSandbox.updateShippingMethodLoaded$.subscribe(data => {
                if (data === true) {
                    this.settingSandbox.getShippingMethod({ key: "free-shipping" });
                    this.toastr.success('Shipping method updated successfully', 'Success');
                    this.commonSandbox.doGetProfile();
                    this.dataSubmitted.emit('payment');
                }
            }));
        } else {
            const params: any = { settings: {} };

            params.title = 'Free Shipping';
            params.key = 'free-shipping';
            params.status = this.shippingMethodForm.controls['status'].value || 0;
            params.methodName = 'Free Shipping';
            params.settings.minimumOrderAmount = this.shippingMethodForm.controls['minimumOrderAmount'].value || 0;
            params.settings.isShipping = 1;
            this.settingSandbox.updateShippingMethod(new FreeShippingModel(params));

            this.subscriptions.push(this.settingSandbox.updateShippingMethodLoaded$.subscribe(data => {
                if (data === true) {
                    this.settingSandbox.getShippingMethod({ key: "free-shipping" });
                    this.toastr.success('Shipping method updated successfully', 'Success');
                    this.commonSandbox.doGetProfile();
                    this.dataSubmitted.emit('payment');
                }
            }));
        }
    }

    doActionFreeShiping(event) {
        if (event.target.checked) {
            const params: any = { settings: {} };

            params.title = 'Free Shipping';
            params.key = 'free-shipping';
            params.status = this.shippingMethodForm.controls['status'].value || 1;
            params.methodName = 'Free Shipping';
            params.settings.minimumOrderAmount = this.shippingMethodForm.controls['minimumOrderAmount'].value || 0;
            params.settings.isPublishFreeShipping = 1;


            this.settingSandbox.updateShippingMethod(new FreeShippingModel(params));

            this.subscriptions.push(this.settingSandbox.updateShippingMethodLoaded$.subscribe(data => {
                if (data === true) {
                    this.settingSandbox.getShippingMethod({ key: "free-shipping" });
                    this.toastr.success('Shipping method updated successfully', 'Success');
                    this.commonSandbox.doGetProfile();
                    this.dataSubmitted.emit('payment');
                }
            }));
        } else {
            const params: any = { settings: {} };

            params.title = 'Free Shipping';
            params.key = 'free-shipping';
            params.methodName = 'Free Shipping';
            params.settings.minimumOrderAmount = this.shippingMethodForm.controls['minimumOrderAmount'].value || 0;
            params.settings.isPublishFreeShipping = 0;
            this.settingSandbox.updateShippingMethod(new FreeShippingModel(params));

            this.subscriptions.push(this.settingSandbox.updateShippingMethodLoaded$.subscribe(data => {
                if (data === true) {
                    this.settingSandbox.getShippingMethod({ key: "free-shipping" });
                    this.toastr.success('Shipping method updated successfully', 'Success');
                    this.commonSandbox.doGetProfile();
                    this.dataSubmitted.emit('payment');
                }
            }));
        }
    }

    submit() {
        if (!this.shippingMethodForm.valid) {
            this.validateAllFormFields(this.shippingMethodForm);
            return;
        }

        const params: any = { settings: {} };

        params.title = this.shippingMethodForm.controls['title'].value;
        params.key = this.shippingMethodForm.controls['key'].value;
        params.status = this.shippingMethodForm.controls['status'].value;
        params.methodName = this.shippingMethodForm.controls['methodName'].value;
        params.settings.minimumOrderAmount = this.shippingMethodForm.controls['minimumOrderAmount'].value;

        this.settingSandbox.updateShippingMethod(new FreeShippingModel(params));

        this.subscriptions.push(this.settingSandbox.updateShippingMethodLoaded$.subscribe(data => {
            if (data === true) {
                this.settingSandbox.getShippingMethod({ key: "free-shipping" });
                this.toastr.success('Shipping method updated successfully', 'Success');
                this.commonSandbox.doGetProfile();
                this.dataSubmitted.emit('payment')
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(each => each.unsubscribe());
    }
}
