import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../../../../app/core/settings/settings.service';
import { AISandbox } from '../../../../../app/core/ai/ai.sandbox';
import { CustomerGroupService } from '../../../../../app/core/groups/group.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { PromocodeService } from '../../../../../app/core/promocode/promocode.service';
import { RecordAudioService } from '../../../../../app/core/record-audio/record-audio.service';
import { CommonService } from '../../../../../app/core/common/common.service';
import { OrderService } from '../../../../../app/core/order/order.service';
import * as _ from 'lodash';
import { OrderSandbox } from '../../../../../app/core/order/order.sandbox';
import { skip, take } from 'rxjs/operators';
import { ProductService } from '../../../../../app/core/product/product.service';
import { BlogService } from '../../../../../app/core/blogs/blog.service';
import { CommonSandbox } from '../../../../../app/core/common/common.sandbox';


@Component({
  selector: 'app-aichatbot',
  templateUrl: './ai-chatbot.component.html',
  styleUrls: ['./ai-chatbot.component.scss']
})
export class AichatbotComponent implements OnInit, OnDestroy {
  status: boolean = false;
  status1: boolean = false;
  public show = false;
  public loading = false;
  public masterClose = false;
  public type = 1;
  public message = '';
  public selectedHomepage = 0;
  public chats = [];
  public subscriptions = [];
  public boxes = [];
  public promocode = '';
  public selectedBox = '';
  public orderId = '';
  public promocodeDescription = '';
  public promocodeType = '';
  public promocodeStartDate = '';
  public interval = null;
  public promocodeEndDate = '';
  public promotionalValue = '';
  public speaking = false;
  public transcribe = false;
  public suggestedText = '';
  public isTextSearch = false;
  public upsMethods = [];
  public selectedValue = '';
  public shippingMethodCode = '';
  public order = null;
  public products = [];

  public styles = [
    "Realism",
    "Minimalism",
    "Retro/Vintage",
    "Lettering",
    "Geometric",
    "Vector",
    "Art",
    "Illustration",
    "Surrealism",
    "Psychedelic"
  ];

  public promocodeTypes = [
    {
      value: 1,
      label: "2 For 1"
    },
    {
      value: 3,
      label: "Half Price"
    },
    {
      value: 5,
      label: "Percentage Discount"
    },
    {
      value: 2,
      label: "Amount Discount"
    }
  ];

  public couponCodeTypes = [
    {
      value: 5,
      label: "Percentage Discount"
    },
    {
      value: 2,
      label: "Amount Discount"
    }
  ];


  public step;
  public tab;

  public userData;

  public industry = [
    'Food',
    'Electronics',
    'Entertainment',
    'Agriculture',
    'Clothing',
    'Computers and IT',
    'Construction',
    'Healthcare',
    'Manufacturing',
    "Retail",
    "Tourism",
    "Textile"
  ]

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  

  constructor(
    public recorder: RecordAudioService,
    public settingService: SettingsService,
    public aiSandbox: AISandbox,
    public orderSandbox: OrderSandbox,
    public groupService: CustomerGroupService,
    public toastr: ToastrService,
    public promocodeService: PromocodeService,
    public commonSandbox: CommonSandbox,
    public router: Router,
    public commonService: CommonService,
    private productService: ProductService,
    private blogService: BlogService,
    public orderService: OrderService,
  ) {

  }
  ngOnInit() {
    
    this.subscriptions.push(this.orderSandbox.orderDetail$.subscribe(data => {
			if (data) {
				this.orderId = data.orderPrefixId;
			}
		}));


    this.subscriptions.push(this.aiSandbox.masterClose$.subscribe((data: any) => {
		
				this.masterClose = data;
			
		}));


    this.subscriptions.push(this.commonSandbox.getProfile$.subscribe(profile => {
      if (profile) {
        this.userData = profile.customerDetail;
    
      }
    }));

    this.subscriptions.push(this.aiSandbox.toggleAI$.pipe(skip(1)).subscribe((data: boolean) => {
      
        speechSynthesis.cancel();
        this.status1 = !this.status1;
       this.status = false;
    }))

    this.subscriptions.push(this.aiSandbox.tab$.subscribe((tab: string) => {
      this.tab= tab;
  }));


  this.subscriptions.push(this.aiSandbox.step$.subscribe((step: string) => {
    this.step= step;
}))


    this.subscriptions.push(this.aiSandbox.message$.pipe(skip(1)).subscribe((data: any) => {

      // if(this.router.url !== '/settings/initial-setup'){
      //   return;
      // }

    

      speechSynthesis.cancel();
      this.chats = [];
      if(data?.open){
        this.status = true;
     
      } else {
       this.status =false;
       this.status1 = false;

      }

      if(!data?.message){
        return;
      }

      this.chats.push({
        message: data.message,
        left: true,
        question: false,
        answer: false,
        type: data.type
      });
      this.speakText(data.message);
  }))

    this.subscriptions.push(this.aiSandbox.questionType$.subscribe((type) => {

      console.log(" **** masterClose ", this.masterClose);

      if(type){
        this.type = type;
        if(!this.masterClose){
          this.chats = [];
          this.getAssistanceQuestion();
        }
      }
    }))
  }

  getAssistanceQuestion(){
    this.settingService.getAssitanceQuestion({ type: this.type }).toPromise().then((response) => {
      this.status = true;
      this.chats.push({
        message: response.data.question,
        id: response.data.id,
        left: true,
        question: true
      });
    })
  }

  modifyProducts() {
    this.subscriptions.push(this.settingService.getAllProductIds({ orderId: this.order.vendorOrderId }).subscribe((response) => {
      if (response) {
          console.log("response");
          console.log(response);
          let orderIds = response.data;

          console.log("***** list of products ****");
          console.log(this.products);
          this.products.map(item => {
              if (orderIds.find(item2 => item2.productId == item.productId)) {

                  orderIds.forEach(orderItem => {
                      console.log("in map order product id");
                      console.log(orderItem.productId);

                      console.log("in map item product id");
                      console.log(item.productId);

                      if (orderItem.productId == item.productId) {
                          item.shippedQuantity = orderItem.shippedQuantity;
                          item.remainQuantity = parseFloat(item.quantity) - parseFloat(item.shippedQuantity);
                      }
                  });
                  item.actualWeight = item.weight;
                  item.actualQuantity = item.quantity;
                  item.weight = item.weight * item.remainQuantity;
                  item.MaxQuantity = item.remainQuantity;

              } else {
                  item.remainQuantity = item.quantity;
                  item.actualWeight = item.weight;
                  item.actualQuantity = item.quantity;
                  item.weight = item.weight * item.remainQuantity;
                  item.MaxQuantity = item.remainQuantity;

                  return item;
              }

          });
          this.products = this.products.filter(item => {
              if (orderIds.indexOf(item.productId + '') === -1 && item.quantity == item.shippedQuantity) {
                  return false;
              } else {
                  return true;
              }
          });
      }
      this.loading = false;
  }, (err) => {

  }));
  }

  stop(type = 0) {
    console.log("** stopeed ")
    this.speaking = false;
    this.transcribe = false;
    this.recorder.stop()
        .then(([buffer, audioAsblob]) => { //stopping makes promise resolves to the blob file of the recorded audio
            console.log("stopped with audio Blob:", audioAsblob);
            this.isTextSearch = true;
            if (type == 0) {
                this.playAudio(audioAsblob);
            } else {
                this.transcribeAudio(audioAsblob);
            }

        })
        .catch(error => {
            //Error handling structure
            switch (error.name) {
                case 'InvalidStateError': //error from the MediaRecorder.stop
                    console.log("An InvalidStateError has occured.");
                    break;
                default:
                    console.log("An error occured with the error name " + error.name);
            };

        });
}


playAudio(recorderAudioAsBlob) {

  //read content of files (Blobs) asynchronously
  let reader = new FileReader();
  console.log(" ***  recorderAudioAsBlob ", recorderAudioAsBlob);
  //once content has been read
  reader.onload = (e) => {
      //store the base64 URL that represents the URL of the recording audio
      let base64URL = e.target.result;

      this.getAnswer(base64URL);
      //If this is the first audio playing, create a source element
      //as pre populating the HTML with a source of empty src causes error
      // if (!this.audioElementSource) //if its not defined create it (happens first time only)
      //     this.createSourceForAudioElement();

      //set the audio element's source using the base64 URL
      //  this.audioElementSource.src = base64URL;

      //set the type of the audio element based on the recorded audio's Blob type
      // let BlobType = recorderAudioAsBlob.type.includes(";") ?
      //     recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(';')) : recorderAudioAsBlob.type;
      // this.audioElementSource.type = BlobType

      //call the load method as it is used to update the audio element after changing the source or other settings
      // this.audioElement.load();

      //play the audio after successfully setting new src and type that corresponds to the recorded audio
      // console.log("Playing audio...");
      // this.audioElement.play();


  };

  //read content and convert it to a URL (base64)
  reader.readAsDataURL(recorderAudioAsBlob);
}


transcribeAudio(recorderAudioAsBlob) {

  //read content of files (Blobs) asynchronously
  let reader = new FileReader();
  console.log(" ***  recorderAudioAsBlob ", recorderAudioAsBlob);
  //once content has been read
  reader.onload = (e) => {
      //store the base64 URL that represents the URL of the recording audio
      let base64URL = e.target.result;
      this.loading = true;
      this.suggestedText = '';
      this.commonService.getTranslation({
          audio: base64URL
      }).toPromise().then((response) => {
        this.chats.push({
          message: response.data,
          left: true,
          question: false,
          answer: false,
        });
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        this.loading = false;
        console.log(" *** response ", response);
      }).catch(err => {
          this.loading = false;
          console.log(" **** err ", err);
      })
      //If this is the first audio playing, create a source element
      //as pre populating the HTML with a source of empty src causes error
      // if (!this.audioElementSource) //if its not defined create it (happens first time only)
      //     this.createSourceForAudioElement();

      //set the audio element's source using the base64 URL
      //  this.audioElementSource.src = base64URL;

      //set the type of the audio element based on the recorded audio's Blob type
      // let BlobType = recorderAudioAsBlob.type.includes(";") ?
      //     recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(';')) : recorderAudioAsBlob.type;
      // this.audioElementSource.type = BlobType

      //call the load method as it is used to update the audio element after changing the source or other settings
      // this.audioElement.load();

      //play the audio after successfully setting new src and type that corresponds to the recorded audio
      // console.log("Playing audio...");
      // this.audioElement.play();


  };

  //read content and convert it to a URL (base64)
  reader.readAsDataURL(recorderAudioAsBlob);
}

getOrder(param){
 return this.orderService.getOrderId(param).toPromise();
}

fraudOrderes(param){
  return this.orderService.fraudOrderes(param).toPromise();
 }

  createGroup(name){
    const params: any = {};

    params.name = name;
    params.description = name;
    params.colorcode = '#ffffff',
    params.status = 1;
    params.users = [];
   
    this.groupService.createGroup(params).toPromise().then(response => {
        this.toastr.success('Group created successfully', 'Success');
        this.chats.push({
          message: "Group has been created",
          left: true,
          question: false,
          answer: false
        });
        setTimeout(() => {
          this.scrollToBottom();
        },0);
    });
    
  }


  speakText(textToSpeak) {
    let speakData = new SpeechSynthesisUtterance();

    let voices = speechSynthesis.getVoices();
    console.log(voices);
    speakData.voice = voices[1];

    speakData.volume = 1; // From 0 to 1
    speakData.text = textToSpeak.trim();
    speakData.lang = 'en';
 
    speechSynthesis.speak(speakData);
    speechSynthesis.resume();
}

  selectHomepage(chat){
    if(chat?.subType == 'homepage-industry'){
      localStorage.setItem("industry", chat.message);
      this.chats.push({
        message: chat.message,
        left: false,
        question: false,
        answer: false
      });
      setTimeout(() => {
        this.scrollToBottom();
        this.chats.push({
          message: "Please choose a style:",
          left: true,
          question: true,
          answer: false,
          subType : "homepage"
        });
        this.speakText("Please choose a style")
  
        for(let style of this.styles){
          this.chats.push({
            message: style,
            left: true,
            question: false,
            answer: true,
            select: true,
            subType: "homepage-style"
          });
         }
      },0);
  

   

      this.loading = false;
      return;
    }

    if(chat?.subType == 'homepage-style'){
      localStorage.setItem("style", chat.message);
      localStorage.setItem("homepageId", this.selectedHomepage+'');
      this.chats.push({
        message: chat.message,
        left: false,
        question: false,
        answer: false,
        subType: "homepage-image-prompt"
      });
      this.chats.push({
        message: "Write a prompt for generating relevant images",
        left: true,
        question: true,
        answer: false,
        subType: "homepage-image-prompt"
      });
      //
      this.speakText("Write a prompt for generating relevant images")
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
    
      return;
    }

    if(chat?.subType == 'homepage-image-prompt'){
      localStorage.setItem("imagePrompt", chat.message);

      this.chats.push({
        message: chat.message,
        left: false,
        question: false,
        answer: false
      });
      this.chats.push({
        message: "Your homepage is being generated",
        left: true,
        question: false,
        answer: false
      });
      //
      //this.speakText("Your homepage is being generated")
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.aiSandbox.textSuccess
      this.router.navigate(['/settings/homepage-template'],{ queryParams: { created: 1 } });
      this.aiSandbox.buildHomepage(true);
      this.loading = false;
      return;
    }

    this.selectedHomepage = chat.id;
    this.chats.push({
      image: chat.image,
      left: false,
      question: true,
      answer: false,
      subType : "homepage"
    });

    setTimeout(() => {
      this.scrollToBottom();
      this.chats.push({
        message: "What Type of industry are you in?",
        left: true,
        question: true,
        answer: false,
        subType : "homepage"
      });
      this.speakText("What Type of industry are you in?")
     for(let industry of this.industry){
      this.chats.push({
        message: industry,
        left: true,
        question: false,
        answer: true,
        select: true,
        subType : "homepage-industry"
      });
     }
    },0);

   

  }


  start() {
    console.log(" *** start ");
    this.recorder.start()
        .then(() => { //on success
          // const origOnaudioprocess = this.recorder.mediaRecorder.processor.onaudioprocess;

          // this.recorder.mediaRecorder.processor.onaudioprocess = function(event) {
          //   console.log(" *** audio is being processed");
          //   origOnaudioprocess(event);
          //


          if(this.interval){
            clearInterval(this.interval);
          }

          // Create an analyser node
          const analyser =  this.recorder.mediaRecorder.context.createAnalyser();
          analyser.fftSize = 2048;
    
          // Connect the source to the analyser
           this.recorder.mediaRecorder.microphone.connect(analyser);
    
          // // Start the speech detection loop
          this.interval = setInterval(() => {
            // Get the frequency data from the analyser
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            analyser.getByteFrequencyData(dataArray);
    
            // Calculate the average volume
            const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;
    
            console.log(" *** averageVolume ",averageVolume);

            // Adjust the threshold according to your needs
            const threshold = 10;
    
            // Check if the average volume exceeds the threshold
            const isSpeaking = averageVolume > threshold;
    
            // Do something with the result
            if (isSpeaking) {
              console.log('Speaking');
            } else {
              clearInterval(this.interval);
              this.stop(0);
            }
          }, 5000); 

        
          this.suggestedText = '';
          console.log("Recording Audio...")
        })
        .catch(error => { //on error
            this.speaking = false;
            this.transcribe = false;
            //No Browser Support Error
            if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
                console.log("To record audio, use browsers like Chrome and Firefox.");
                //Error handling structure
                switch (error.name) {
                    case 'AbortError': //error from navigator.mediaDevices.getUserMedia
                        console.log("An AbortError has occured.");
                        break;
                    case 'NotAllowedError': //error from navigator.mediaDevices.getUserMedia
                        console.log("A NotAllowedError has occured. User might have denied permission.");
                        break;
                    case 'NotFoundError': //error from navigator.mediaDevices.getUserMedia
                        console.log("A NotFoundError has occured.");
                        break;
                    case 'NotReadableError': //error from navigator.mediaDevices.getUserMedia
                        console.log("A NotReadableError has occured.");
                        break;
                    case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
                        console.log("A SecurityError has occured.");
                        break;
                    case 'TypeError': //error from navigator.mediaDevices.getUserMedia
                        console.log("A TypeError has occured.");
                        break;
                    case 'InvalidStateError': //error from the MediaRecorder.start
                        console.log("An InvalidStateError has occured.");
                        break;
                    case 'UnknownError': //error from the MediaRecorder.start
                        console.log("An UnknownError has occured.");
                        break;
                    default:
                        console.log("An error occured with the error name " + error.name);
                };
            }
        });
}

getTotal(orderData) {
  return _.round(parseFloat(orderData.total), 2);
}


updateOrderStatus() {
  console.log("update status");

  const params: any = {};
  params.vendorOrderId = this.order.orderId;
  params.subOrderStatusId = 4;
  params.code = this.selectedValue;
  params.label = this.upsMethods.find(item => item.code == this.selectedValue).label;
  params.key = this.selectedValue;

  let totalWeight = 0;
  this.products.forEach(item => {
    
    totalWeight = totalWeight + parseFloat(item.weight);
    
});

this.products.forEach(item => {
    item.quantity = item.remainQuantity;
    item.totalWeight = totalWeight;
    item.boxId = this.selectedBox;
    item.boxweight = this.boxes.find(item => item.id == this.selectedBox).weight;
});

  params.products = this.products;
  this.loading = true;

  this.orderSandbox.getOrderStatusUpdate(params);
  this.subscriptions.push(this.orderSandbox.updateOrderStatusLoaded$.pipe(take(2)).subscribe(data => {
    if (data) {
      this.loading = false;
      this.orderId = '';
      this.orderSandbox.doOrderDetail(this.order.vendorOrderId);
    
      this.chats.push({
        message: "Your shipment has been created successfully",
        left: true,
        question: true,
        answer: false,
        subType : "method-selected"
      });

      this.router.navigate(['/orders/all-orders'], {
        queryParams: {
          orderId: this.order.vendorOrderId
        }
      });

      setTimeout(() => {
        this.scrollToBottom();
      },0)

       // this.printPackingSlip();
      
      // this.getOrderList();
    }
  }));
  this.subscriptions.push(this.orderSandbox.updateOrderStatusFailed$.subscribe(data => {
    if(data){
      this.loading = false;
    }
  }))
}


choiceSelected(message: string){
  this.status1 = !this.status1;
  this.status = true;
  this.message = message;
  this.chats = [];
  this.getAnswer();
}

 async getAnswer(audio: any = '', emit = ''){
    speechSynthesis.cancel();
    if(!this.message && !audio){
      return;
    }

    if(this.loading){
      return;
    }

    if(audio){
      this.loading = true;
      let transacription = await this.settingService.transcribe({ audio: audio }).toPromise();
      this.message = transacription.data.answer;
    }

    const lastMessage = this.chats.filter(item => item.question).slice(-1)[0];
    
    let message = this.message;
    this.message = '';
    this.loading = true;
    if(message){
      this.chats.push({
        message: message,
        left: false,
        question: false,
        answer: false,
      });
    }
   
    setTimeout(() => {
      this.scrollToBottom();
    },0);
    


    if(lastMessage?.subType == 'homepage-image-prompt'){
      localStorage.setItem("imagePrompt", message);

      this.chats.push({
        message: message,
        left: false,
        question: false,
        answer: false
      });
      this.chats.push({
        message: "Your homepage is being generated",
        left: true,
        question: false,
        answer: false
      });
      //
      //this.speakText("Your homepage is being generated")
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.aiSandbox.textSuccess
      this.router.navigate(['/settings/homepage-template'],{ queryParams: { created: 1 } });
      this.aiSandbox.buildHomepage(true);
      this.loading = false;
      return;
    }


    if(lastMessage?.subType == 'shipment-yes'){
      if(message.toLowerCase() == 'yes'){
        this.updateOrderStatus();
        return;
      } else if (message.toLowerCase() == 'no'){
        this.loading = false;
        return;
      }
     
    }

    if(lastMessage?.subType == 'method-selected'){
    
      this.selectedValue = this.selectedValue.length === 3 ? this.selectedValue.replace(/^0+/, ""): this.selectedValue;

        this.chats.push({
          message: "Please review the following info",
          left: true,
          question: true,
          answer: false,
          subType : "method-selected"
        });
        this.speakText("Please review the following info")

        this.chats.push({
          message: "Order No: " + this.order.orderPrefixId,
          left: true,
          question: true,
          answer: false,
          subType : "method-selected"
        });


        this.chats.push({
          message: "Shipping Method: " + this.upsMethods.find(item => item.code == this.selectedValue).label,
          left: true,
          question: true,
          answer: false,
          subType : "method-selected"
        });

        this.chats.push({
          message: "Shipping Box: " + this.boxes.find(item => item.id == this.selectedBox).title,
          left: true,
          question: true,
          answer: false,
          subType : "method-selected"
        });

        this.chats.push({
          message: "Do you want me to ship this order (yes/no) ?",
          left: true,
          question: true,
          answer: false,
          subType : "shipment-yes"
        });
        setTimeout(() => {
          this.speakText("Do you want me to ship this order (yes/no) ?")
        },5000)
        this.loading = false;
        return;
  }

    if(lastMessage?.subType == 'box-selected'){
        this.selectedBox = this.selectedValue;
        if(this.order.shippingMethodCode){
          this.selectedValue = this.order.shippingMethodCode;
         this.order.shippingMethodCode = this.order.shippingMethodCode.length === 3 ? this.order.shippingMethodCode.replace(/^0/, ""): this.order.shippingMethodCode;
          console.log(" ***  this.order.shippingMethodCode ", this.order.shippingMethodCode)
          this.chats.push({
            message: "Please review the following info",
            left: true,
            question: true,
            answer: false,
            subType : "method-selected"
          });
          this.speakText("Please review the following info")
          this.chats.push({
            message: "Order No: " + this.order.orderPrefixId,
            left: true,
            question: true,
            answer: false,
            subType : "method-selected"
          });
  
  
          this.chats.push({
            message: "Shipping Method: " + this.upsMethods.find(item => item.code == this.order.shippingMethodCode).label,
            left: true,
            question: true,
            answer: false,
            subType : "method-selected"
          });
  
          this.chats.push({
            message: "Shipping Box: " + this.boxes.find(item => item.id == this.selectedBox).title,
            left: true,
            question: true,
            answer: false,
            subType : "method-selected"
          });
  
          this.chats.push({
            message: "Do you want me to ship this order (yes/no) ?",
            left: true,
            question: true,
            answer: false,
            subType : "shipment-yes"
          });
          setTimeout(() => {
            this.speakText("Do you want me to ship this order (yes/no) ?")
          },5000)
        
        } else {
          this.chats.push({
            message: "Please choose a shipping method",
            left: true,
            question: true,
            answer: false,
            subType : "method-selected"
          });
          this.speakText("Please choose a shipping method")
          console.log(" ***  this.upsMethods ",this.upsMethods);
          setTimeout(() => {
            this.scrollToBottom();
          },0)
          this.upsMethods.forEach((box) => {
            this.chats.push({
              message: box.label,
              value: box.code,
              left: true,
              question: true,
              answer: true,
              choice: true,
              subType : "method-selected"
            });
          });
        }
       
        this.loading = false;
       
        return;
    }

    if(lastMessage?.subType == 'show-boxes'){
      this.getOrder(message).then((response:any) => {
       
        if(response.data){
          this.products = response.data.newproductList;

          this.products = this.products.map(item => {

            let newItem = { ...item };

            newItem.weight = newItem.weight * item.quantity;
            return item;
        })

          let data = response.data;
          this.order = data;
          this.modifyProducts();
          this.orderService.getUpsMethodList({
            orderId: data.orderId,
            vendorId: data.vendorId,
            address: data.shippingAddress1,
            city: data.shippingCity,
            country: data.shippingCountryId,
            zipCode: data.shippingPostcode,
            amount: this.getTotal(data),
            weight: 0
          }).toPromise().then(response => {
            this.upsMethods = response.data;
            this.settingService.listShippingBoxes({}).subscribe((response) => {
              this.boxes = response.data;
              this.loading = false;
              this.chats.push({
                message: "Please choose one of the boxes",
                left: true,
                question: true,
                answer: false,
                subType : "box-selected"
              });

              this.speakText("Please choose one of the boxes")
              this.boxes.forEach((box) => {
                this.chats.push({
                  message: box.title,
                  value: box.id,
                  left: true,
                  question: true,
                  answer: true,
                  choice: true,
                  subType : "box-selected"
                });
              })

              setTimeout(() => {
                this.scrollToBottom();
              },0)

          }, (err) => {
            this.loading = false;
          });

            if (this.upsMethods.length > 0) {
              const shippingMethod = this.upsMethods[0];
              if (shippingMethod) {
               
              }
            }
          })
        
       
        }
      });

      return;

    }


   
    if(lastMessage?.subType == 'create-newsletter'){
      localStorage.setItem("textForNewsletter", message);
      this.chats.push({
        message: "Write a prompt for generating newsletter image",
        left: true,
        question: true,
        answer: false,
        subType : "ask-for-newsletter-image-prompt"
      });
      this.speakText("Write a prompt for generating newsletter image")
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.loading = false;
      return;
    }


    if((lastMessage?.subType == 'promocode-finish') || (lastMessage?.subType == 'coupon-finish')){

      const isValid = moment(message,'DD/MM/YYYY').isValid();
      if(!isValid){

        let nextMessage = '';
        let subType = '';
       
        if((lastMessage?.subType == 'promocode-finish')){
          nextMessage =  "Please enter a valid end date (day/month/year)";
          subType =  "promocode-finish";
        } else {
          nextMessage =  "Please enter a valid end date (day/month/year)";
          subType = "coupon-finish";
        }

        this.chats.push({
          message: nextMessage,
          left: true,
          question: true,
          answer: false,
          subType : subType
        });
        this.speakText(nextMessage)

        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        this.loading = false;
        return;
      }
      this.promocodeEndDate = moment(message,'DD/MM/YYYY').format('YYYY-MM-DD');
      
      let params = {
        promotionalCode: this.promocode,
        description: this.promocodeDescription,
        promotionalValue: this.promotionalValue,
        type: parseInt(this.promocodeType),
        startDate: this.promocodeStartDate,
        endDate: this.promocodeEndDate,
        productIds: [],
        status: 0,
        minimumPurchaseAmount: 0,
        maximumPurchaseAmount: 1000,
        maxUsagePerUser: 1,
        maxUsage: 1000
      }


    if(lastMessage?.sybType == 'promocode-finish'){
      this.promocodeService.createPromocode(params).toPromise().then((response) => {
        this.toastr.success('Promocode created successfully!', 'Success');
        this.chats.push({
          message: "Your promocode has been generated successfully. Please navigate to Marketing/Promotional Codes to view.",
          left: true,
          question: true,
          answer: false,
        });
        this.speakText("Your promocode has been generated successfully. Please navigate to Marketing/Promotional Codes to view.")
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        this.loading = false;
    }).catch(err => {
        
        console.log(err);
    });
    } else {

      const params: any = {};
      params.couponName = this.promocodeDescription;
      params.couponCode = this.promocode;
    
      params.status = 1;
    
      params.couponType = this.promocodeType;
      params.discount = this.promotionalValue;
      params.minimumPurchaseAmount = 1;
      params.maximumPurchaseAmount = 1000;
      params.emailRestrictions = '';
      params.applicableFor = '';
     
      
      params.freeShipping = 0;
      
      params.allQualifyingItemsApply = 1;
    
      params.startDate = this.promocodeEndDate;
      params.endDate = this.promocodeStartDate;
      params.maxUserPerCoupon = 1;
      params.noOfTimeCouponValidPerUser = 1;
      params.appliedCartItemsCount =  0;
   
      params.productType = [];

      this.settingService.createCoupon(params).toPromise().then((response) => {
        this.toastr.success('Coupon code created successfully!', 'Success');
        this.chats.push({
          message: "Your coupon code has been generated successfully. Please navigate to Marketing/Manage Coupons to view.",
          left: true,
          question: true,
          answer: false,
        });
        this.speakText("Your coupon code has been generated successfully. Please navigate to Marketing/Manage Coupons to view.");
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        this.loading = false;
    }).catch(err => {
        
        console.log(err);
    });
    }

   
      
    
     
      return;
    }


    if((lastMessage?.subType == 'promocode-end') || (lastMessage?.subType == 'coupon-end')){

      const isValid = moment(message,'DD/MM/YYYY').isValid();
   
      if(!isValid){

        let nextMessage = '';
        let subType = '';
       
  
        if((lastMessage?.subType == 'promocode-end')){
          nextMessage =  "Please enter a valid start date (day/month/year)";
          subType =  "promocode-end";
        } else {
          nextMessage =  "Please enter a valid start date (day/month/year)";
          subType = "coupon-end";
        }

        this.chats.push({
          message: nextMessage,
          left: true,
          question: true,
          answer: false,
          subType : subType
        });
        this.speakText(nextMessage);
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        this.loading = false;
        return;
      }
      this.promocodeStartDate = moment(message,'DD/MM/YYYY').format('YYYY-MM-DD');
      
      let nextMessage = '';
      let subType = '';
     

      if((lastMessage?.subType == 'promocode-end')){
        nextMessage =  "Please enter the promocode end date (day/month/year)?";
        subType =  "promocode-finish";
      } else {
        nextMessage =  "Please enter the coupon end date (day/month/year)?";
        subType = "coupon-finish";
      }
      
      this.chats.push({
        message: nextMessage,
        left: true,
        question: true,
        answer: false,
        subType : subType
      });
      this.speakText(nextMessage);
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.loading = false;
      return;
    }

    if((lastMessage?.subType == 'promocode-amount') || (lastMessage?.subType == 'coupon-amount')){
      
      this.promotionalValue = message;

      let nextMessage = '';
      let subType = '';
     

      if((lastMessage?.subType == 'promocode-amount')){
        nextMessage =  "Please enter the promocode start date (day/month/year)?";
        subType =  "promocode-end";
      } else {
        nextMessage =  "Please enter the coupon start date (day/month/year)?";
        subType = "coupon-end";
      }

      this.chats.push({
        message: nextMessage,
        left: true,
        question: true,
        answer: false,
        subType : subType
      });
      this.speakText(nextMessage);
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.loading = false;
      return;
    }

    if((lastMessage?.subType == 'promocode-start') || (lastMessage?.subType == 'coupon-start')){
      
      let nextMessage = '';
      let subType = '';
     


      if((this.promocodeType == '2')){

        if((lastMessage?.subType == 'promocode-start')){
          nextMessage =  "Please enter the promocode discount amount";
          subType =  "promocode-amount";
        } else {
          nextMessage =  "Please enter the coupon discount amount";
          subType = "coupon-amount";
        }

        this.chats.push({
          message: nextMessage,
          left: true,
          question: true,
          answer: false,
          subType : subType
        });
        this.speakText(nextMessage);
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        this.loading = false;
        return;
      }

      if((this.promocodeType == '5')){

        if((lastMessage?.subType == 'promocode-start')){
          nextMessage =  "Please enter the promocode discount percentage";
          subType =  "promocode-amount";
        } else {
          nextMessage =  "Please enter the coupon discount percentage";
          subType = "coupon-amount";
        }


        this.chats.push({
          message: nextMessage,
          left: true,
          question: true,
          answer: false,
          subType : subType
        });
        this.speakText(nextMessage);
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        this.loading = false;
        return;
      }

      if((lastMessage?.subType == 'promocode-start')){
        nextMessage =  "Please enter the promocode start date (day/month/year)?";
        subType =  "promocode-end";
      } else {
        nextMessage =  "Please enter the coupon start date (day/month/year)?";
        subType = "coupon-end";
      }

      this.chats.push({
        message: nextMessage,
        left: true,
        question: true,
        answer: false,
        subType : subType
      });
      this.speakText(nextMessage);
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.loading = false;
      return;
    }

    if((lastMessage?.subType == 'promocode-type') || (lastMessage?.subType == 'coupon-type')){
      this.promocodeDescription = message;
      let nextMessage = '';
      let subType = '';
      let types = [];
      if((lastMessage?.subType == 'promocode-type')){
        types = this.promocodeTypes;
        nextMessage =  "Please select a promocode type";
        subType =  "promocode-start";
      } else {
        types = this.couponCodeTypes
        nextMessage =  "Please select a coupon type";
        subType = "coupon-start";
      }

      this.chats.push({
        message: nextMessage,
        left: true,
        question: true,
        answer: false,
        subType : subType
      });
      this.speakText(nextMessage);
      for(let type of types){
        this.chats.push({
          message: type.label,
          value: type.value,
          left: true,
          question: true,
          answer: true,
          choice: true,
          subType :subType
        });
       }

      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.loading = false;
      return;
    }

    if((lastMessage?.subType == 'promocode-description') || (lastMessage?.subType == 'coupon-description')){
      this.promocode = message;

      let nextMessage = '';
      let subType = '';

      if((lastMessage?.subType == 'promocode-description')){
        nextMessage =  "Please enter the promocode description";
        subType =  "promocode-type";
      } else {
        nextMessage =  "Please enter the coupon description";
        subType = "coupon-type";
      }

      this.chats.push({
        message:  nextMessage,
        left: true,
        question: true,
        answer: false,
        subType : subType
      });
      this.speakText(nextMessage);
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.loading = false;
      return;
    }

   

    if(lastMessage?.subType == 'ask-for-newsletter-image-prompt'){
      localStorage.setItem("imagePromptForNewsletter", message);
      this.chats.push({
        message: "Your newsletter is being generated",
        left: true,
        question: true,
        answer: false
      });
    //  this.speakText("Your newsletter is being generated");
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      this.loading = false;
      this.router.navigate(['/promotions/newsletter-template'], { queryParams: { created: 1 } });
      return;
    }
 
    if(lastMessage?.subType == 'order-redirect'){
        

      this.getOrder(message).then((response:any) => {
        this.loading = false;

        if(response.data){
          this.router.navigate(['/orders/all-orders'], {
            queryParams: {
              orderId: response.data.vendorOrderId
            }
          });
        }
        this.chats.push({
          message: "You have been redirected to order detail page",
          left: true,
          question: true,
          answer: false,
        });

      
       
        
      });

     
      return;
    }
    
    if(lastMessage?.subType == 'order-yes'){
      if(message == 'yes'){
        this.loading = true;
        this.getOrder(this.orderId).then((response:any) => {
       
          if(response.data){
            this.products = response.data.newproductList;
  
            this.products = this.products.map(item => {
  
              let newItem = { ...item };
  
              newItem.weight = newItem.weight * item.quantity;
              return item;
          })
  
            let data = response.data;
            this.order = data;
            this.modifyProducts();
            this.orderService.getUpsMethodList({
              orderId: data.orderId,
              vendorId: data.vendorId,
              address: data.shippingAddress1,
              city: data.shippingCity,
              country: data.shippingCountryId,
              zipCode: data.shippingPostcode,
              amount: this.getTotal(data),
              weight: 0
            }).toPromise().then(response => {
              this.upsMethods = response.data;
              this.settingService.listShippingBoxes({}).subscribe((response) => {
                this.boxes = response.data;
                this.loading = false;
                this.chats.push({
                  message: "Please choose one of the boxes",
                  left: true,
                  question: true,
                  answer: false,
                  subType : "box-selected"
                });
                this.speakText("Please choose one of the boxes")
                this.boxes.forEach((box) => {
                  this.chats.push({
                    message: box.title,
                    value: box.id,
                    left: true,
                    question: true,
                    answer: true,
                    choice: true,
                    subType : "box-selected"
                  });
                })
  
                setTimeout(() => {
                  this.scrollToBottom();
                },0)
  
            }, (err) => {
              this.loading = false;
            });
  
              if (this.upsMethods.length > 0) {
                const shippingMethod = this.upsMethods[0];
                if (shippingMethod) {
                 
                }
              }
            })
          
         
          }
        });
  
      } else {
        this.chats.push({
          message: "Please enter the order no",
          left: true,
          question: true,
          answer: false,
          subType : "show-boxes"
        });
        this.speakText("Please enter the order no");
      }
      this.loading = false;
      return;
    }



    this.settingService.getAnswer({ text: message, audio: audio,  id: lastMessage ? lastMessage.id : 0  }).toPromise().then((response) => {
      this.loading = false;
      if(response.data){
        console.log(" ***** response.type ", response.type);
        if(response.data.type == 'prompt'){
          //  this.aiSandbox.textSuccess({ text: response.data.answer, voiceText: message });
        }

        if(response.data.subType == 'shipment-change'){
            this.chats.push({
              message: "Please choose a shipping method",
              left: true,
              question: true,
              answer: false,
              subType : "method-selected"
            });
            this.speakText("Please choose a shipping method");
          
            setTimeout(() => {
              this.scrollToBottom();
            },0)
            this.upsMethods.forEach((box) => {
              this.chats.push({
                message: box.label,
                value: box.code,
                left: true,
                question: true,
                answer: true,
                choice: true,
                subType : "method-selected"
              });
            });
        }


        if(response.data.subType == 'create-product'){
          this.loading = false;
      
          this.productService.insertProductInBackground({
            prompt: message
        }).toPromise().then(() => {
          this.chats.push({
            message: "Your product is being created, you will find it under your products tab for review",
            left: true,
            question: true,
            answer: false,
   
          });
          this.speakText("Your product is being created, you will find it under your products tab for review");
        });
        return;
        }

        if(response.data.subType == 'create-blog'){
          this.loading = false;
          this.blogService.addBlogAI({
            prompt: message
        }).toPromise().then(() => {
        
        });

        this.chats.push({
          message: "Your Blog is being created, you will find it under your blogs tab for review",
          left: true,
          question: true,
          answer: false,
 
        });
        this.speakText("Your Blog is being created, you will find it under your blogs tab for review");

        return;
        }
       


        if(response.data.subType == 'shipment'){
          if(this.orderId){
            this.chats.push({
              message: "Do you want to ship the order : " + this.orderId,
              left: true,
              question: true,
              answer: false,
              subType : "order-yes"
            });
            this.speakText( "Do you want to ship the order : " + this.orderId);
          } else {
            this.chats.push({
              message: "Please enter the order no",
              left: true,
              question: true,
              answer: false,
              subType : "show-boxes"
            });
            this.speakText("Please enter the order no");
          }
          
        }


        if(response.data.subType == 'order'){
          this.chats.push({
            message: "Please enter the Order No",
            left: true,
            question: true,
            answer: false,
            subType : "order-redirect"
          });
          this.speakText("Please enter the order no");
        }

        if(response.data.subType == 'fraud'){

          console.log(" **** response data ", response.data);

          this.chats.push({
            message: "Fetching the fraudlant orders",
            left: true,
            question: true,
            answer: false,
            subType : ""
          });


          this.speakText("Fetching the fraudlant orders");

          this.fraudOrderes({}).then((response:any) => {

            console.log(" *** response ", response);

            this.chats.push({
              message: "Following are the fraudlant orders",
              left: true,
              question: true,
              answer: false,
              subType : ""
            });

            response.data.map((item) => {
              this.chats.push({
                message: item.orderPrefixId + ". Click to view detail",
                orderId: item.vendorOrderId,
                left: true,
                question: true,
                answer: false,
                link: true,
                subType : ""
              });
            });
            setTimeout(() => {
              this.scrollToBottom();
            },0)
        
          });
        }

        if(response.data.subType == 'promocode'){
          this.chats.push({
            message: "Sure please enter the promocode name?",
            left: true,
            question: true,
            answer: false,
            subType : "promocode-description"
          });
          this.speakText("Sure please enter the promocode name?");
        }

        if(response.data.subType == 'coupon'){
          this.chats.push({
            message: "Sure please enter the coupon name?",
            left: true,
            question: true,
            answer: false,
            subType : "coupon-description"
          });
          this.speakText("Sure please enter the coupon name?");
        }

        if(response.data.subType == 'create-newsletter'){
          this.chats.push({
            message: "Sure please write a description for your newsletter",
            left: true,
            question: true,
            subType: response.data.subType,
            answer: false
          });
          this.speakText("Sure please write a description for your newsletter");
        }

        if(response.data.subType == 'homepage'){
          this.chats.push({
            message: "Sure you can choose to build from the following templates",
            left: true,
            question: true,
            subType: response.data.subType,
            answer: false
          });
          this.speakText("Sure you can choose to build from the following templates");
          for(let group of response.data.templates){
            this.chats.push({
              id: group.id,
              image: "https://app.premierstreets.com:8000/uploads/" + group.templateImage,
              left: true,
              question: false,
              answer: true,
              select: true
            });
        }
        this.loading = false;
        return;
      }


        if(response.data.subType == 'group_suggestions') {

          this.chats.push({
            message: "Here are some group suggestions for you: ",
            left: true,
            question: false,
            answer: true
          });
          this.speakText("Here are some group suggestions for you");
          let groups = response.data.answer.split(', ');
          for(let group of groups){
            this.chats.push({
              message: group,
              left: true,
              question: false,
              answer: true,
              card: true
            });
          }
          return;
        }

        

          this.chats.push({
            message: response.data.answer || response.data.anwer,
            left: true,
            question: false,
            answer: true,
            emit: emit
          });

        

          this.speakText(response.data.answer || response.data.anwer);
        
          if(response.data.image){
            let images = response.data.image.split(',');
            for(let image of images){
            this.chats.push({
                image: "https://app.premierstreets.com:8000/uploads/vendor-support/" + image.trim(),
                left: true,
                question: false,
                answer: true
              });
            }
          }

        } else {
          this.chats.push({
            message: "Sorry I don't know that. Please try asking me something else",
            left: true,
            question: false,
            answer: true
          });
          this.speakText("Sorry I don't know that. Please try asking me something else")
        }
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
      
    });
  }


  fillText(text, emit){
   
      this.aiSandbox.textSuccess({ text: text, voiceText: emit });
    
  }

  scrollToBottom(): void {
    try {
      if (this.myScrollContainer?.nativeElement) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }

    } catch (err) {
      console.log(" ***** err ", err);
    }
  }
  
  clickEvent1(){
      this.status1 = !this.status1;   
      this.aiSandbox.setMasterClose(true)
      speechSynthesis.cancel();
  }

  clickEvent(){
    this.chats = [];
    this.status = !this.status;   
    this.aiSandbox.setMasterClose(true)
    speechSynthesis.cancel();
}

  



  ngOnDestroy(): void {
    speechSynthesis.cancel();
    if(this.interval){
      clearInterval(this.interval);
    }
      this.subscriptions.forEach(item => {
        item.unsubscribe();
      })
  }


}
