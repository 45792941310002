
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    DataType: 'application/json'
  })
};

@Injectable()
export class ConfigService {
  private config: Object;
  private env: Object;

  constructor(private http: HttpClient) { }
  public getImageUrl(): string {
    return environment.imageUrl;
  }

  public getUploadUrl(): string {
    return environment.uploadUrl;
  }

  public getImageBaseUrl() {
    return environment.imageBaseUrl;
  }

  public getScoketUrl(): string {
    return environment.socketUrl;
  }

  public getStoreUrl(): string {
    return environment.storeUrl;
  }

  public getBookKeepingUrl(): string {
    return environment.bookkeepingUrl;
  }

  public isProduction() {
    return environment.production;
  }
}
