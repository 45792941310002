import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
    FormGroup
} from '@angular/forms';

import { Router } from '@angular/router';
import { CommonSandbox } from '../../../../../../core/common/common.sandbox';
import { SettingsService } from '../../../../../../core/settings/settings.service';
import { AuthSandbox } from '../../../../../../core/auth/auth.sandbox';
import { environment } from '../../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-setting-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SettingSidbarComponent implements OnInit, OnDestroy {
    @Input() activeMenu = '';
    @Input() activeRoute = '';
    public shippingMethodForm: FormGroup;
    public subscriptions: Array<any>;
    public userData: any = {};
    public vendoradminId: any = {};
    public subMenu: any = {};
    public subMenuAdded = 0;
    public vendorServiceId = environment.vendorServiceId
    public currentVendorSerivceId = 0;
    constructor(
        public router: Router,
        public commonSandbox: CommonSandbox,
        public settingsService: SettingsService,
        public toastr: ToastrService,
        public authSandbox: AuthSandbox
    ) { }


    goToRoute(route) {
        this.router.navigate(route).then(() => { window.location.reload(); })
    }

    goTo(route) {

        if (this.currentVendorSerivceId == this.vendorServiceId) {
            this.router.navigate(route);
        } else {
            this.toastr.info("Please upgrade your plan to use this feature");
        }

    }

    ngOnInit() {
        this.subscriptions = [];
        this.vendoradminId = JSON.parse(localStorage.getItem('vendorUserDetails'));

        this.getService();
        if (this.vendoradminId.id) {
            const params: any = {};
            params.id = this.vendoradminId.id;
            this.subscriptions.push(this.settingsService.getVendorPermission(params).subscribe((response) => {
                if (Object.keys(response).length > 0 && Object.keys(response.data.settings.submenu).length > 0) {
                    this.subMenuAdded = 1;
                    this.subMenu = response.data.settings.submenu;
                }
            }))
        }

        this.subscriptions.push(this.commonSandbox.getProfile$.subscribe(data => {
            if (data && Object.keys(data).length) {
                this.userData = data;
            }
        }))



    }

    getService() {
        this.subscriptions.push(this.authSandbox.subscription$.subscribe(data => {
            if (data) {
                this.currentVendorSerivceId = data.serviceId;
            }
        }));
    }
    ngOnDestroy() {
        this.subscriptions.forEach(each => each.unsubscribe());
    }
}
