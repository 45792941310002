import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ConfigService } from '../../../../core/services/config.service';
import { CommonSandbox } from '../../../../core/common/common.sandbox';
import { menu } from './side-menu';
import { isPlatformBrowser } from '@angular/common';
import { Modes as SidebarModes } from 'angular-sidebar-menu';
import { ChatDialog } from '../chat-popup/chat-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthSandbox } from '../../../../core/auth/auth.sandbox';
import { SettingsService } from '../../../../core/settings/settings.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';


@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
	sidebarModes = SidebarModes;
	currentSidebarMode = SidebarModes.EXPANDED;
	mainNavigationOpened = true;
	public mainNavigationClosed = false;
	menuItem: Array<any> = [];
	public dialogRef: any;
	showMenu = 'Dashboard';
	showSubMenu = '';
	public routeUrl = '';
	public userData: any = {};
	public permissions = {};
	public subscriptions = [];
	public currentVendorSerivceId = 0;
	public subscriptionData = null;
	public vendorServiceId = environment.vendorServiceId;
	allRecords: any;

	constructor(
		private commonSandbox: CommonSandbox,
		private config: ConfigService,
		public dialog: MatDialog,
		public router: Router,
		public authSandbox: AuthSandbox,
		public toastr: ToastrService,
		@Inject(PLATFORM_ID) private platformId: Object,
		public settingsService: SettingsService,
	) { }

	ngOnInit() {
		this.getService();
		this.routeUrl = this.router.url;

		this.subscriptions.push(this.router.events.subscribe((val) => {
			this.routeUrl = this.router.url;
		}));

		this.subscriptions.push(this.commonSandbox.getProfile$.subscribe(data => {
			if (data && Object.keys(data).length) {
				this.userData = data;
				this.updateMenuItems();
				this.permissions = data.permissions;
				//  console.log(this.permissions ,'this.permissions ===========');
			}
		}))

		this.subscriptions.push(this.authSandbox.subscription$.subscribe(data => {
			if (data) {
				this.subscriptionData = data;
			}
		}));

		//-----------This Is For Get All Menu Items Persmission--------------//
		// this.settingsService.getHelpInformations({}).toPromise().then(response => {
		// 	this.allRecords = response.data;
		//  });
	}

	isMobileDevice(sidebarItem) {
		console.log(" **** url ", this.router.url)


		setTimeout(() => {
			if (this.router.url === '/promotions/social-designs') {
				//this.router.navigate([sidebarItem.path])
				window.location.href = `https://vendor.premierstreets.com/#/${sidebarItem.path}`;
				window.location.reload();
			} else {
				if ((sidebarItem.title === 'Inbound Dashboard' || sidebarItem.title === 'Customer Service Tickets' || sidebarItem.title == '3rd Party Stockiest') && (this.currentVendorSerivceId != this.vendorServiceId)) {
					this.toastr.info("Please upgrade your plan to use this feature");
				} else {
					this.router.navigate([sidebarItem.path]);
				}
			}
		}, 10);
		return window.matchMedia("only screen and (max-width: 760px)").matches;
	}

	getService() {
		this.subscriptions.push(this.authSandbox.subscription$.subscribe(data => {
			if (data) {
				this.currentVendorSerivceId = data.serviceId;
			}
		}));
	}
	checkSubItem(item) {

		if (item.hide) {
			if ((this.userData.role == 'subadmin') && (item.path === '/payroll/report')) {
				return false;
			}

			if ((this.userData.role == 'subadmin') && (item.path === '/settings/vendor-admins/list')) {
				return false;
			}

			if ((this.userData.role == 'subadmin') && (item.path === '/orders/order-resend-requests')) {
				return false;
			}

			if ((this.userData.role == 'vendor') || (this.userData.role == 'subadmin')) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	openChat(id) {
		if (this.userData) {
			this.dialogRef = this.dialog.open(ChatDialog, {
				data: {
					title: "Chat",
					id: id
				}
			});
			this.dialogRef.afterClosed().subscribe(result => {
				console.log(`Dialog result: ${result}`);
			});
		}
	}

	getPercentage() {
		if (this.userData) {
			let factors = 0;
			if (this.userData.companyName) {
				factors = factors + 1;
			}

			if (this.userData.termsAccepted === 1) {
				factors = factors + 1;
			}
			if (this.userData.shippingCompleted) {
				factors = factors + 1;
			}
			if (this.userData.paymentCompleted) {
				factors = factors + 1;
			}
			return ((factors / 4) * 100).toFixed(0);
		} else {
			return 0;
		}
	}

	isMenuDisabled(userData, sidebarnavItem) {

		if (sidebarnavItem.title === 'Dashboard') {
			return false;
		}
		if (this.subscriptionData) {
			return ((this.subscriptionData.status === 0));
		} else {
			return false; //(sidebarnavItem.disable);
		}
	}

	isMenuShow(sidebarnavItem) {
		if ((sidebarnavItem.title === 'Inbound Dashboard' || sidebarnavItem.title === 'Customer Service Tickets' || sidebarnavItem.title == '3rd Party Stockiest') && (this.currentVendorSerivceId != this.vendorServiceId)) {
			return false;
		} else {
			//this.router.navigate(route);
		}
	}



	updateMenuItems() {
		this.menuItem = menu.map(item => {
			if (item.title === 'Feedback') {
				if (isPlatformBrowser(this.platformId)) {
					const token = localStorage.getItem('vendorToken');
					item.path = this.config.getStoreUrl() + '/auth/auto-login?token=' + encodeURIComponent(token);
				}
			}

			if (item.title === 'Bookkeeping') {
				if (isPlatformBrowser(this.platformId)) {
					const token = localStorage.getItem('vendorToken');
					item.path = this.config.getBookKeepingUrl() + '?token=' + encodeURIComponent(token);
				}
			}

			if (item.title === 'Settings') {
				if (isPlatformBrowser(this.platformId)) {
					console.log(" ***** this.userData.shippingCompleted *****  ", this.userData.shippingCompleted);
					item.showIncompelete = (this.userData.shippingCompleted) ? false : true;
				}
			}



			if (item.title === 'Payments') {
				if (isPlatformBrowser(this.platformId)) {
					item.showIncompelete = (this.userData.paymentCompleted) ? false : true;
				}
			}


			if (item.title === 'Social Hub') {
				if (isPlatformBrowser(this.platformId)) {
					const token = localStorage.getItem('vendorToken');
					item.path = this.config.getStoreUrl() + '/auth/auto-login?token=' + encodeURIComponent(token) + `&url=/socialhub-user`;
				}
			}

			if (item.title === 'Messages') {
				if (isPlatformBrowser(this.platformId)) {
					const token = localStorage.getItem('vendorToken');
					item.path = this.config.getStoreUrl() + '/auth/auto-login?token=' + encodeURIComponent(token) + `&url=${encodeURIComponent('/socialhub-user')}`;
				}
			}

			// if (item.title === 'Blogs') {
			// 	if (isPlatformBrowser(this.platformId)) {
			// 		item.showIncompelete = (this.userData.shippingCompleted) ? false : true;
			// 	}
			// }



			if (item.key === 'pos') {
				let submenus = [];
				item.submenu.forEach((item2) => {
					if (item2.title === 'Close Shift') {
						if (this.userData.shiftData.state === 2) {
							item2.title = "Open Shift";
							item2.path = '/pos/open-shift'
						}
					} else if (item2.title === 'Open Shift') {
						if (this.userData.shiftData.state === 1) {
							item2.title = "Close Shift";
							item2.path = '/pos/close-shift'
						}
					}
					submenus.push(item2);
				});
				item.submenu = submenus;
			}

			return item;
		});


	}

	openDropdown(event) {
		event.stopPropagation();
	}

	// this is for the open close
	addExpandClass(element: any) {
		if (element === this.showMenu) {
			this.showMenu = '0';
		} else {
			this.showMenu = element;
		}
	}

	addActiveClass(element: any) {
		if (element === this.showSubMenu) {
			this.showSubMenu = '0';
		} else {
			this.showSubMenu = element;
		}
	}

	searchMenu(keyword) {
		this.menuItem = menu;
		this.menuItem = this.menuItem.filter(item => {
			return item.title.toLowerCase().includes(keyword);
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(item => {
			item.unsubscribe();
		})
	}
}
