import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { PaymentSandbox } from '../../../../../../core/payment/payment.sandbox';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
    Validators,
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxPaypalComponent, PayPalScriptService } from 'ngx-paypal';
import { PaymentService } from '../../../../../../core/payment/payment.service';
import { CommonSandbox } from '../../../../../../core/common/common.sandbox';
import { GatewaySettingModel } from '../../../../../../core/payment/payment-model/gateway-setting.mode';


@Component({
    selector: 'app-authorize-settings',
    templateUrl: './authorize-settings.component.html',
    styleUrls: ['./authorize-settings.component.scss']
})
export class AuthorizeSettingsComponent implements OnInit, OnDestroy {
    public userDetails = JSON.parse(localStorage.getItem('vendor-settings'));
    private subscriptions: Array<Subscription> = [];
    public authorizeForm: FormGroup
    private gatewayData: any;
    public payPalConfig: any;
    public userData: any = {};
    public showIncomplete = true;

    public showPay = false;
    public width = 220;
    public height = 35;
    public shape = 'rect';
    public color = 'gold';
    public label = 'paypal';
    public layout = 'vertical';
    public routeUrl = '';

    @Output() dataSubmitted = new EventEmitter();

    constructor(
        public paymentSandbox: PaymentSandbox,
        public formBuilder: FormBuilder,
        public router: Router,
        public toastr: ToastrService,
        public payPalScriptService: PayPalScriptService,
        public paymentService: PaymentService,
        public commonSandbox: CommonSandbox
    ) { }

    public initConfig(clientId): void {
        this.showPay = true;

    }

    ngOnInit() {
        this.initPaypalSettingsForm();
        this.routeUrl = this.router.url;
        this.subscriptions.push(
            this.commonSandbox.getProfile$.subscribe(data => {
                if (data && Object.keys(data).length) {
                    this.userData = data;
                }
            })
        );

        this.paymentService.getGatewaySetting({ key: 'authorize' }).toPromise().then((response: any) => {
            if (Object.keys(response.data).length > 0) {
                this.gatewayData = response.data;
                this.setSettingsForm(response.data);
            }
        });
    }

    initPaypalSettingsForm() {
        this.authorizeForm = this.formBuilder.group({
            key: ['authorize', Validators.required],
            clientId: ['', Validators.required],
            clientSecret: ['', Validators.required],
            publicKey: ['', Validators.required],
            status: ['', Validators.required]
        });
    }


    setSettingsForm(data) {
        this.initConfig(data.settings.clientId);
        this.authorizeForm.controls['clientId'].setValue(data.settings.clientId);
        this.authorizeForm.controls['clientSecret'].setValue(data.settings.clientSecret);
        this.authorizeForm.controls['publicKey'].setValue(data.settings.publicKey);
        this.authorizeForm.controls['status'].setValue(data.status);
    }


    submit() {
        if (!this.authorizeForm.valid) {
            return;
        }
        this.showPay = false;
        let data: any = { settings: {}, key: '' };
        data.key = this.authorizeForm.controls['key'].value;
        data.status = this.authorizeForm.controls['status'].value;
        data.settings.clientId = this.authorizeForm.controls['clientId'].value;
        data.settings.clientSecret = this.authorizeForm.controls['clientSecret'].value;
        data.settings.publicKey = this.authorizeForm.controls['publicKey'].value;
        return this.paymentService.saveGatewaySetting(new GatewaySettingModel(data)).toPromise().then((response: any) => {
            if (response) {
                this.toastr.success('Settings updated successfully', 'Success');
                this.paymentSandbox.getPaymentGatewaySettings({ key: 'paypal' });
                //window.location.reload();
                this.initConfig(data.settings.clientId);
                this.commonSandbox.doGetProfile();
                //  this.dataSubmitted.emit('complete');
            }
        });

        // this.subscriptions.push(this.paymentSandbox.gatewaySaveSuccess$.subscribe((data) => {
        //     if (data) {
        //         this.toastr.success('Settings updated successfully', 'Success');
        //         //  this.paymentSandbox.getPaymentGatewaySettings({ key: 'authorize' });
        //         this.commonSandbox.doGetProfile();

        //     }
        // }));
    }


    submitAndComplete(){
       // this.submit().then(() => {
            this.dataSubmitted.emit('complete');
      //  })
    }

    ngOnDestroy() {
        this.subscriptions.forEach(each => each.unsubscribe());
    }
}
