import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-theme-setting-popup',
  templateUrl: './theme-setting-popup.component.html',
  styleUrls: ['./theme-setting-popup.component.scss']
})
export class ThemeSettingComponent implements OnInit, OnChanges {

  @Input('show') show: boolean;


  @Output() classChanged = new EventEmitter();

  public showHide;

  public defaultClass = '';

  public layoutClass = ''
  public themeClass = ''
  public topbarClass = '';
  public sidebarClass = '';

  constructor() { }

  ngOnInit() {
    this.showHide = this.show;

    let className = localStorage.getItem('theme-class');
    if (className) {
      this.defaultClass = className;

      let classArray = className.split(' ');

      this.layoutClass = ['horizontal', 'vertical'].find(item => classArray.includes(item)) || '';
      this.themeClass = ['theme-light', 'theme-dark'].find(item => classArray.includes(item)) || '';
      this.topbarClass = ['topbar-light', 'topbar-brand', 'topbar-dark'].find(item => classArray.includes(item)) || '';
      this.sidebarClass = ['sidebar-light', 'sidebar-brand', 'sidebar-dark'].find(item => classArray.includes(item)) || '';

    } else {
      this.defaultClass = '';
    }
  }

  selectStyle(type, className) {
    if (type == 1) {
      this.layoutClass = className;
      if (className == 'vertical') {
        this.defaultClass = this.defaultClass.replace(' horizontal', '').replace(' vertical', '').concat(' ' + className);
      } else {
        this.defaultClass = this.defaultClass.replace(' vertical', '').replace(' horizontal', '').concat(' ' + className);
      }
    } else if (type == 2) {
      this.themeClass = className;
      if (className == 'theme-light') {
        this.defaultClass = this.defaultClass.replace(' theme-dark', '').replace(' theme-light', '').concat(' ' + className);
      } else {
        this.defaultClass = this.defaultClass.replace(' theme-light', '').replace(' theme-dark', '').concat(' ' + className);
      }
    } else if (type == 4) {
      this.sidebarClass = className;
      if (className == 'sidebar-light') {
        this.defaultClass = this.defaultClass.replace(' sidebar-light', '').replace(' sidebar-dark', '').replace(' sidebar-brand', '').concat(' ' + className);
      } else if (className == 'sidebar-dark') {
        this.defaultClass = this.defaultClass.replace(' sidebar-light', '').replace(' sidebar-brand', '').replace(' sidebar-dark', '').concat(' ' + className);
      } else if (className == 'sidebar-brand') {
        this.defaultClass = this.defaultClass.replace(' sidebar-light', '').replace(' sidebar-dark', '').replace(' sidebar-brand', '').concat(' ' + className);
      }
    } else if (type == 3) {
      this.topbarClass = className;
      if (className == 'topbar-light') {
        this.defaultClass = this.defaultClass.replace(' topbar-light', '').replace(' topbar-dark', '').replace(' topbar-brand', '').concat(' ' + className);
      } else if (className == 'topbar-dark') {
        this.defaultClass = this.defaultClass.replace(' topbar-light', '').replace(' topbar-dark', '').replace(' topbar-brand', '').concat(' ' + className);
      } else if (className == 'topbar-brand') {
        this.defaultClass = this.defaultClass.replace(' topbar-light', '').replace(' topbar-dark', '').replace(' topbar-brand', '').concat(' ' + className);
      }
    }
    this.classChanged.emit(this.defaultClass);
  }

  ngOnChanges(): void {
    this.showHide = !this.showHide;
  }

}
