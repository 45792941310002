import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonSandbox } from '../../../../../../core/common/common.sandbox';
import { ConfigService } from '../../../../../../core/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from '../../../../../../core/settings/settings.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { first, take } from 'rxjs/operators';
import { AISandbox } from '../../../../../../core/ai/ai.sandbox';


declare var tinymce;


@Component({
    selector: 'app-shop-details',
    templateUrl: './shop-details.component.html',
    styleUrls: ['./shop-details.component.scss'],

})

export class ShopDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('filePathForUser') filePathForUser: ElementRef;
    @ViewChild('filePathForCompany') filePathForCompany: ElementRef;
    @ViewChild('filePathForCover') filePathForCover: ElementRef;
    @ViewChild('aboutPageVideoEl') aboutPageVideoEl: ElementRef;
    @ViewChild('filePathForAvatar') filePathForAvatar: ElementRef;
    @ViewChild('filePathForShopImage') filePathForShopImage: ElementRef;
    @Output() dataSubmitted = new EventEmitter();
    public userDetails: any = {};
    public avatarForm: FormGroup;
    public companyForm: FormGroup;
    public godaddySetupForm: FormGroup;
    public shopDescriptionForm: FormGroup;
    public paymentInfoForm: FormGroup;
    public storyForm: FormGroup;
    public termsForm: FormGroup;
    public routeUrl = '';
    public speak = true;
    // upload image
    public activeStep = '0';
    public vendorId: any;
    public imageUrl: any;
    public companyImageUrl: any;
    public shopImageUrl: any;
    private userImage: any;
    private companyImage: any;
    public shopImage: any;
    public imagePath: string;
    public uploadPath: string;
    private subscriptions: Array<Subscription> = [];
    public ifImageAvailable: string;
    public ifCompanyImageAvailable: string;
    public ifCoverImageAvailable: string;
    public coverImageUrl: any;
    public aboutPageVideoUrl: any;
    public aboutPageVideo: any;
    private coverImage: any;
    public countryId: any;
    public aboutPageVideoType: number;
    public ourStory: string;
    public avatarImageUrl: any;
    public avatarImage: any;
    public imageError = '';
    public showIncomplete = true;
    public domainSetup = false;

    public tinyMCEInit = {
        base_url: this.configService.isProduction() ? '/tinymce' : '/tinymce',
        suffix: '.min',
        height: 300,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        imagetools_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        image_advtab: true,
        file_picker_callback: function (cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = function () {
                var file = input.files[0];

                var reader = new FileReader();
                reader.onload = function () {
                    // Note: Now we need to register the blob in TinyMCEs image blob
                    // registry. In the next release this part hopefully won't be
                    // necessary, as we are looking to handle it internally.
                    var id = 'blobid' + (new Date()).getTime();
                    var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                    const result: any = reader.result;
                    var base64 = result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    // call the callback and populate the Title field with the file name
                    cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
            };

            input.click();
        }
    }

    constructor(
        public modal: NgbModal,
        public formBuilder: FormBuilder,
        public configService: ConfigService,
        public commonSandbox: CommonSandbox,
        public aiSandbox: AISandbox,
        public toastr: ToastrService,
        public sanitizer: DomSanitizer,
        public settingService: SettingsService,
        public router: Router
    ) { }

    ngOnInit() {
        this.routeUrl = this.router.url;
        this.aboutPageVideoType = 0;
        this.aiSandbox.showInsert(true);
        this.initiatecompanyForm();
        this.vendorId = JSON.parse(localStorage.getItem('vendorUserDetails'));
        this.imagePath = this.configService.getImageUrl();
        this.uploadPath = this.configService.getUploadUrl();
        this.regDetailEvent();
        this.getCountryList();
        this.regDetailEvent();

        $('button.save').on('click', function () {
            if ($('.error-field').position()) {
                $('html, body').animate({
                    'scrollTop': $('.error-field').position()['top']
                });
            }

            $('html, body').animate({
                'scrollTop': $('.bd-tab-content').position()['top']
            });
        });
    }

    // get country list
    getCountryList() {
        const params: any = {};
        params.limit = '';
        params.offset = '';
        params.keyword = '';
        params.count = '';
        this.commonSandbox.getCountryList(params);
        // this.subscriptions.push(this.commonSandbox.getCountryList$.subscribe(data => {
        //     if (data) {
        //         this.getCountryId(data);
        //     }
        // }));
    }



    // initiate user form event
    initiatecompanyForm() {
        this.companyForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            companyEmailId: ['', Validators.required],
            password: [''],
            companyName: ['', Validators.required],
            companyAddress1: ['', Validators.required],
            minimumOrderAmount: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            pincode: ['', Validators.required],
            countries: ['', Validators.required],
            mobileNo: ['', Validators.required],
            companyVatNumber: [''],
            subdomain: [''],

        });

        this.godaddySetupForm = this.formBuilder.group({
            domain: [''],
            godaddyCustomerId: ['']
        });

        this.shopDescriptionForm = this.formBuilder.group({
            returnPolicy: ['', Validators.required],
            shippingPolicy: ['', Validators.required],
            aboutPageVideoType: [1, Validators.required],
            additionalInfo1: [''],
            additionalInfo2: [''],
        });

        this.storyForm = this.formBuilder.group({
            companyDescription: ['', Validators.required],
            ourStory: ['', Validators.required],
            rewards: ['', Validators.required]
        });

        this.paymentInfoForm = this.formBuilder.group({
            paymentInfo: ['', Validators.required]
        });

        this.avatarForm = this.formBuilder.group({
            avatar: ['']
        });

        this.termsForm = this.formBuilder.group({
            termsAccepted: ['', Validators.requiredTrue]
        });

    }



    // subscribe user detail event
    regDetailEvent() {
        this.subscriptions.push(
            this.commonSandbox.getProfile$.subscribe(data => {
                if (data && Object.keys(data).length) {
                    this.setcompanyForm(data);
                    this.userDetails = data;
                    setTimeout(() => {
                        this.sendMessage();
                    }, 500);
                 
                    this.ifImageAvailable = this.userDetails.customerDetail.avatarPath;
                    this.imageUrl =
                        this.imagePath +
                        '?path=' +
                        this.userDetails.customerDetail.avatarPath +
                        '&name=' +
                        this.userDetails.customerDetail.avatar +
                        '&width=100&height=100';
                    this.ifCompanyImageAvailable = this.userDetails.companyLogoPath;

                    if (this.userDetails.companyLogo) {
                        this.companyImageUrl = this.imagePath + '?path=' + this.userDetails.companyLogoPath + '&name=' + this.userDetails.companyLogo + '&width=100&height=100';
                    }

                    if (this.userDetails.aboutPageVideo) {
                        this.aboutPageVideoUrl = this.uploadPath + '/' + this.userDetails.aboutPageVideo;
                    }

                    if (this.userDetails.shopImage) {
                        this.shopImageUrl = this.imagePath + '?path=' + this.userDetails.shopImagePath + '&name=' + this.userDetails.shopImage + '&width=100&height=100';
                    }

                    if (this.userDetails.avatarImage) {
                        this.avatarImageUrl = this.imagePath + '?path=' + this.userDetails.avatarImagePath + '&name=' + this.userDetails.avatarImage + '&width=100&height=100';
                    }
                    this.ifCoverImageAvailable = this.userDetails.innerShopImagePath;
                    this.coverImageUrl = this.imagePath + '?path=' + this.userDetails.innerShopImagePath + '&name=' + this.userDetails.innerShopImage + '&width=100&height=100';
                }
            })
        );
        this.subscriptions.push(
            this.commonSandbox.getProfile$.pipe(take(2)).subscribe(data => {
                if (data && Object.keys(data).length) {
                    console.log(" *** active step fired ");
                    this.activeStep = this.userDetails.tab;
                }
            })
        );

        this.subscriptions.push(this.aiSandbox.text$.subscribe((data: any) => {
            // console.log(" **** this.aiSandbox.text$ ", data, data.includes('return policy'));
            if (data && data.text) {
                this.aiSandbox.textSuccess({ text: '', voiceText: '', append: true, type: 2 });
                console.log("data of ai");
                console.log(data);
                const voiceText = data.voiceText;
                const suggestedText = data.text;

                if (voiceText.includes('return policy')) {
                    this.shopDescriptionForm.controls['returnPolicy'].setValue(this.aiSandbox.nl2br(suggestedText, false));
                } else if (voiceText.includes('shipping policy')) {
                    this.shopDescriptionForm.controls['shippingPolicy'].setValue(this.aiSandbox.nl2br(suggestedText, false));
                }  else if (voiceText.includes('our_story')) {
                    this.storyForm.controls['ourStory'].setValue(this.aiSandbox.nl2br(suggestedText, false));
                }  else if (voiceText.includes('about_us')) {
                    this.storyForm.controls['companyDescription'].setValue(this.aiSandbox.nl2br(suggestedText, false));
                }
            }
        }))
    }


    sendMessage(){

        if(!this.speak){
            return;
        }
        this.speak = false;
       
        this.aiSandbox.tab$.subscribe(tab => {
            console.log(" ***** tab ", tab);
            if(tab == 'shop_details'){
                this.triggerMessage();
            }
        })

        
    }


    triggerMessage(){
      
        

            if(this.activeStep == '1'){
                    this.aiSandbox.sendMessage({
                         message: "Please enter your shop details",
                         type: 'shop_details'
                  });
             }
     
             if(this.activeStep == '2'){
                 this.aiSandbox.setTabStep({
                     tab: "shop_details",
                     step: "2"
                 })
                 this.aiSandbox.sendMessage({
                     message: "Do you need help writing your shipping policy?. Click on the write shipping policy. To write a return policy, Click on the write return policy",
                     type: -1
                 });
              
             }
     
     
             if(this.activeStep == '3'){
                 this.aiSandbox.setTabStep({
                     tab: "shop_details",
                     step: "3"
                 })
            
                 this.aiSandbox.sendMessage({
                     message: "Click on write our story to create a story about your shop. Click on write about me to write a paragraph about your shop.",
                     type: -1
                 });
              
             }
     
             if(this.activeStep == '4'){
                 this.aiSandbox.sendMessage({
                     open: true,
                     message: "Please provide payment information which will be visible to your customers. You can include what kind of payment modes you accept like paypal, stripe, credit cards etcetera.",
                     type: -1
                 });         
             }
     
             if(this.activeStep == '5'){
                 this.aiSandbox.sendMessage({
                     open: true,
                     message: "Upload a picture of yourself. It will be used to generate an avatar for your shop. It is not mandatory you can skip this step if you want.",
                     type: -1
                 });         
             }
     
             if(this.activeStep == '6' && !this.userDetails.termsAccepted){
                 this.aiSandbox.sendMessage({
                     open: true,
                     message: "Please accept the terms and conditions.",
                     type: -1
                 });         
             } else if(this.activeStep == '6'){
                this.aiSandbox.setTabStep({
                    tab: "",
                    step: ""
                })
             }
          


        
    }


    setupDomainFields() {
        this.domainSetup = true;

        this.godaddySetupForm.controls['godaddyCustomerId'].setValidators([Validators.required]);
        this.godaddySetupForm.controls['godaddyCustomerId'].updateValueAndValidity();

        this.godaddySetupForm.controls['domain'].setValidators([Validators.required]);
        this.godaddySetupForm.controls['domain'].updateValueAndValidity();
    }



    // set value to companyForm
    setcompanyForm(data) {
        if (data.companyCountryId) {
            this.countryId = data.companyCountryId;
            this.getZoneList();
        }

        console.log(" ****  data ",data);

        this.companyForm.controls['companyName'].setValue(data['companyName']);
        this.companyForm.controls['companyAddress1'].setValue(data['companyAddress1']);
        this.companyForm.controls['city'].setValue(data['companyCity']);
        this.companyForm.controls['state'].setValue(data['companyState']);
        this.companyForm.controls['pincode'].setValue(data['pincode']);
        if(data['countryIds'].length > 0){
            this.companyForm.controls['countries'].setValue(data['countryIds']);
        } else {
            this.companyForm.controls['countries'].setValue([data.companyCountryId]);
        }
      
        this.companyForm.controls['mobileNo'].setValue(data['companyMobileNumber']);
        this.companyForm.controls['subdomain'].setValue(data['subdomain']);
        this.godaddySetupForm.controls['domain'].setValue(data['domain']);
        this.companyForm.controls['companyEmailId'].setValue(data['customerDetail']['email']);
        this.companyForm.controls['firstName'].setValue(data['customerDetail']['firstName']);
        this.companyForm.controls['lastName'].setValue(data['customerDetail']['lastName']);
        this.companyForm.controls['companyVatNumber'].setValue(data['companyVatNumber']);
        this.companyForm.controls['minimumOrderAmount'].setValue(data['minimumPrice']);

        this.shopDescriptionForm.controls['returnPolicy'].setValue(data['returnPolicy']);
        this.shopDescriptionForm.controls['shippingPolicy'].setValue(data['shippingPolicy']);

        this.shopDescriptionForm.controls['aboutPageVideoType'].setValue(data['aboutPageVideoType']);
        this.shopDescriptionForm.controls['additionalInfo1'].setValue(data['additionalInfo1']);
        this.shopDescriptionForm.controls['additionalInfo2'].setValue(data['additionalInfo2']);


        this.storyForm.controls['companyDescription'].setValue(data['companyDescription']);
        this.storyForm.controls['ourStory'].setValue(data['ourStory']);
        this.storyForm.controls['rewards'].setValue(data['rewards']);

        this.paymentInfoForm.controls['paymentInfo'].setValue(data['paymentInformation']);
        this.termsForm.controls['termsAccepted'].setValue(data['termsAccepted'] ? true : false);
    }


    saveCompanyDetails() {
        if (!this.companyForm.valid) {
            this.validateAllFormFields(this.companyForm);
            console.log(this.companyForm);
            return;
        }
        if (this.companyForm.controls['minimumOrderAmount'].value < 0) {
            return;
        }
        const params: any = {};
        params.firstName = this.companyForm.controls['firstName'].value;
        params.email = this.companyForm.controls['companyEmailId'].value;//this.userDetails['customerDetail']['email'];
        params.mobileNumber = this.companyForm.controls['mobileNo'].value;//this.userDetails['customerDetail']['mobileNumber'];
        params.lastName = this.companyForm.controls['lastName'].value;
        params.designation = this.userDetails['designation'];
        params.companyName = this.companyForm.controls['companyName'].value;
        params.countries = this.companyForm.controls['countries'].value;
        params.companyAddress1 = this.companyForm.controls['companyAddress1'].value;
        params.companyAddress2 = this.userDetails['companyAddress2']
        params.companyCity = this.companyForm.controls['city'].value;
        params.companyState = this.companyForm.controls['state'].value;
        params.companyCountryId = this.userDetails['companyCountryId'];
        params.pincode = this.companyForm.controls['pincode'].value;
        params.companyMobileNumber = this.companyForm.controls['mobileNo'].value;
        params.subdomain = this.companyForm.controls['subdomain'].value;
        params.domain = this.godaddySetupForm.controls['domain'].value;
        params.companyEmailId = this.companyForm.controls['companyEmailId'].value;
        params.companyWebsite = this.userDetails['companyWebsite'];
        params.rewards = this.userDetails['rewards'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyPanNumber = this.userDetails['companyPanNumber'];
        params.bankInformation = this.userDetails['bankInformation'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.vendorId = this.vendorId['vendorId'];
        params.minimumPrice = this.companyForm.controls['minimumOrderAmount'].value;
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyVatNumber = this.companyForm.controls['companyVatNumber'].value;
        params.isProfileCompleted = 0;
        params.returnPolicy = this.userDetails['returnPolicy'];
        params.shippingPolicy = this.userDetails['shippingPolicy'];
        params.aboutPageVideoType = this.userDetails['aboutPageVideoType'];
        params.paymentInformation = this.userDetails['paymentInformation'];
        params.tab = 2;
   
        console.log("params of company detail");
        console.log(params);
        this.commonSandbox.doEditProfile(params);
        this.subscriptions.push(this.commonSandbox.editProfileLoaded$.subscribe(data => {
            if (data === true) {
                this.commonSandbox.doGetProfile();
                setTimeout(() => {
                    this.activeStep ="2";
                    this.triggerMessage();
                },0);
            
              
            }
        }));
    }

    saveShopDescription() {
        if (!this.shopDescriptionForm.valid) {
            this.validateAllFormFields(this.shopDescriptionForm);
            return;
        }

        // if (this.imageError) {
        //     return;
        // }

        // if(!this.shopImageUrl){
        //     this.imageError = 'Please upload shop image';
        //     return;
        // }

        // if (!this.companyImageUrl) {
        //     this.imageError = 'Please upload company logo';
        //     return;
        // }

        // if (!this.ifCoverImageAvailable) {
        //     this.imageError = 'Please upload banner image';
        //     return;
        // }

        const params: any = {};
        params.firstName = this.userDetails['customerDetail']['firstName'];
        params.email = this.userDetails['customerDetail']['email'];
        params.mobileNumber = this.userDetails['customerDetail']['mobileNumber'];
        params.lastName = this.userDetails['customerDetail']['lastName'];
        params.designation = this.userDetails['designation'];
        params.companyName = this.userDetails['companyName'];
        params.companyAddress1 = this.userDetails['companyAddress1'];
        params.companyAddress2 = this.userDetails['companyAddress2']
        params.companyCity = this.userDetails['companyCity'];
        params.companyState = this.userDetails['companyState'];
        params.companyCountryId = this.userDetails['companyCountryId'];
        params.pincode = this.userDetails['pincode'];
        params.rewards = this.userDetails['rewards'];
        params.companyMobileNumber = this.userDetails['companyMobileNumber'];
        params.companyEmailId = this.userDetails['customerDetail']['email'];
        params.companyWebsite = this.userDetails['companyWebsite'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyPanNumber = this.userDetails['companyPanNumber'];
        params.bankInformation = this.userDetails['bankInformation'];
        params.vendorId = this.vendorId['vendorId'];
        params.minimumPrice = this.userDetails['minimumPrice'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyVatNumber = this.userDetails['companyVatNumber'];
        params.isProfileCompleted = 0;
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.returnPolicy = this.shopDescriptionForm.controls['returnPolicy'].value;
        params.shippingPolicy = this.shopDescriptionForm.controls['shippingPolicy'].value;
        params.aboutPageVideoType = this.shopDescriptionForm.controls['aboutPageVideoType'].value;
        params.additionalInfo1 = this.shopDescriptionForm.controls['additionalInfo1'].value;
        params.additionalInfo2 = this.shopDescriptionForm.controls['additionalInfo2'].value;
        params.aboutPageVideo = this.aboutPageVideo;
        params.companyLogo = this.companyImage;
        params.innerShopImage = this.coverImage;
        params.shopImage = this.shopImage;
        params.subdomain = this.companyForm.controls['subdomain'].value;
        params.domain = this.godaddySetupForm.controls['domain'].value;
        params.paymentInformation = this.userDetails['paymentInformation'];
        params.tab = 3;
    

        this.commonSandbox.doEditProfile(params);
        this.subscriptions.push(this.commonSandbox.editProfileLoaded$.subscribe(data => {
            if (data === true) {
                this.commonSandbox.doGetProfile();
                setTimeout(() => {
                    this.activeStep ="3";
                    this.triggerMessage();
                },0);
            
                
            }
        }));
    }


    saveOurStory() {
        if (!this.storyForm.valid) {
            this.validateAllFormFields(this.storyForm);
            return;
        }
        const params: any = {};
        params.firstName = this.userDetails['customerDetail']['firstName'];
        params.email = this.userDetails['customerDetail']['email'];
        params.mobileNumber = this.userDetails['customerDetail']['mobileNumber'];
        params.lastName = this.userDetails['customerDetail']['lastName'];
        params.designation = this.userDetails['designation'];
        params.companyName = this.userDetails['companyName'];
        params.companyAddress1 = this.userDetails['companyAddress1'];
        params.companyAddress2 = this.userDetails['companyAddress2']
        params.companyCity = this.userDetails['companyCity'];
        params.companyState = this.userDetails['companyState'];
        params.companyCountryId = this.userDetails['companyCountryId'];
        params.pincode = this.userDetails['pincode'];
        params.companyMobileNumber = this.userDetails['companyMobileNumber'];
        params.companyEmailId = this.userDetails['customerDetail']['email'];
        params.companyWebsite = this.userDetails['companyWebsite'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyPanNumber = this.userDetails['companyPanNumber'];
        params.bankInformation = this.userDetails['bankInformation'];
        params.vendorId = this.vendorId['vendorId'];
        params.minimumPrice = this.userDetails['minimumPrice'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyVatNumber = this.userDetails['companyVatNumber'];
        params.isProfileCompleted = 0;
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.returnPolicy = this.userDetails['returnPolicy'];
        params.shippingPolicy = this.userDetails['shippingPolicy'];
        params.aboutPageVideoType = this.userDetails['aboutPageVideoType'];
        params.companyDescription = this.storyForm.controls['companyDescription'].value;
        params.rewards = this.storyForm.controls['rewards'].value;
        params.ourStory = this.storyForm.controls['ourStory'].value;
        params.paymentInformation = this.userDetails['paymentInformation'];
        params.subdomain = this.companyForm.controls['subdomain'].value;
        params.domain = this.godaddySetupForm.controls['domain'].value;
        params.tab = 4;


        this.commonSandbox.doEditProfile(params);
        this.subscriptions.push(this.commonSandbox.editProfileLoaded$.subscribe(data => {
            if (data === true) {
                this.commonSandbox.doGetProfile();
                setTimeout(() => {
                    this.activeStep ="4";
                    this.triggerMessage();
                },0);
            }
        }));
    }

    savePaymentInfo() {
        if (!this.paymentInfoForm.valid) {
            this.validateAllFormFields(this.paymentInfoForm);
            return;
        }
        const params: any = {};
        params.firstName = this.userDetails['customerDetail']['firstName'];
        params.email = this.userDetails['customerDetail']['email'];
        params.mobileNumber = this.userDetails['customerDetail']['mobileNumber'];
        params.lastName = this.userDetails['customerDetail']['lastName'];
        params.designation = this.userDetails['designation'];
        params.companyName = this.userDetails['companyName'];
        params.companyAddress1 = this.userDetails['companyAddress1'];
        params.companyAddress2 = this.userDetails['companyAddress2']
        params.companyCity = this.userDetails['companyCity'];
        params.companyState = this.userDetails['companyState'];
        params.companyCountryId = this.userDetails['companyCountryId'];
        params.pincode = this.userDetails['pincode'];
        params.companyMobileNumber = this.userDetails['companyMobileNumber'];
        params.companyEmailId = this.userDetails['customerDetail']['email'];
        params.companyWebsite = this.userDetails['companyWebsite'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyPanNumber = this.userDetails['companyPanNumber'];
        params.bankInformation = this.userDetails['bankInformation'];
        params.vendorId = this.vendorId['vendorId'];
        params.minimumPrice = this.userDetails['minimumPrice'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyVatNumber = this.userDetails['companyVatNumber'];
        params.rewards = this.userDetails['rewards'];
        params.isProfileCompleted = 0;
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.returnPolicy = this.userDetails['returnPolicy'];
        params.shippingPolicy = this.userDetails['shippingPolicy'];
        params.aboutPageVideoType = this.userDetails['aboutPageVideoType'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.paymentInformation = this.paymentInfoForm.controls['paymentInfo'].value;
        params.subdomain = this.companyForm.controls['subdomain'].value;
        params.domain = this.godaddySetupForm.controls['domain'].value;
        params.tab = 5;
       

        this.commonSandbox.doEditProfile(params);
        this.subscriptions.push(this.commonSandbox.editProfileLoaded$.subscribe(data => {
            if (data === true) {
                this.commonSandbox.doGetProfile();
                setTimeout(() => {
                    this.activeStep ="5";
                    this.triggerMessage();
                },0);
            }
        }));
    }

    saveAvatar() {
        const params: any = {};
        params.firstName = this.userDetails['customerDetail']['firstName'];
        params.email = this.userDetails['customerDetail']['email'];
        params.mobileNumber = this.userDetails['customerDetail']['mobileNumber'];
        params.lastName = this.userDetails['customerDetail']['lastName'];
        params.designation = this.userDetails['designation'];
        params.companyName = this.userDetails['companyName'];
        params.companyAddress1 = this.userDetails['companyAddress1'];
        params.companyAddress2 = this.userDetails['companyAddress2']
        params.companyCity = this.userDetails['companyCity'];
        params.companyState = this.userDetails['companyState'];
        params.companyCountryId = this.userDetails['companyCountryId'];
        params.pincode = this.userDetails['pincode'];
        params.companyMobileNumber = this.userDetails['companyMobileNumber'];
        params.companyEmailId = this.userDetails['customerDetail']['email'];
        params.companyWebsite = this.userDetails['companyWebsite'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyPanNumber = this.userDetails['companyPanNumber'];
        params.bankInformation = this.userDetails['bankInformation'];
        params.vendorId = this.vendorId['vendorId'];
        params.minimumPrice = this.userDetails['minimumPrice'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyVatNumber = this.userDetails['companyVatNumber'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.rewards = this.userDetails['rewards'];
        params.ourStory = this.userDetails['ourStory'];
        params.returnPolicy = this.userDetails['returnPolicy'];
        params.shippingPolicy = this.userDetails['shippingPolicy'];
        params.aboutPageVideoType = this.userDetails['aboutPageVideoType'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.paymentInformation = this.userDetails['paymentInformation'];
        params.avatarImage = this.avatarImage;
        params.subdomain = this.companyForm.controls['subdomain'].value;
        params.domain = this.godaddySetupForm.controls['domain'].value;
        params.tab = 6;
    

        this.commonSandbox.doEditProfile(params);
        this.subscriptions.push(this.commonSandbox.editProfileLoaded$.subscribe(data => {
            if (data === true) {
                this.commonSandbox.doGetProfile();
                setTimeout(() => {
                    this.activeStep ="6";
                    this.triggerMessage();
                },0);
            }
        }));
    }

    enableShop() {
        const params: any = {};
        params.firstName = this.userDetails['customerDetail']['firstName'];
        params.email = this.userDetails['customerDetail']['email'];
        params.mobileNumber = this.userDetails['customerDetail']['mobileNumber'];
        params.lastName = this.userDetails['customerDetail']['lastName'];
        params.designation = this.userDetails['designation'];
        params.companyName = this.userDetails['companyName'];
        params.companyAddress1 = this.userDetails['companyAddress1'];
        params.companyAddress2 = this.userDetails['companyAddress2']
        params.companyCity = this.userDetails['companyCity'];
        params.companyState = this.userDetails['companyState'];
        params.companyCountryId = this.userDetails['companyCountryId'];
        params.pincode = this.userDetails['pincode'];
        params.companyMobileNumber = this.userDetails['companyMobileNumber'];
        params.companyEmailId = this.userDetails['customerDetail']['email'];
        params.companyWebsite = this.userDetails['companyWebsite'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyPanNumber = this.userDetails['companyPanNumber'];
        params.bankInformation = this.userDetails['bankInformation'];
        params.vendorId = this.vendorId['vendorId'];
        params.minimumPrice = this.userDetails['minimumPrice'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyVatNumber = this.userDetails['companyVatNumber'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.returnPolicy = this.userDetails['returnPolicy'];
        params.shippingPolicy = this.userDetails['shippingPolicy'];
        params.aboutPageVideoType = this.userDetails['aboutPageVideoType'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.paymentInformation = this.userDetails['paymentInformation'];
        params.rewards = this.userDetails['rewards'];
        params.isProfileCompleted = 1;
        params.showShop = 1;
        params.subdomain = this.companyForm.controls['subdomain'].value;
        params.domain = this.godaddySetupForm.controls['domain'].value;
        this.commonSandbox.doEditProfile(params);
        this.subscriptions.push(this.commonSandbox.editProfileLoaded$.subscribe(data => {
            if (data === true) {
                this.commonSandbox.doGetProfile();
            }
        }));
    }


    saveTerms() {
        if (!this.termsForm.valid) {
            this.validateAllFormFields(this.termsForm);
            return;
        }
        const params: any = {};
        params.firstName = this.userDetails['customerDetail']['firstName'];
        params.email = this.userDetails['customerDetail']['email'];
        params.mobileNumber = this.userDetails['customerDetail']['mobileNumber'];
        params.lastName = this.userDetails['customerDetail']['lastName'];
        params.designation = this.userDetails['designation'];
        params.companyName = this.userDetails['companyName'];
        params.companyAddress1 = this.userDetails['companyAddress1'];
        params.companyAddress2 = this.userDetails['companyAddress2']
        params.companyCity = this.userDetails['companyCity'];
        params.companyState = this.userDetails['companyState'];
        params.companyCountryId = this.userDetails['companyCountryId'];
        params.pincode = this.userDetails['pincode'];
        params.companyMobileNumber = this.userDetails['companyMobileNumber'];
        params.companyEmailId = this.userDetails['customerDetail']['email'];
        params.companyWebsite = this.userDetails['companyWebsite'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyPanNumber = this.userDetails['companyPanNumber'];
        params.bankInformation = this.userDetails['bankInformation'];
        params.vendorId = this.vendorId['vendorId'];
        params.minimumPrice = this.userDetails['minimumPrice'];
        params.companyGstNumber = this.userDetails['companyGstNumber'];
        params.companyVatNumber = this.userDetails['companyVatNumber'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.returnPolicy = this.userDetails['returnPolicy'];
        params.shippingPolicy = this.userDetails['shippingPolicy'];
        params.aboutPageVideoType = this.userDetails['aboutPageVideoType'];
        params.companyDescription = this.userDetails['companyDescription'];
        params.ourStory = this.userDetails['ourStory'];
        params.paymentInformation = this.userDetails['paymentInformation'];
        params.rewards = this.userDetails['rewards'];
        params.subdomain = this.companyForm.controls['subdomain'].value;
        params.domain = this.godaddySetupForm.controls['domain'].value;
        params.isProfileCompleted = 1;
        params.showShop = 1;
        params.termsAccepted = this.termsForm.controls['termsAccepted'].value ? 1 : 0;
        this.commonSandbox.doEditProfile(params);
        this.subscriptions.push(this.commonSandbox.editProfileLoaded$.pipe(take(2)).subscribe(data => {
            if (data === true) {
                this.commonSandbox.doGetProfile();
                this.dataSubmitted.emit('shipping');
            }
        }));
    }

    /**
     * upload new user image
     *
     * @param el refer the HTMLElement filePath
     * it will activate the click function of el
     */
    uploadButtonClickForUser() {
        const el: HTMLElement = this.filePathForUser.nativeElement as HTMLElement;
        el.click();
    }
    uploadButtonClickForCompany() {
        const el: HTMLElement = this.filePathForCompany.nativeElement as HTMLElement;
        el.click();
    }

    uploadButtonClickForShopImage() {
        const el: HTMLElement = this.filePathForShopImage.nativeElement as HTMLElement;
        el.click();
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    // calls convertBase64 to convert data into base64 formt
    uploadChange($event): void {
        this.ifImageAvailable = 'avatar';
        this.convertBase64($event.target);
    }

    // calls convertBase64 to convert data into base64 formt
    uploadCompanyImageChange($event): void {
        this.convertCompanyImageBase64($event.target);
        $event.target.value = null;
    }

    // convert image file into Base64 format
    convertBase64(inputValue: any): void {
        const file: File = inputValue.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = e => {
            this.imageUrl = myReader.result;
            this.userImage = myReader.result;
        };
        myReader.readAsDataURL(file);
    }

    // convert image file into Base64 format
    convertCompanyImageBase64(inputValue: any): void {
        const file: File = inputValue.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = e => {
            const image: any = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];
                if (img_height < 150 && img_width < 150) {
                    this.imageError = 'Shop image must be of 150 * 150';
                    return false;
                } else {
                    this.imageError = '';
                    this.companyImageUrl = myReader.result;
                    this.companyImage = myReader.result;
                }
            };


        };
        myReader.readAsDataURL(file);
    }

    selectCountry(event) {
        this.countryId = +event.target.value;
        //   this.companyForm.controls['state'].setValue('');
        // if (event.target.value === '99') {
        //     this.getZoneList();
        // }
    }

    getZoneList() {
        const params: any = {};
        params.limit = '';
        params.offset = '';
        params.keyword = '';
        params.count = '';
        this.commonSandbox.getZoneList(params);
    }

    uploadButtonClickForCover() {
        const el: HTMLElement = this.filePathForCover.nativeElement as HTMLElement;
        el.click();
    }

    uploadAvatar() {
        const el: HTMLElement = this.filePathForAvatar.nativeElement as HTMLElement;
        el.click();
    }

    avatarChange(event) {
        const file: File = event.target.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = e => {
            this.avatarImageUrl = myReader.result;
            this.avatarImage = myReader.result;
        };
        myReader.readAsDataURL(file);
    }

    uploadShopImageChange(event) {
        const file: File = event.target.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = e => {
            const image: any = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];
                if (img_height < 400 && img_width < 500) {
                    this.imageError = 'Shop image must be of 400 * 500';
                    return false;
                } else {
                    this.imageError = '';
                    this.shopImageUrl = myReader.result;
                    this.shopImage = myReader.result;
                }
            };

        };
        myReader.readAsDataURL(file);
    }

    aboutPageVideoClick() {
        const el: HTMLElement = this.aboutPageVideoEl.nativeElement as HTMLElement;
        el.click();
    }

    aboutPageVideoChange(event) {
        const file: File = event.target.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = e => {
            this.aboutPageVideoUrl = myReader.result;
            this.aboutPageVideo = myReader.result;
        };
        myReader.readAsDataURL(file);
    }

    uploadCoverImageChange($event): void {
        this.convertCoverImageBase64($event.target);
    }

    // convert image file into Base64 format
    convertCoverImageBase64(inputValue: any): void {
        const file: File = inputValue.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = e => {

            const image: any = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                // const img_height = rs.currentTarget['height'];
                // const img_width = rs.currentTarget['width'];
                // if (img_height < 700 && img_width < 1600) {
                //     this.imageError = 'Banner image must be of 1600 * 700';
                //     return false;
                // } else {
                this.imageError = '';
                this.ifCoverImageAvailable = 'avatar';
                this.coverImageUrl = myReader.result;
                this.coverImage = myReader.result;
                //}
            };
        };
        myReader.readAsDataURL(file);
    }

    ngOnDestroy() {
        this.aiSandbox.showInsert(false);
        this.aiSandbox.setTabStep({
            tab: "",
            step: ""
        })
        this.subscriptions.forEach(each => each.unsubscribe());
    }
}
