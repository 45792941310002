import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './default/common/layout/layout.component';
import { AuthGuard } from './core/providers/guards/auth-guard';

const routes: Routes = [

	{
		path: 'auth',
		loadChildren: () => import('./default/auth/auth.module').then(m => m.AuthModule),
		canActivate: [AuthGuard]
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'products',
				loadChildren: () => import('./default/pages/component/products/products.module').then(m => m.ProductsModule),
			},
			{
				path: 'manufactured-products',
				loadChildren: () => import('./default/pages/component/manufactured-product/manufactured-product.module').then(m => m.ManufacturedProductModule),
			},
			
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full'
			},
			{
				path: 'orders',
				loadChildren: () => import('./default/pages/component/order/order.module').then(m => m.OrdersModule),
			},

			{
				path: 'dashboard',
				loadChildren: () => import('./default/pages/component/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'marketplace-approval',
				loadChildren: () => import('./default/pages/component/market-place-approval/market-place-approval.module').then(m => m.MarketPlaceApprovalModule),
			},

			{
				path: 'settings',
				loadChildren: () => import('./default/pages/component/settings/settings.module').then(m => m.SettingsModule),
			},
			{
				path: 'promotions',
				loadChildren: () => import('./default/pages/component/promotions/promotions.module').then(m => m.PromotionsModule),
			},
			{
				path: 'payments',
				loadChildren: () => import('./default/pages/component/payments/payments.module').then(m => m.PaymentsModule),
			},
			{
				path: 'payroll',
				loadChildren: () => import('./default/pages/component/payroll/payroll.module').then(m => m.PayrollModule),
			},
			{
				path: 'customers',
				loadChildren: () => import('./default/pages/component/customers/customers.module').then(m => m.CustomersModule),
			},
			{
				path: 'feedbacks',
				loadChildren: () => import('./default/pages/component/feedbacks/feedbacks.module').then(m => m.FeedbacksModule),
			},

			{
				path: 'pos',
				loadChildren: () => import('./default/pages/component/pos/pos.module').then(m => m.POSModule),
			},

			{
				path: 'booking',
				loadChildren: () => import('./default/pages/component/Booking/booking.module').then(m => m.BookingModule),
			},

			{
				path: 'google-analytics',
				loadChildren: () => import('./default/pages/component/google/google-analytics.module').then(m => m.GoogleAnalytics),
			},

			{
				path: 'shop-supplies',
				loadChildren: () => import('./default/pages/component//shop-supplies/shop-supplies.module').then(m => m.ShopSuppliesModule),
			},

			{
				path: 'digital-me',
				loadChildren: () => import('./default/pages/component/digital-me/digital-me.module').then(m => m.DigitalMeModule),
			},
		],
	}

];
@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
