import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public className = '';

  constructor() { }

  ngOnInit() {
    let className = localStorage.getItem('theme-class');
    if (className) {
      this.className = className;
    }
  }

  onClassChange(className) {
    this.className = className;
    localStorage.setItem('theme-class', className);
  }

}
