
export const menu = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    image: 'assets/imgs/dashboard.svg',
    activeImage: 'assets/imgs/dashboard-act.svg',
    submenu: [],
    extralink: false,
    class: '',
    disable: false,
    key: 'dashboard',
    lang: "Dashboard.Dashboard",
    showIncompelete: false
  },

  {
    path: '/pos/new-sale',
    title: 'POS',
    image: 'assets/imgs/POS-icon.svg',
    activeImage: 'assets/imgs/POS-icon-act.svg',
    key: 'pos',
    lang: "Dashboard.POS",
    showIncompelete: false,
    submenu: [
      {
        path: '/pos/new-sale',
        title: 'New Sale',
        lang: "New Sale",
      },
      {
        path: '/pos/close-shift',
        title: 'Close Shift',
        lang: "Close Shift",
      },
      {
        path: '/pos/shift-report',
        title: 'Shift Report',
        lang: "Shift Report",
      },
      {
        path: '/pos/saved-cart',
        title: 'Saved Cart',
        lang: "Saved Cart",
      },

    ],
    class: '',
    extralink: false,
    disable: true

  },
  {
    path: '/orders',
    title: 'Sales',
    key: 'orders',
    lang: "Dashboard.Orders",
    image: 'assets/imgs/Orders-icon.svg',
    activeImage: 'assets/imgs/Orders-icon-act.svg',
    showIncompelete: false,
    submenu: [
      {
        path: '/orders/list',
        title: 'Recent Orders'
      },
      {
        path: '/orders/update',
        title: 'Update Order Status',
        hide: true
      },
      {
        path: '/orders/all-orders',
        title: 'All Orders'
      },
      {
        path: '/orders/abandoned-orders',
        title: 'Abandoned Orders',
        hide: true
      },
      {
        path: '/orders/orders-list',
        title: 'Manage Orders',
        hide: true
      },
      {
        path: '/orders/archive-orders',
        title: 'Archive Orders',
        hide: true
      },
      {
        path: '/orders/cancel-orders',
        title: 'Cancel Requests',
        hide: true
      },
      {
        path: '/orders/order-resend-requests',
        title: 'Order Resend Requests',
        hide: true
      },
      {
        path: '/orders/quotation-list',
        title: 'Quotation Request List',
        hide: true
      },
      {
        path: '/orders/order-invoice',
        title: 'Orders Invoice List',
        hide: true
      },
      {
        path: '/orders/sales-report-list',
        title: 'Sales Report',
        hide: true
      },
      {
        path: '/orders/quotes',
        title: 'Quotes',
        hide: true
      },
    ],
    class: '',
    extralink: false,
    disable: true
  },
  {
    path: '/payments',
    title: 'Payments',
    key: 'payments',
    lang: "Dashboard.Payments",
    image: 'assets/imgs/card.svg',
    disable: false,
    activeImage: 'assets/imgs/card-act.svg',
    showIncompelete: false,
    submenu: [
      {
        path: '/payments/list',
        title: 'Payments'
      },
      {
        path: '/payments/earnings',
        title: 'Earnings',
        hide: true
      },
      {
        path: '/payments/archive',
        title: 'Archive Payments',
        hide: true
      },
      {
        path: '/payments/settings',
        title: 'Payment Gateway Settings',
        hide: true
      }

    ],
    class: '',
    extralink: false
  },
  {
    path: '/products',
    title: 'Products',
    key: 'products',
    lang: "Dashboard.Products",
    image: 'assets/imgs/products.svg',
    activeImage: 'assets/imgs/products-act.svg',
    showIncompelete: false,
    submenu: [

      {
        path: '/products/list',
        title: 'Product List'
      },
      // {
      //   path: '/products/categories',
      //   title: 'Categories'
      // },
      {
        path: '/products/new-lists',
        title: 'Newly Added'
      },
      {
        path: '/products/active-lists',
        title: 'Active Products'
      },
      {
        path: '/products/inactive-lists',
        title: 'Inactive Products'
      },
      {
        path: '/products/gift-cards',
        title: 'Gift Cards',
        hide: true
      },
      {
        path: '/products/reviews',
        title: 'Reviews',
        hide: true
      },
    ],
    class: '',
    extralink: false,
    disable: true
  },
  {
    path: '/manufactured-products/dashboard',
    title: 'Manufacturing',
    key: 'customers',
    lang: "Manufacturing",
    image: 'assets/imgs/manufacture.svg',
    activeImage: 'assets/imgs/products-act.svg',
    showIncompelete: false,
    submenu: [
      {
        path: '/manufactured-products/dashboard',
        title: 'Dashboard.Dashboard'
      },
      {
        path: '/manufactured-products/schedule',
        title: 'Manage Schedules'
      },
      {
        path: '/manufactured-products/list',
        title: 'Manufactured Products'
      },
      {
        path: '/manufactured-products/calculate-cost',
        title: 'Build a product'
      },
      {
        path: '/manufactured-products/batch-list',
        title: 'Batches'
      },
      {
        path: '/manufactured-products/ingredient-inventory',
        title: 'Add inventory'
      },
      {
        path: '/manufactured-products/ingredients',
        title: 'Add Inventory components'
      },

    ],
    class: '',
    extralink: false,
    disable: true
  },

  // {
  //     path: '',
  //     title: 'Track',
  //     image: 'assets/imgs/track.svg',
  //     activeImage: 'assets/imgs/track.svg',
  //     submenu: [],
  //     class: '',
  //     extralink: false
  // },

  {
    path: '/shop-supplies',
    title: 'Purchase Supplies',
    key: 'supplies',
    image: 'assets/imgs/suplier.svg',
    activeImage: 'assets/imgs/suplier-act.svg',
    showIncompelete: false,
    lang: "Dashboard.Supplies",
    submenu: [

      // {
      //   path: '/products/list',
      //   title: 'Product List'
      // },
      // {
      //   path: '/products/categories',
      //   title: 'Categories'
      // },

    ],
    class: '',
    extralink: false,
    disable: true
  },
  {
    path: '/customers',
    title: 'Customers',
    key: 'customers',
    lang: "Dashboard.Customers",
    image: 'assets/imgs/customer-1.svg',
    activeImage: 'assets/imgs/Avatar-recording-act.svg',
    showIncompelete: false,
    submenu: [
      {
        path: '/customers/list',
        title: 'Overview'
      },
      {
        path: '/customers/profiles',
        title: 'Profiles'
      },
      {
        path: '/customers/groups',
        title: 'Groups'
      },
      {
        path: '/customers/inbound-dashboard',
        title: 'Inbound Dashboard',
        key: 'customerSupport',
      },
      {
        path: '/customers/customer-service-tickets',
        title: 'Customer Service Tickets',
        key: 'customerSupport',
      },
      {
        path: '/customers/keyword-tags',
        title: 'Keyword Tags',
        key: 'customerSupport',
      },
      {
        path: '/customers/offline-messages',
        title: 'Offline Auto Response',
        key: 'customerSupport',
      },
      {
        path: '/customers/ai-customer-support-versions',
        title: 'Train Customer Support AI',
        key: 'customerSupport',
      },


      // {
      //   path: '/customers/auto-reply',
      //   title: 'Auto Reply For Social Media',
      //   key: 'customerSupport',
      // },

    ],
    class: '',
    extralink: false,
    disable: true
  },

  {
    path: '',
    title: 'Social Hub',
    lang: "Dashboard.SocialHub",
    image: 'assets/imgs/Social-hub-icon.svg',
    activeImage: 'assets/imgs/Social-hub-icon-act.svg',
    submenu: [],
    class: '',
    extralink: false,
    disable: true,
    key: 'socialHub',
    showIncompelete: false,
  },

  {
    path: '/booking/vendor-time-slot',
    title: 'Bookings',
    image: 'assets/imgs/calendar.svg',
    activeImage: 'assets/imgs/calendar-active.svg',

    class: '',
    extralink: false,
    disable: true,
    key: 'bookings',
    lang: "Dashboard.Bookings",
    submenu: [

      {
        path: '/booking/vendor-time-slot',
        title: 'Time Slots'
      },
      {
        path: '/booking/booking-request',
        title: 'Booking Request'
      },
      {
        path: '/booking/transactions',
        title: 'Booking Transactions'
      },


    ],

    showIncompelete: false,
  },

  {
    path: '/promotions/social-designs',
    title: 'Marketing',
    image: 'assets/imgs/promotions.svg',
    activeImage: 'assets/imgs/promotions-act.svg',
    key: 'promotions',
    lang: "Dashboard.Promotions",
    submenu: [
      {
        path: '/promotions/social-designs',
        title: 'Design Tool'
      },
      {
        path: '/promotions/ads',
        title: 'Manage Ads'
      },
      {
        path: '/promotions/reward-points',
        title: 'Manage Points'
      },
      {
        path: '/promotions/coupon',
        title: 'Manage Coupons'
      },

      {
        path: '/promotions/loyalty-promotions',
        title: 'Loyalty Promotions'
      },
      {
        path: '/promotions/promotional-codes',
        title: 'Promotional Codes'
      },
      {
        path: '/promotions/newsletter-template',
        title: 'Newsletter Template'
      },
      {
        path: '/promotions/newsletter-users',
        title: 'Newsletter Users'
      },
      {
        path: '/promotions/send-email-newsletter-users',
        title: 'Newsletter Logs'
      }
    ],
    class: '',
    extralink: false,
    disable: true,
    showIncompelete: false
  },
  {
    path: '/digital-me',
    title: 'Digital Me',
    image: 'assets/imgs/Digital.svg',
    activeImage: 'assets/imgs/Digital-act.svg',
    key: 'digitalMe',
    lang: "Dashboard.DigitalMe",
    submenu: [],
    class: '',
    extralink: false,
    disable: true
  },
  {
    path: '/payroll',
    title: 'Payroll',
    image: 'assets/imgs/payroll.svg',
    activeImage: 'assets/imgs/promotions-act.svg',
    key: 'bookings',
    lang: "Dashboard.Payroll",
    submenu: [
      {
        path: '/payroll',
        title: 'Add Checkin'
      },
      {
        path: '/payroll/report',
        title: 'Checkin Report',
        hide: true
      },
    ],
    class: '',
    extralink: false,
    disable: true,
    showIncompelete: false
  },
  {
    path: '/socialhub-user?chat=1',
    title: 'Messages',
    key: 'messages',
    lang: "Dashboard.Messages",
    image: 'assets/imgs/Messages.svg',
    activeImage: 'assets/imgs/Messages-act.svg',
    submenu: [],
    class: '',
    extralink: false,
    disable: true
  },
  {
    path: '/google-analytics',
    title: 'Google Analytics',
    key: 'google-analytics',
    lang: "Dashboard.GoogleAnalytics",
    image: 'assets/imgs/google.svg',
    activeImage: 'assets/imgs/google-act.svg',
    submenu: [],
    class: '',
    extralink: false,
    disable: true
  },

  {
    path: '/book-keeping',
    title: 'Bookkeeping',
    image: 'assets/imgs/bookkepping.svg',
    key: 'promotions',
    lang: "Dashboard.Bookkeeping",
    activeImage: 'assets/imgs/bookkepping-w.svg',
    submenu: [

    ],
    class: '',
    extralink: false,
    disable: true
  },

  {
    path: '/settings',
    title: 'Settings',
    key: 'settings',
    lang: "Dashboard.Settings",
    disable: false,
    image: 'assets/imgs/setting.svg',
    activeImage: 'assets/imgs/setting-act.svg',
    submenu: [
      // {
      //   path: '/settings/hardware-setup',
      //   title: 'Hardware Setup'
      // },
      {
        path: '/settings/list',
        title: 'General Details'
      },
      {
        path: '/settings/shop-details',
        title: 'Shop Details'
      },
      {
        path: '/settings/site-settings',
        title: 'Website Builder'
      },
      {
        path: '/settings/online-shop-template',
        title: 'Digital Shop Creator Tool'
      },

      {
        path: '/settings/free-shipping',
        title: 'Shippings'
      },
      {
        path: '/settings/vendor-admins/list',
        title: 'Users',
        hide: true
      },
      {
        path: '/settings/pos-setting',
        title: 'POS Settings'
      },
      // {
      //   path: '/settings/seo-container',
      //   title: 'SEO'
      // },
      {
        path: '/settings/newsletter-email',
        title: 'Email'
      },
      {
        path: '/settings/streaming-server',
        title: 'Streaming Server Configuration'
      },
      {
        path: '/settings/stockiest/list',
        title: '3rd Party Stockiest'
      },

      {
        path: '/settings/ai-integration',
        title: 'AI Integration'
      },

      {
        path: '/settings/ai-operations-version-list',
        title: 'AI Operations'
      },

    ],

    class: '',
    extralink: false
  },

  {
    path: '/promotions',
    title: 'Feedback',
    image: 'assets/imgs/feedback-1.svg',
    key: 'promotions',
    lang: "Dashboard.Feedback",
    activeImage: 'assets/imgs/Messages-act.svg',
    submenu: [

    ],
    class: '',
    extralink: false,
    disable: true
  }


];
