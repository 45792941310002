import { AuthSandbox } from './../../../../core/auth/auth.sandbox';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonSandbox } from '../../../../core/common/common.sandbox';
import { environment } from '../../../../../environments/environment';
import { ConfigService } from '../../../../core/services/config.service';
import { ChatDialog } from '../chat-popup/chat-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from '../../../../core/common/common.service';
import { AISuggestion } from '../ai-suggestion/ai-suggestion.component';
import { AIQusSuggestion } from '../ai-qus-suggestion/ai-qus-suggestion.component';
import { AISandbox } from '../../../../core/ai/ai.sandbox';
import { AISuggestionOperation } from '../ai-operation-suggestion/ai-suggestion-operation.component';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {

  public userDetails = JSON.parse(localStorage.getItem('vendorUserDetails'));
  public userProfileDetails: any;
  public userData: any = {};
  public imageUrl = environment.imageUrl;
  public communicationUrl = '';
  public dialogRef: any;
  public vendorService: any;
  public cancleService = 0;
  public vendorId = 0;

  public animationClass = '';

  public showHide = false;

  @Output() classChanged = new EventEmitter();


  token: any;
  constructor(public router: Router,
    public commonSandbox: CommonSandbox,
    public commonService: CommonService,
    public translateService: TranslateService,
    public aiSandbox: AISandbox,
    public dialog: MatDialog,
    public configService: ConfigService,
    public sandbox: AuthSandbox) { }

  ngOnInit() {

    this.getVendorProfile();
    this.getSettings();

    this.aiSandbox.showAnimation$.subscribe((response: string) => {
      this.animationClass = response;
    })

    this.commonSandbox.getProfile$.subscribe(profile => {
      if (profile) {
        this.userData = profile;
        const token = localStorage.getItem('vendorToken');
        this.communicationUrl = this.configService.getStoreUrl() + '/auth/auto-login?token=' + encodeURIComponent(token) + `&url=/socialhub-user`;;
        this.userProfileDetails = profile.customerDetail;
        this.vendorId = profile.customerId;
        this.getVendorService(profile.customerId);
      }
    });
  }


  getVendorService(vendorId) {
    const params: any = {};

    params.vendorId = vendorId;
    this.commonService.getVendorService(params).toPromise().then((response) => {
      if (response.data) {
        this.vendorService = response.data;
        this.sandbox.setSubscription(this.vendorService);
        this.cancleService = this.vendorService.status;
        if (this.userProfileDetails.onboardingCompleted === 0) {
          this.router.navigate(['/settings/initial-setup']);
        }
      } else {
        this.router.navigate(['auth/subscription-update']);
      }
    })
  }

  updateVendorSubscription() {
    this.router.navigate(['auth/subscription-update']);
  }

  onClassChange(data) {
    this.classChanged.emit(data);
  }

  opneAIQusSuggestionModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxWidth = '700px';
    dialogConfig.panelClass = 'DarkBackground';
    dialogConfig.width = '100%';
    this.dialogRef = this.dialog.open(AIQusSuggestion, dialogConfig);

    this.dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onChange = (value) => {
    // this.translateService.setDefaultLang(value);
    this.translateService.use(value);
    //  this.translateService.reloadLang(value);
  }

  opneAISuggestionModal() {

    this.aiSandbox.setMasterClose(false);
    this.aiSandbox.toggleAI();
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.height = 'auto';
    // dialogConfig.maxWidth = '800px';
    // dialogConfig.panelClass = 'DarkBackground';
    // dialogConfig.width = '100%';
    // this.dialogRef = this.dialog.open(AISuggestion, dialogConfig);

    // this.dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });

  }


  opneAIOperationModal() {

    this.aiSandbox.setMasterClose(false);
    this.aiSandbox.toggleAI();

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.height = 'auto';
    // dialogConfig.maxWidth = '800px';
    // dialogConfig.panelClass = 'DarkBackground';
    // dialogConfig.width = '100%';
    // this.dialogRef = this.dialog.open(AISuggestionOperation, dialogConfig);

    // this.dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });

  }


  openChat(id) {
    if (this.userData) {
      this.dialogRef = this.dialog.open(ChatDialog, {
        data: {
          title: "Chat",
          id: id
        }
      });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  toggleStatus() {
    this.commonService.setChatStatus({}).toPromise().then((response) => {
      if (this.userProfileDetails.chatStatus === 1) {
        this.userProfileDetails.chatStatus = 0;
      } else {
        this.userProfileDetails.chatStatus = 1;
      }
    })
  }

  logout() {
    this.sandbox.doLogout({});
    this.commonSandbox.clearProfile();
    localStorage.removeItem('vendorUserDetails');
    localStorage.removeItem('vendorUser');
    localStorage.removeItem('vendor-settings');
    localStorage.removeItem('vendorToken');
    this.router.navigate(['/auth/login']);
  }

  getVendorProfile() {
    this.commonSandbox.doGetProfile();
  }



  getSettings() {
    this.commonSandbox.doSettings();
  }
}
