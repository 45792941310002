
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Api } from '.././providers/api/api';
// model
import { ProductListModel } from './product-model/Product-list.model';
import { ProductDeleteModel } from './product-model/product-delete.model';
import { DetailModel } from './product-model/detail.model';
import { ProductBulkDeleteModel } from './product-model/product-bulk-delete.model';
import { scan } from 'rxjs/operators';

export interface Upload {
  progress: number
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE',
  data?: any
}

const initialState: Upload = { state: 'PENDING', progress: 0 }

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  )
}

const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
  if (isHttpProgressEvent(event)) {
    return {
      progress: event.total
        ? Math.round((100 * event.loaded) / event.total)
        : upload.progress,
      state: 'IN_PROGRESS',
    }
  }
  if (isHttpResponse(event)) {
    return {
      progress: 100,
      state: 'DONE',
      data: event.body
    }
  }
  return upload
}



@Injectable()
export class ProductService extends Api {
  // for get method
  public params: any = {};
  // url
  private basUrl = this.getBaseUrl();


  // add product
  productAdd(param) {
    console.log(param)
    return this.http.post(this.basUrl + '/vendor-product/create-vendor-product', param);
  }


  // add product
  freeServiceEnable(param) {
    console.log(param)
    return this.http.post(this.basUrl + '/vendor-product/create-free-booking', param);
  }

  //getfreeService
  getfreeService(param) {
    return this.http.post(this.basUrl + '/vendor-product/get-free-service', param);
  }

  // getBarcodeImage
  getBarcodeImage(param) {
    return this.http.post(this.basUrl + '/vendor-product/create-product-barcode', param);
  }

  abc(param) {
    console.log("in token ");
    return this.http.post(this.basUrl + '/vendor-order/connection-token', param);
  }



  /**
 * Handles 'productDetail' function. Calls get method with specific api address
 * along its param.
 *
 * @param param from DetailModel
 */
  productDetail(param: DetailModel) {
    return this.http.get(this.basUrl + '/vendor-product/vendor-product-detail/' + param.Id);
  }
  /**
   * Handles 'productDetail' function. Calls post method with specific api address
   * along its param.
   *
   * @param param from Model
   */
  productUpdate(param) {
    return this.http.put(
      this.basUrl + '/vendor-product/update-vendor-product/' + param.productId,
      param
    );
  }

  // delete product
  productDelete(params: ProductDeleteModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: false,
      body: { productId: params.productId }
    };

    return this.http.delete(
      this.basUrl + '/vendor-product/delete-product/' + params.productId,
      httpOptions
    );
  }

  public getBundleProducts(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-product/get-bundle-productList', {
      params: params
    });
  }

  public getProductText(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/ai-operations/generate-product', {
      params: params
    });
  }

  public insertProductInBackground(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-product/create-vendor-product-background', {
      params: params
    });
  }



  public getManufacturers(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/manufacturer/manufacturerlist', {
      params: params
    });
  }

  productDeleteByFlag(params: ProductDeleteModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: false,
      body: { productId: params.productId }
    };

    return this.http.delete(
      this.basUrl + '/vendor-product/delete-product-by-flag/' + params.productId,
      httpOptions
    );
  }


  // delete bulk product
  productBulkDelete(params: ProductBulkDeleteModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: false,
      body: { productId: params.productId }
    };

    return this.http.post(
      this.basUrl + '/vendor-product/delete-product', params,
      httpOptions
    );
  }


  public userGiftCardsListForVendor(param) {
    return this.http.get(this.basUrl + '/vendor-product/vendor-user-giftcards', {
      params: param
    });
  }

  public userGiftCardsCountForVendor(param) {
    return this.http.get(this.basUrl + '/vendor-product/vendor-user-giftcards-count', {
      params: param
    });
  }

  // list product
  public getServiceList(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-product/vendor-service-list', {
      params: reqOpts

    });
  }

  // list product
  public productList(params: ProductListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-product/vendor-product-list', {
      params: reqOpts

    });
  }

  // list product
  public productListForResend(params: ProductListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-product/vendor-product-list-resend', {
      params: reqOpts

    });
  }




  // list product
  public manufacturProductList(params: ProductListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-product/vendor-manufactur-product-list', {
      params: reqOpts

    });
  }


  // stock status list
  public stockStatusList(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/stock-status/stock-status-list', {
      params: reqOpts
    });
  }
  // product status change service calling

  public changeGiftCardsStatus(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-product/change-giftcard-product-status', params);
  }

  public cloneManfacturedProduct(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/manufactured-product/clone', params);
  }


  public productStatus(params: any): Observable<any> {
    return this.http.put(this.basUrl + '/vendor-product/add-vendor-product-status/' + params.productId, params);

  }
  public categoryList(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;

    return this.http.get(this.basUrl + '/vendor/vendor-category-list', { params: reqOpts });
  }

  public updateCategory(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.put(this.basUrl + '/vendor-product/update-vendor-category', reqOpts);
  }



  public getParentCategories(params: any): Observable<any> {
    console.log("parnet category");
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-product/parent-category', { params: reqOpts });
  }


  getManufaturedProductDetail(param) {
    return this.http.get(this.basUrl + '/manufactured-product/detail/' + param.id);
  }


  public getSubCategories(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-product/sub-category/' + params.categoryId, { params: reqOpts });
  }

  public getCategoryProducts(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-product/product-by-category', { params: reqOpts });
  }


  public ratingReviewList(params): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/product/product-rating-list-vendor', {
      params: reqOpts
    });
  }


  public changeStatus(params): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.put(this.basUrl + '/product/Product-rating-status-vendor/' + params.id, params);
  }

  public ratingDelete(params): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.post(this.basUrl + '/product/Product-rating-delete-vendor', params);
  }

  /**
   * Handles 'productCount' function. Calls get method with specific api address
   * along its param.
   *
   * @param params from RatingReviewListModel
   */

  public productCount(filterParam: any): Observable<any> {
    const reqOpts: any = {};
    const params = Object.getOwnPropertyNames(filterParam).reduce(
      (p, key) => p.set(key, filterParam[key]),
      new HttpParams()
    );
    reqOpts.params = params;
    return this.http.get(this.basUrl + '/vendor-product/vendor-product-list', reqOpts);
  }

  /**
   * Handles 'ProductExcel' function. Calls put method with specific api address
   * along its param.
   *
   * @param params from model
   */
  public productExcel(params): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/admin-vendor-product/vendor-product-excel-list', reqOpts);
  }


  // add product
  uploadContent(data, params) {
    return this.http.post(this.basUrl + '/media/upload-content', data, { params: params, reportProgress: true, observe: 'events' }).pipe(scan(calculateState, initialState));
  }



  uploadEditorContent(data, params) {
    return this.http.post(this.basUrl + '/media/upload-editor-assets', data, { params: params, reportProgress: true, observe: 'events' }).pipe(scan(calculateState, initialState));
  }

  saveImageFromUrl(data) {
    return this.http.post(this.basUrl + '/vendor-homepage-template/save-image-from-url', {}, { params: data });
  }


  /**
   * Handles 'ProductExcel' function. Calls put method with specific api address
   * along its param.
   *
   * @param params from model
   */
  public productAllExcel(params): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'blob';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/admin-vendor-product/bulk-vendor-product-excel-list', reqOpts);
  }

  // get manufacturer list

  public manufacturerList(params): Observable<any> {
    return this.http.get(this.basUrl + '/manufacturer/manufacturerlist', { params: params });
  }


  // change quotation status
  public changeQuotationStatus(params): Observable<any> {
    return this.http.put(this.basUrl + '/vendor-product/update-quotation-available/' + params.productId, params);
  }

  variantList(params): Observable<any> {
    return this.http.get(this.basUrl + '/varients/varientslist', { params: params });
  }

  public taxList(params): Observable<any> {
    return this.http.get(this.basUrl + '/tax/tax-list', { params: params });
  }
  deleteProbabilityOption(params): Observable<any> {
    return this.http.delete(this.basUrl + '/vendor-product/delete-product-varient-option/' + params.id);
  }

  deleteGiftCard(params): Observable<any> {
    console.log("parmas of delete gift card");
    console.log(params.param.cardId);
    return this.http.delete(this.basUrl + '/vendor-product/delete-gift-card/' + params.param.cardId);
  }
  deleteAllGiftCard(params): Observable<any> {
    console.log("parmas of delete gift card");
    console.log(params);
    return this.http.delete(this.basUrl + '/vendor-product/delete-all-gift-card/' + params.params.productId);
  }




  // Question Add
  QuestionAdd(param) {
    return this.http.post(this.basUrl + '/vendor-product-question/add-question', param);
  }

  // Question List
  questionList(params) {
    return this.http.get(this.basUrl + '/vendor-product-question/question-list', { params: params });
  }

  // Question Delete
  questionDelete(params) {
    return this.http.delete(this.basUrl + '/vendor-product-question/delete-question/' + params.questionId);
  }

  // Question Status
  questionStatus(params) {
    return this.http.put(this.basUrl + '/vendor-product-question/update-question-status/' + params.questionId, params);
  }

  // Answer Add
  answerAdd(param) {
    return this.http.post(this.basUrl + '/vendor-product-answer/add-answer', param);
  }

  // addManufacturedProduct
  addIngredients(param) {
    return this.http.post(this.basUrl + '/manufactured-product/add-ingredients', param);
  }

  // addManufacturedProduct
  updateIngredients(param) {
    return this.http.post(this.basUrl + '/manufactured-product/update-ingredients', param);
  }


  // addManufacturedProduct
  addIngredientProduct(param) {
    return this.http.post(this.basUrl + '/manufactured-product/create-ingredient', param);
  }

  // addManufacturedProduct
  editIngredientProduct(param) {
    return this.http.post(this.basUrl + '/manufactured-product/update-inventory', param);
  }

  // addManufacturedProduct
  addManufacturedProduct(param) {
    return this.http.post(this.basUrl + '/manufactured-product/create', param);
  }

  // addManufacturedProduct
  updateManufacturedProduct(param) {
    return this.http.post(this.basUrl + '/manufactured-product/update', param);
  }


  getManufacturedProduct(param) {
    return this.http.get(this.basUrl + '/manufactured-product/view/' + param.id);
  }


  getIngredentDetail(param) {
    return this.http.get(this.basUrl + '/manufactured-product/ingredent-detail/' + param.ingredientId);
  }

  getIngredentInventoryDetail(param) {
    return this.http.get(this.basUrl + '/manufactured-product/ingredent-inventory-detail/' + param.ingredientId);
  }

  getInventoryDetail(param) {
    return this.http.get(this.basUrl + '/manufactured-product/inventory-detail/' + param.ingredientId);
  }


  skuAlredyExist(param) {
    return this.http.get(this.basUrl + '/manufactured-product/sku-alredy-exist/' + param.sku);
  }

  getManufacturedProductByProductId(param) {
    return this.http.get(this.basUrl + '/manufactured-product/getByProductId/' + param.id);
  }

  // addManufacturedProduct
  listManufacturedProduct(param) {
    return this.http.get(this.basUrl + '/manufactured-product/list', {
      params: param
    });
  }

  // addManufacturedProduct
  listIngredientsInventory(param) {
    return this.http.get(this.basUrl + '/manufactured-product/inventory', {
      params: param
    });
  }

  // addManufacturedProduct
  ingredientsList(param) {
    return this.http.get(this.basUrl + '/manufactured-product/ingredients', {
      params: param
    });
  }

  // batch inventory
  batchInventoryList(param) {
    return this.http.get(this.basUrl + '/manufactured-batch/batch-inventory', {
      params: param
    });
  }

  // batch inventory
  getBatch(param) {
    return this.http.get(this.basUrl + '/manufactured-batch/batch-info', {
      params: param
    });
  }

  deleteManfacturedProduct(param) {
    return this.http.post(this.basUrl + '/manufactured-product/delete', param);
  }

  deleteIngredientInverntory(param) {
    return this.http.post(this.basUrl + '/manufactured-product/delete-inverntory', param);
  }

  deleteIngredient(param) {
    return this.http.post(this.basUrl + '/manufactured-product/delete-ingredient', param);
  }

  addBatch(param) {
    return this.http.post(this.basUrl + '/manufactured-batch/create', param);
  }

  updateBatch(param) {
    return this.http.post(this.basUrl + '/manufactured-batch/update', param);
  }


  deleteBatch(param) {
    return this.http.post(this.basUrl + '/manufactured-batch/delete', param);
  }

  listManufacturedBatch(param) {
    return this.http.get(this.basUrl + '/manufactured-batch/list', {
      params: param
    });
  }

  listManufacturedBatchLogs(param) {
    return this.http.get(this.basUrl + '/manufactured-batch/list-log', {
      params: param
    });
  }

  // Answer List
  answerList(params) {
    return this.http.get(this.basUrl + '/vendor-product-answer/answer-list', { params: params });
  }

  // Answer Delete
  answerDelete(params) {
    return this.http.delete(this.basUrl + '/vendor-product-answer/delete-answer/' + params.answerId);
  }

  // Answer Status
  answerStatus(params) {
    return this.http.put(this.basUrl + '/vendor-product-answer/update-answer-status/' + params.answerId, params);
  }

  //make default

  makeDefault(params) {
    return this.http.put(this.basUrl + '/vendor-product-answer/make-default-answer/' + params.answerId, params)
  }


  //--------For Upload Product--------------//
  public importProducts(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-product/upload-vendor-product', params);
  }

}
