import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { RecordAudioService } from "../../../../core/record-audio/record-audio.service";
import { CommonService } from "../../../../core/common/common.service";
import { AISandbox } from "../../../../core/ai/ai.sandbox";
import { SettingsService } from "../../../../core/settings/settings.service";
import { Router } from "@angular/router";

@Component({
    selector: 'ai-suggestion-operation-modal',
    templateUrl: './ai-suggestion-operation.component.html',
    styleUrls: ['./ai-suggestion-operation.component.scss']
})
export class AISuggestionOperation implements OnInit, AfterViewInit {

    public audioElement: any;
    public audioElementSource: any;

    public speaking = false;
    public searching = false;

    public suggestedText = ``;
    public text = '';
    public voiceText = '';
    public isTextSearch = false;

    public loading = false;
    constructor(
        public recorder: RecordAudioService,
        public aiSandbox: AISandbox,
        public commonService: CommonService,
        public settingService: SettingsService,
        public router: Router,
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {


    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        // this.audioElement = document.getElementsByClassName('audio-element')[0];
    }

    getSuggestions() {
        if (!this.text) {
            return;
        }
        this.isTextSearch = true;
        this.searching = true;
        this.suggestedText = '';
        this.commonService.getRedirectUrl({
            prompt: this.text
        }).toPromise().then((response) => {

            console.log(response);
            this.searching = false;
            this.voiceText = response.voiceText;
            this.suggestedText = response.data;
            this.isTextSearch = false;
            if (this.suggestedText) {
                this.router.navigate([this.suggestedText]);
                this.close();
            } else {
                this.suggestedText = 'Could not find any action. Please try with a different phrase.'
            }
        }).catch(err => {
            this.searching = false;
            console.log(" **** err ", err);
        })
    }

    start() {
        console.log(" *** start ");
        this.recorder.start()
            .then(() => { //on success
                this.speaking = true;
                this.suggestedText = '';
                console.log("Recording Audio...")
            })
            .catch(error => { //on error
                this.speaking = false;
                //No Browser Support Error
                if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
                    console.log("To record audio, use browsers like Chrome and Firefox.");
                    //Error handling structure
                    switch (error.name) {
                        case 'AbortError': //error from navigator.mediaDevices.getUserMedia
                            console.log("An AbortError has occured.");
                            break;
                        case 'NotAllowedError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A NotAllowedError has occured. User might have denied permission.");
                            break;
                        case 'NotFoundError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A NotFoundError has occured.");
                            break;
                        case 'NotReadableError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A NotReadableError has occured.");
                            break;
                        case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
                            console.log("A SecurityError has occured.");
                            break;
                        case 'TypeError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A TypeError has occured.");
                            break;
                        case 'InvalidStateError': //error from the MediaRecorder.start
                            console.log("An InvalidStateError has occured.");
                            break;
                        case 'UnknownError': //error from the MediaRecorder.start
                            console.log("An UnknownError has occured.");
                            break;
                        default:
                            console.log("An error occured with the error name " + error.name);
                    };
                }
            });
    }

    copyText(inputElement) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    }

    fillText() {
        console.log(" ***  this.suggestedText ", this.voiceText);
        this.aiSandbox.textSuccess({ text: this.suggestedText, voiceText: this.voiceText, type: 1 });
        this.send();
    }

    append() {
        console.log(" ***  this.suggestedText ", this.voiceText);
        this.aiSandbox.textSuccess({ text: this.suggestedText, voiceText: this.voiceText, append: true, type: 2 });
        this.send();
    }

    stop() {
        console.log("** stopeed ")
        this.speaking = false;
        this.recorder.stop()
            .then(([buffer, audioAsblob]) => { //stopping makes promise resolves to the blob file of the recorded audio
                console.log("stopped with audio Blob:", audioAsblob);
                this.isTextSearch = true;
                this.playAudio(audioAsblob);
            })
            .catch(error => {
                //Error handling structure
                switch (error.name) {
                    case 'InvalidStateError': //error from the MediaRecorder.stop
                        console.log("An InvalidStateError has occured.");
                        break;
                    default:
                        console.log("An error occured with the error name " + error.name);
                };

            });
    }

    playAudio(recorderAudioAsBlob) {

        //read content of files (Blobs) asynchronously
        let reader = new FileReader();
        console.log(" ***  recorderAudioAsBlob ", recorderAudioAsBlob);
        //once content has been read
        reader.onload = (e) => {
            //store the base64 URL that represents the URL of the recording audio
            let base64URL = e.target.result;


            this.searching = true;
            this.suggestedText = '';
            this.commonService.getSuggestion({
                audio: base64URL
            }).toPromise().then((response) => {
                this.searching = false;
                this.suggestedText = response.data.message.content;
                this.voiceText = response.voiceText;
                console.log(" *** response ", response);
            }).catch(err => {
                this.searching = false;
                console.log(" **** err ", err);
            })
            //If this is the first audio playing, create a source element
            //as pre populating the HTML with a source of empty src causes error
            // if (!this.audioElementSource) //if its not defined create it (happens first time only)
            //     this.createSourceForAudioElement();

            //set the audio element's source using the base64 URL
            //  this.audioElementSource.src = base64URL;

            //set the type of the audio element based on the recorded audio's Blob type
            // let BlobType = recorderAudioAsBlob.type.includes(";") ?
            //     recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(';')) : recorderAudioAsBlob.type;
            // this.audioElementSource.type = BlobType

            //call the load method as it is used to update the audio element after changing the source or other settings
            // this.audioElement.load();

            //play the audio after successfully setting new src and type that corresponds to the recorded audio
            // console.log("Playing audio...");
            // this.audioElement.play();


        };

        //read content and convert it to a URL (base64)
        reader.readAsDataURL(recorderAudioAsBlob);
    }

    createSourceForAudioElement() {
        let sourceElement = document.createElement("source");
        this.audioElement.appendChild(sourceElement);

        this.audioElementSource = sourceElement;
    }

    send() {
        this.dialogRef.close(true);
    }
    close() {
        this.dialogRef.close(false);
    }
}