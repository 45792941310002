// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'https://app.premierstreets.com:8000/api',
  imageUrl: 'https://images.premierstreets.com:3000/resize',
  uploadUrl: 'https://app.premierstreets.com:8000/uploads',
  socketUrl: 'https://io.premierstreets.com',
   imageBaseUrl: "https://app.premierstreets.com:8000",
  bookkeepingUrl: "https://bookkeeping.premierstreets.com/",
  // baseUrl: 'https://ps.miraclechd.co/backend/api',
  // imageUrl: 'https://ps.miraclechd.co/backend/api/media/image-resize',
  // uploadUrl: 'https://ps.miraclechd.co/backend/uploads',
  storeUrl: 'https://premierstreets.com',
 //storeUrl: 'http://localhost:4300',
  // socketUrl: 'https://localhost:8002',
  // baseUrl: 'https://localhost:8002/api',
  // imageUrl: 'https://localhost:8002/api/media/image-resize',
  //   uploadUrl: 'https://localhost:80002/uploads',
  // productUrl: '<Your store base url>',
  // pluginUrl: '<Your API url>',
  vendorServiceId: 7,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.