import { Component, Inject, OnInit, ViewChild, NgZone } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxPaypalComponent, PayPalScriptService } from 'ngx-paypal';
import { PaymentService } from "../../../../../../core/payment/payment.service";

@Component({
    selector: 'vendor-paypal-payment-modal',
    templateUrl: './paypal-modal.component.html',
    styleUrls: ['./paypal-modal.component.scss']
})
export class PaypalModalComponent implements OnInit {

    public vendors = [];
    public imagePath: any;
    public appliedCoupons = [];
    public payPalConfig: any;
    public authorizenetConfig: any;
    public loading = false;
    public stripeTokenVal = '';
    paymentHandler: any = null;
    public paymentSettingsResponse: any;

    @ViewChild('payPalElem1') paypalComponent1?: NgxPaypalComponent;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public zone: NgZone,
        public paymentService: PaymentService,
        public payPalScriptService: PayPalScriptService
    ) {


    }

    ngOnInit() {
        // this.invokeStripe();
        this.paymentService.getStripePaymentGateway({ 'key': 'paypal' }).toPromise().then(response => {
            this.loading = false;
            this.paymentSettingsResponse = response;
        })

        if (this.data.clientId) {
            this.payPalConfig = {
                currency: 'USD',
                clientId: this.data.clientId,
                fundingSource: 'card',
                createOrderOnServer: (data) => {
                    this.loading = true;
                    return this.paymentService.createOrder(this.data.params).toPromise().then(response => {
                        this.loading = false;
                        return response.data.result.id;
                    })
                },
                advanced: {
                    commit: 'true',
                    extraQueryParams: [{ name: "enable-funding", value: "venmo" }]
                },

                onApprove: (data, actions) => {

                },

                onClientAuthorization: (data) => {
                    this.dialogRef.close({ paymentType: 'paypal', id: data.id });
                },
                onCancel: (data, actions) => {
                },
                onError: err => {
                    console.log('OnError', err);
                },
            }
        }

        if (this.data.apiLoginID) {
            this.authorizenetConfig = {
                billingAddressOptions: '{"show":true, "required":false}',
                urlAction: "https://api.yourdomain.com/authorizepay/callback",
                apiLoginID: this.data.apiLoginID,
                clientKey: this.data.clientKey,
                acceptUIFormBtnTxt: 'Pay Now',
                acceptUIFormHeaderTxt: 'Pay',
                live: true
            }
        }

    }



    // invokeStripe() {
    //     if (!window.document.getElementById('stripe-script')) {
    //         const script = window.document.createElement("script");
    //         script.id = "stripe-script";
    //         script.type = "text/javascript";
    //         script.src = "https://checkout.stripe.com/checkout.js";
    //         script.onload = () => {
    //             this.paymentHandler = (<any>window).StripeCheckout.configure({
    //                 key: 'pk_test_51GtDjDF6tXeF8bczXE30MpDF6V7UGgaAnaefTtFkJJmefrfOmUzWCCCFyYK4kKY0DlLuRLRdsRpQG8X8nd358Kl300RWziD7c3',
    //                 locale: 'auto',
    //                 token: function (stripeToken: any) {
    //                     console.log(stripeToken)
    //                     alert('Payment has been successfull!');
    //                 }
    //             });
    //         }
    //         window.document.body.appendChild(script);
    //     }
    // }

    initializePayment() {
        console.log("this.data.selected user");
        console.log(this.data.selectedCustomer);


        const paymentHandler = (<any>window).StripeCheckout.configure({
            key: this.data.stripeApiKey,
            locale: 'auto',
            token: (stripeToken: any) => {
                console.log({ stripeToken });
                // alert('Stripe token generated!');
                console.log("token of stripe in handler");
                console.log(stripeToken.id);
                this.stripeTokenVal = stripeToken;

                this.loading = true;
                return this.paymentService.createOrder(this.data.params).toPromise().then(response => {
                    this.loading = false;
                    console.log("response of create order");
                    console.log(response);
                    // return response.data.result.id;
                    //  response.data.stripeTokenVal = this.stripeTokenVal;

                    this.dialogRef.close({ paymentType: 'stripe', id: response.data.result.id, stripeTokenVal: this.stripeTokenVal });

                    // this.dialogRef.close(response);
                })

                //this.placeOrder(this.cartItems, 'stripe');
            }
        });

        paymentHandler.open({
            //  name: this.data.selectedCustomer.firstName + ' ' + this.data.selectedCustomer.lastName,
            description: 'Pay',
            amount: this.data.amount * 100,
            email: this.data.selectedCustomer.email
        });


    }
    public authorizeHandler(response) {

        console.log(" ***** response ***** ", response);
        if (response.messages.resultCode === "Error") {
            var i = 0;
            while (i < response.messages.message.length) {
                console.log(
                    response.messages.message[i].code + ": " +
                    response.messages.message[i].text
                );
                i = i + 1;
            };
        }
        else {
            this.zone.run(() => {
                let data = {
                    paymentType: "authorize",
                    dataValue: response.opaqueData.dataValue,
                    dataDescriptor: response.opaqueData.dataDescriptor
                }
                this.dialogRef.close(data);
            });
        }
    }


    close() {
        this.dialogRef.close();
    }
}