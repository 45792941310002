import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthApiService } from './core/auth/auth.service';
import { CommonSandbox } from './core/common/common.sandbox';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'spurtcommerce-multi-vendor';
	public subscriptions = [];
	public constructor(private activeRoute: ActivatedRoute,
		private commonSandbox: CommonSandbox,
		private authService: AuthApiService, private router: Router) {

	}

	public ngOnInit() {
		let params: any = this.activeRoute.snapshot.queryParams;
		this.subscriptions.push(this.activeRoute.queryParams.subscribe(params => {
			if (params.token) {
				localStorage.setItem('vendorToken', params.token);
				this.authService.getLoginData({}).toPromise().then((response) => {
					localStorage.setItem('vendorUserDetails', JSON.stringify(response.data.user));
					this.router.navigate(['dashboard']);
				});
			}
		}));
		// this.subscriptions.push(this.commonSandbox.getProfile$.subscribe(data => {
		// 	if (data && Object.keys(data).length) {
		// 		if ((data.customerDetail.onboardingCompleted === 0) && (this.router.url !== '/settings/initial-setup')) {
		// 			this.router.navigate(['/settings/initial-setup']);
		// 		}
		// 	}
		// }))
	}

	public ngOnDestroy() {
		this.subscriptions.forEach(subscripton => {
			subscripton.unsubscribe();
		})
	}
}
