import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl
} from '@angular/forms';
import { ConfigService } from '../../../../../../core/services/config.service';
import { SettingsSandbox } from '../../../../../../core/settings/settings.sandbox';
import { ActivatedRoute, Router } from '@angular/router';
import { UpsShippingModel } from '../../../../../../core/settings/models/ups-shipping.model';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../../../../../core/settings/settings.service';
import { CommonSandbox } from '../../../../../../core/common/common.sandbox';

@Component({
    selector: 'app-ups-shipping-method',
    templateUrl: './ups-shipping-method.component.html',
    styleUrls: ['./ups-shipping-method.component.scss']
})
export class UpsShippingMethodComponent implements OnInit, OnDestroy {

    public shippingMethodForm: FormGroup;
    public subscriptions: Array<any>;
    public upsMethods = [];
    public userData: any = {};
    public showIncomplete = true;
    public routeUrl = '';
    @Output() dataSubmitted = new EventEmitter();
    constructor(
        public modal: NgbModal,
        public router: Router,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder,
        public configService: ConfigService,
        public settingSandbox: SettingsSandbox,
        public toastr: ToastrService,
        public settingService: SettingsService,
        public commonSandbox: CommonSandbox,

    ) { }

    ngOnInit() {
        this.subscriptions = [];
        this.initiateShippingMethodForm();

        this.routeUrl = this.router.url;
        this.subscriptions.push(
            this.commonSandbox.getProfile$.subscribe(data => {
                if (data && Object.keys(data).length) {
                    this.userData = data;
                }
            })
            
        );

        this.settingSandbox.getShippingMethod({ key: "ups-shipping" });

        this.subscriptions.push(this.settingSandbox.shippingMethod$.subscribe((data) => {
            if (Object.keys(data).length > 0) {
                this.setShippingMethodFormData(new UpsShippingModel(data));
            } else {
                this.setShippingMethodFormData(new UpsShippingModel(data));
            }
        }));

        this.commonSandbox.getCountryList({});

        this.subscriptions.push(this.settingService.getUpsMethods({})?.subscribe((response: any) => {
            this.upsMethods = response.data;
        }))

        $('button.save').on('click', function () {
            $('html, body').animate({
              'scrollTop': $('.bd-tab-content').position()['top']
            });
          });
        
    }

    initiateShippingMethodForm() {
        this.shippingMethodForm = this.formBuilder.group({
            title: ['', Validators.required],
            methodName: ['', Validators.required],
            key: ['ups-shipping', Validators.required],
            status: [0, Validators.required],
            mode: [0, Validators.required],
            accessLicenseNumber: ['', Validators.required],
            upsUserId: ['', Validators.required],
            upsPassword: ['', Validators.required],
            handlingFee: ['', Validators.required],
            allowedMethods: ['', Validators.required],
        //    freeMethod: ['', Validators.required],
            // enableFreeShipping: [0, Validators.required],
            // freeShippingThreshold: [0, Validators.required],
            shipToCountries: ['', Validators.required],
            //   allowedCountries: ['', Validators.required]
        });

    }

    setShippingMethodFormData(data) {
        this.shippingMethodForm.controls['key'].setValue(data.key);
        this.shippingMethodForm.controls['title'].setValue(data.title);
        this.shippingMethodForm.controls['status'].setValue(data.status);
        this.shippingMethodForm.controls['methodName'].setValue(data.methodName);
        this.shippingMethodForm.controls['mode'].setValue(data.settings.mode);
        this.shippingMethodForm.controls['accessLicenseNumber'].setValue(data.settings.accessLicenseNumber);
        this.shippingMethodForm.controls['upsUserId'].setValue(data.settings.upsUserId);
        this.shippingMethodForm.controls['upsPassword'].setValue(data.settings.upsPassword);
        this.shippingMethodForm.controls['handlingFee'].setValue(data.settings.handlingFee);
        this.shippingMethodForm.controls['allowedMethods'].setValue(data.settings.allowedMethods);
        // this.shippingMethodForm.controls['freeMethod'].setValue(data.settings.freeMethod);
        // this.shippingMethodForm.controls['enableFreeShipping'].setValue(data.settings.enableFreeShipping);
        // this.shippingMethodForm.controls['freeShippingThreshold'].setValue(data.settings.freeShippingThreshold);
        this.shippingMethodForm.controls['shipToCountries'].setValue(data.settings.shipToCountries);
        // this.shippingMethodForm.controls['allowedCountries'].setValue(data.settings.allowedCountries);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }


    submit() {
        if (!this.shippingMethodForm.valid) {
            console.log(" **** this.shippingMethodForm ****** ", this.shippingMethodForm);
            this.validateAllFormFields(this.shippingMethodForm);
            return;
        }

        const params: any = { settings: {} };

        params.title = this.shippingMethodForm.controls['title'].value;
        params.key = this.shippingMethodForm.controls['key'].value;
        params.status = this.shippingMethodForm.controls['status'].value;
        params.methodName = this.shippingMethodForm.controls['methodName'].value;
        params.settings.mode = this.shippingMethodForm.controls['mode'].value;
        params.settings.accessLicenseNumber = this.shippingMethodForm.controls['accessLicenseNumber'].value;
        params.settings.upsUserId = this.shippingMethodForm.controls['upsUserId'].value;
        params.settings.upsPassword = this.shippingMethodForm.controls['upsPassword'].value;
        params.settings.shipperNumber = this.shippingMethodForm.controls['accessLicenseNumber'].value;
        params.settings.handlingFee = this.shippingMethodForm.controls['handlingFee'].value;
        params.settings.allowedMethods = this.shippingMethodForm.controls['allowedMethods'].value;
        // params.settings.freeMethod = this.shippingMethodForm.controls['freeMethod'].value;
        // params.settings.enableFreeShipping = this.shippingMethodForm.controls['enableFreeShipping'].value;
        // params.settings.freeShippingThreshold = this.shippingMethodForm.controls['freeShippingThreshold'].value;
        params.settings.shipToCountries = this.shippingMethodForm.controls['shipToCountries'].value;
        //    params.settings.allowedCountries = this.shippingMethodForm.controls['allowedCountries'].value;

        this.settingSandbox.updateShippingMethod(new UpsShippingModel(params));

        this.subscriptions.push(this.settingSandbox.updateShippingMethodLoaded$.subscribe(data => {
            if (data === true) {
                this.settingSandbox.getShippingMethod({ key: "ups-shipping" });
                this.toastr.success('Shipping method updated successfully', 'Success');
                this.commonSandbox.doGetProfile();
                this.dataSubmitted.emit('payment');
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(each => each.unsubscribe());
    }
}
