
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Api } from '../providers/api/api';
// model
import { OrderListModel } from './order-model/Order-list.model';

@Injectable()
export class OrderService extends Api {
  // for get method
  public params: any = {};
  // url
  private basUrl = this.getBaseUrl();


  // add order
  getOrderDetail(param) {
    return this.http.get(this.basUrl + '/vendor-order/order-detail/' + param);
  }

  getOrderId(param) {
    return this.http.get(this.basUrl + '/vendor-order/get-order-id/' + param);
  }

  fraudOrderes(param) {
    return this.http.get(this.basUrl + '/vendor-order/get-fraudlent-orders');
  }

  orderChangeHistory(param) {
    return this.http.get(this.basUrl + '/vendor-order/change-history', {
      params: param
    });
  }

  todayOrders(param) {
    return this.http.post(this.basUrl + '/vendor-order/today-orders', param);
  }

  getResendOrderRequestList(param) {
    console.log("in request");
    console.log(this.basUrl + '/orders/get-resend-order-requests');
    return this.http.post(this.basUrl + '/orders/get-resend-order-requests', param);
  }

  validateAddress(param) {
    return this.http.post(this.basUrl + '/vendor-order/validate-address', param);
  }

  notifyStockiest(param) {
    return this.http.post(this.basUrl + '/vendor-order/notify-stockiest', param);
  }

  notifyVendor(param) {
    return this.http.post(this.basUrl + '/vendor-order/notify-vendor', param);
  }

  addSupportNoteInOrder(param) {
    return this.http.post(this.basUrl + '/vendor-order/add-order-note', param);
  }


  addResendOrder(param) {
    return this.http.post(this.basUrl + '/vendor-order/resend-order', param);
  }

  addShopSupplier(param) {
    return this.http.post(this.basUrl + '/shop-supply/create', param);
  }

  updateShopSupplier(param) {
    return this.http.post(this.basUrl + '/shop-supply/update/' + param.id, param);
  }


  public approveResendOrderRequest(params) {
    return this.http.post(this.basUrl + '/orders/approve-order', params);
  }

  public recreateOrder(params) {
    return this.http.post(this.basUrl + '/orders/recreate-order', params);
  }

  sendLowStockEmail(param) {
    return this.http.post(this.basUrl + '/shop-supply/send-low-stock-email', param);
  }

  getSingleShopSupplier(param) {
    return this.http.get(this.basUrl + '/shop-supply/view', {
      params: param
    });
  }


  getShopSupplier(params) {
    return this.http.get(this.basUrl + '/shop-supply/list', { params: params });
  }

  getCheckins(params) {
    return this.http.get(this.basUrl + '/staff-checkin/list', { params: params });
  }

  getEmployeeCheckins(params) {
    return this.http.get(this.basUrl + '/staff-checkin/list-for-admin', { params: params });
  }

  addCheckin(params) {
    return this.http.post(this.basUrl + '/staff-checkin/create', params);
  }

  addQuote(params) {
    return this.http.post(this.basUrl + '/quote/create', params);
  }

  updateCheckin(params) {
    return this.http.post(this.basUrl + '/staff-checkin/update/' + params.id, params);
  }

  public exportCheckinReport(params: any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/staff-checkin/export', reqOpts);
  }


  deleteCheckin(params) {
    return this.http.post(this.basUrl + '/staff-checkin/delete', params);
  }

  // // add order
  // getShopSupplier(param) {
  //   return this.http.get(this.basUrl + '/shop-supply/list');
  // }
  // add order
  getCartDetail(param) {
    return this.http.get(this.basUrl + '/order/cart-detail', {
      params: {
        cartId: param
      }
    });
  }

  getReports(param) {
    return this.http.get(this.basUrl + '/vendor-order/get-reports', {
      params: param
    });
  }
  getSalesReports(params) {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return this.http.get(this.basUrl + '/vendor-order/bulk-sales-report-export', {
      params: params
    });
  }




  // add order
  getShiftReport(param) {
    return this.http.get(this.basUrl + '/vendor-order/shift-report', {
      params: param
    });
  }

  // archive order detail
  getArchiveOrderDetail(param) {
    return this.http.get(this.basUrl + '/vendor-order/order-detail/' + param);
  }
  // recent list order
  public recentOrderList(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/recent-order-list', {
      params: reqOpts
    });
  }

  public placeOrder(params) {
    return this.http.post(this.basUrl + '/vendor-order/customer-checkout', params);
  }

  public cartData(params) {
    return this.http.post(this.basUrl + '/order/add-cart-data', params);
  }



  public sendReciept(params) {
    return this.http.post(this.basUrl + '/vendor-order/send-reciept', params);
  }


  public startShift(params) {
    return this.http.post(this.basUrl + '/vendor-order/start-shift', params);
  }

  public endShift(params) {
    return this.http.post(this.basUrl + '/vendor-order/end-shift', params);
  }

  // archive order detail
  getShift(param) {
    return this.http.get(this.basUrl + '/vendor-order/get-shift', param);
  }

  public placeCreditOrder(params) {
    return this.http.post(this.basUrl + '/vendor-order/credit-checkout', params);
  }

  public ConnectStripeTerminalToken(params) {
    console.log("in connec t srtoep");
    return this.http.post(this.basUrl + '/vendor-order/connection-token', params);
  }


  public getUpsMethodList(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/admin-vendor-order/calculate-shipping-price', {
      params: reqOpts
    });
  }

  public calculateShippingPrice(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.post(this.basUrl + '/store-shipping-method/calculate-shipping-price', params);
  }


  public shppingMehtods(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/store-shipping-method/list', {
      params: reqOpts
    });
  }

  public shppingMehtodId(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/store-shipping-method/shipping-method', {
      params: reqOpts
    });
  }


  // all list order
  public allOrderList(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/order-list', {
      params: reqOpts
    });
  }

  // all list order
  public updateOrderAddress(params): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/update-address', params);
  }

  // all list order
  public amendOrder(params): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/amend-order', params);
  }

  public updateShippingStatus(params): Observable<any> {
    return this.http.post(this.basUrl + '/store-shipping-method/update/shipping/status', params);
  }

  public cancelOrder(params): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/cancel-order', params);
  }


  public refundOrder(params): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/refund-order', params);
  }


  public verifyOrder(params): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/verify-order', params);
  }
  // all list order
  public allOrderListMain(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/order-list-main', {
      params: reqOpts
    });
  }
  // all list order
  public allOrderListMainOrders(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/all-order-list-main', {
      params: reqOpts
    });
  }



  // archive list order
  public archiveOrderList(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/archive-order-list', {
      params: reqOpts
    });
  }

  // all orders
  public allOrders(params): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/all-orders', {
      params: reqOpts
    });
  }

  public allQuotes(params): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/quote/list', {
      params: reqOpts
    });
  }

  // all list order
  public orderCount(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/order-counts');
  }
  // all list order
  public deliveryPersonsList(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/delivery-person/delivery-person-list-delivery-allocation', {
      params: reqOpts
    });
  }
  // allocate delivery person list order
  public allocateDeliveryPersons(params): Observable<any> {
    return this.http.post(this.basUrl + '/delivery-person/add-delivery-allocation', params);
  }
  // all list based on status
  public allOrderListBasedOnStatus(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/vendor-orders-based-status-list', {
      params: reqOpts
    });
  }

  // make arhive
  public deleteOrder(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/delete-order', params);
  }


  public getQuoteDetail(params: any) {
    return this.http.get(this.basUrl + '/quote/view-for-vendor', {
      params
    });
  }

  public requestQuotePayment(params: any) {
    return this.http.post(this.basUrl + '/quote/request-payment', params);
  }

  public markDepositPaid(params: any) {
    return this.http.post(this.basUrl + '/quote/mark-deposit-paid', params);
  }

  public markQuoteCompleted(params: any) {
    return this.http.post(this.basUrl + '/quote/mark-completed', params);
  }

  public deleteQuote(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/quote/delete', params);
  }

  public deleteAllQuote(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/quote/delete-all', params);
  }
  // make arhive
  public deleteShopSupplier(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/shop-supply/delete', params);
  }

  // all list based on status
  public updateAllOrderListBasedOnStatus(params: any): Observable<any> {
    const param: any = {};
    param.subOrderStatusId = params.subOrderStatusId;
    return this.http.put(this.basUrl + '/vendor-order/update-order-status/' + params.vendorOrderId, param);
  }
  // log list order
  public orderLogList(params: OrderListModel): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/vendorOrderLoglist', {
      params: reqOpts
    });
  }
  // update status list order
  public orderStatusUpdate(params: any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.subOrderStatusId = Number(params.subOrderStatusId);
    reqOpts.code = params.code;
    reqOpts.label = params.label;
    reqOpts.products = params.products || [];
    return this.http.put(this.basUrl + '/vendor-order/update-order-status/' + params.vendorOrderId, reqOpts);
  }
  // make arhive
  public makeArchive(params: any): Observable<any> {
    const reqOpts: any = {};
    return this.http.post(this.basUrl + '/vendor-order/make-vendor-order-archive', params);
  }
  // update status list order
  public shippingInformationUpdate(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/update-shipping-information', params);
  }

  public orderStatusList(params: any): Observable<any> {
    let reqOpts: any = {};
    reqOpts = params;
    return this.http.get(this.basUrl + '/vendor-order/vendor-order-status-list', { params: reqOpts });
  }

  // export archive order
  public exportArchiveOrder(params: any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/vendor-order/archive-order-export', reqOpts);
  }


  // export all archive order

  public exportAllArchiveOrder(params: any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/vendor-order/bulk-archive-order-export', reqOpts);
  }

  // archive order list count

  public archiveOrderListCount(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-order/archive-order-list-count', params);
  }

  // cancel order list

  public cancelOrderList(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-order/cancel-order-list', { params: params });
  }

  // cancel order list count

  public cancelOrderListCount(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-order/cancel-order-list', { params: params });
  }

  // export cancel order list

  public exportCancelOrder(params: any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/vendor-order/vendor-order-cancel-excel-list', reqOpts);
  }

  // export all cancel order list

  public exportAllCancelOrder(params: any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/vendor-order/bulk-vendor-order-cancel-excel-list', reqOpts);
  }


  // change cancel order status

  public changeCancelOrderStatus(params: any): Observable<any> {
    return this.http.put(this.basUrl + '/vendor-order/update-vendor-order-cancel-request/' + params.orderProductId, params);
  }

  // Bulk cancel order status

  public bulkCancelOrderStatus(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-order/update-bulk-vendor-order-cancel-request', { params: params });
  }

  // quotation request list

  public quotationList(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-quotation/quotation-request-list', { params: params });
  }

  // quotation request list count

  public quotationListCount(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-quotation/quotation-request-list', { params: params });
  }

  // get order invoice list
  orderInvoiceList(param) {
    return this.http.get(this.basUrl + '/vendor-order/vendor-invoice-list', { params: param });
  }

  // get order invoice list count
  orderInvoiceListCount(param) {
    return this.http.get(this.basUrl + '/vendor-order/vendor-invoice-list', { params: param });
  }

  // Download Invoice
  downloadInvoice(params) {
    return this.http.get(this.basUrl + '/vendor-order/order-invoice-export-pdf', { params: params });
  }

  downloadLabel(params) {
    return this.http.get(this.basUrl + '/vendor-order/order-export-label', { params: params });
  }

  shipdownloadLabel(params) {
    return this.http.get(this.basUrl + '/vendor-order/ship-export-label', { params: params });
  }


  getShippedProducts(params) {
    return this.http.get(this.basUrl + '/vendor-order/get-shipped-products', { params: params });
  }


  downloadPacking(params) {
    return this.http.get(this.basUrl + '/vendor-order/order-export-packing', { params: params });
  }


  vendorShippingMehtods(param): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-order/vendor-shipping-methods', { params: param });
  }

  settlementList(param): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-order/sales-report-list', { params: param });
  }
  settlementListCount(param): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-order/sales-report-list', { params: param });
  }
  sendMail(param): Observable<any> {
    return this.http.post(this.basUrl + '/vendor-order/order-invoice-export-send-email-pdf', param);
  }
  public exportSalesReport(params: any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.basUrl + '/vendor-order/sales-report-export-list', reqOpts);
  }

  createAbondanMail(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/abondan-mail-setup/create', params);
  }

  getAbondanMail(params: any): Observable<any> {
    return this.http.get(this.basUrl+'/abondan-mail-setup/view-abondan-mail/'+params.orderId)
  }

  jobSetupAbondanMain(params: any): Observable<any> {
    return this.http.get(this.basUrl+'/abondan-mail-setup/job/'+params)
  }

  getAbondanMailDaily(params: any): Observable<any> {
    return this.http.get(this.basUrl+'/abondan-mail-setup/view-abondan-mail-daily')
  }

}
