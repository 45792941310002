import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ClipboardModule } from '@angular/cdk/clipboard';

// components
import { CommonHeaderComponent } from '../../default/shared/components/common-header/common-header.component';
import { FooterComponent } from '../../default/shared/components/footer/footer.component';
import { SidebarComponent } from '../../default/shared/components/sidebar/sidebar.component';
import { BreadcrumbsComponent } from '../../default/shared/components/breadcrumbs/breadcrumbs.component';
import { ImagemanagerpopupComponent } from './popup/ImageManagerPopup/imagemanagerpopup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductSuccessComponent } from './popup/product-success/product-success.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NodataComponent } from './components/nodata/nodata.component';
import { NumberAcceptModule } from './validation-directives/onlyNumber.module';
import { AppMaterial } from '../../app.material.module';
import { PagerComponent } from './components/pager/pager.component';
import { AddCouponComponent } from './popup/add-coupon/add-coupon.component';
import { AddDeliveryComponent } from './popup/add-delivery/add-delivery.component';
import { PipeModule } from './pipe/pipe.module';
import { UpcommingComponent } from './components/upcomming-page/upcomming-page.component';
import { DeletePopupComponent } from './popup/delete-popup/delete-popup.component';
import { CurrencyPipeModule } from './pipe/currency.module';
import { ConfirmationDialogComponent } from './popup/Confirmation-dialog/confirmation-dialog.component';
import { ConfigService } from '../../core/services/config.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarMenuModule } from 'angular-sidebar-menu';
import { SocialChatModule } from './components/social-chat/social-chat.module';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { SettingSidbarComponent } from '../pages/component/settings/components/sidebar/sidebar.component';
import { PopoverComponent } from './components/popover/popover.component';
import { AichatbotComponent } from './components/ai-chatbot/ai-chatbot.component';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../core/common/common.service';
import { SettingsService } from '../../core/settings/settings.service';
import { PaypalModalComponent } from '../pages/component/pos/component/paypal-modal/paypal-modal.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxAuthorizenetModule } from './ngx-authorizenet/src/public-api';
import { ThemeSettingComponent } from './components/theme-setting-popup/theme-setting-popup.component';
import { BusinessDetailsComponent } from '../pages/component/settings/components/business-details/business-details.component';
import { ShopDetailsComponent } from '../pages/component/settings/components/shop-details/shop-details.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FreeShippingMethodComponent } from '../pages/component/settings/components/free-shipping/free-shipping-method.component';
import { FlatRateShippingMethodComponent } from '../pages/component/settings/components/flat-rate-shipping/flat-rate-shipping.component';
import { FedexShippingMethodComponent } from '../pages/component/settings/components/fedex-shipping/fedex-shipping-method.component';
import { UpsShippingMethodComponent } from '../pages/component/settings/components/ups-shipping/ups-shipping-method.component';
import { PaymentSettingsComponent } from '../pages/component/payments/components/payment-settings/payment-settings.component';
import { PaymentSandbox } from '../../core/payment/payment.sandbox';
import { PaymentService } from '../../core/payment/payment.service';
import { AuthorizeSettingsComponent } from '../pages/component/payments/components/authorize-settings/authorize-settings.component';
import { AISuggestion } from './components/ai-suggestion/ai-suggestion.component';
import { AIQusSuggestion } from './components/ai-qus-suggestion/ai-qus-suggestion.component';
import { RecordAudioService } from '../../core/record-audio/record-audio.service';
import { AISandbox } from '../../core/ai/ai.sandbox';
import { AISuggestionOperation } from './components/ai-operation-suggestion/ai-suggestion-operation.component';
import { ProductService } from '../../core/product/product.service';
import { LoyaltyPromotionService } from '../../core/loyalty-promotion/loyalty-promotion.service';
import { SettingsSandbox } from '../../core/settings/settings.sandbox';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PromocodeService } from '../../core/promocode/promocode.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CustomerGroupService } from '../../../app/core/groups/group.service';
import { OrderService } from '../../../app/core/order/order.service';
import { OrderSandbox } from '../../../app/core/order/order.sandbox';
import { ProductSandbox } from '../../../app/core/product/product.sandbox';
import { BlogService } from '../../../app/core/blogs/blog.service';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SwiperModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NumberAcceptModule,
        AppMaterial,
        PipeModule, CurrencyPipeModule,
        SidebarMenuModule,
        MatSidenavModule,
        SocialChatModule,
        NgxPayPalModule,
        NgxAuthorizenetModule,
        EditorModule,
        ClipboardModule,
        NgMultiSelectDropDownModule,
        TranslateModule.forChild({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        CommonModule,
        RouterModule,
        SwiperModule,
        NumberAcceptModule,
        CommonHeaderComponent,
        FooterComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        ProductSuccessComponent,
        LoaderComponent,
        NodataComponent,
        AppMaterial,
        PipeModule, CurrencyPipeModule,
        PagerComponent, UpcommingComponent,
        SanitizeHtmlPipe,
        SettingSidbarComponent,
        PopoverComponent,
        AichatbotComponent,
        PaypalModalComponent,
        ThemeSettingComponent,
        BusinessDetailsComponent,
        ShopDetailsComponent,
        FreeShippingMethodComponent,
        FlatRateShippingMethodComponent,
        FedexShippingMethodComponent,
        UpsShippingMethodComponent,
        PaymentSettingsComponent,
        AuthorizeSettingsComponent,
        AISuggestion,
        AIQusSuggestion,
        TranslateModule
    ],
    declarations: [
        CommonHeaderComponent,
        FooterComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        ImagemanagerpopupComponent,
        ProductSuccessComponent,
        LoaderComponent,
        NodataComponent,
        SanitizeHtmlPipe,
        SettingSidbarComponent,
        PagerComponent, ConfirmationDialogComponent,
        AddCouponComponent, AddDeliveryComponent, UpcommingComponent, DeletePopupComponent, PopoverComponent, AichatbotComponent,
        PaypalModalComponent,
        ThemeSettingComponent,
        BusinessDetailsComponent,
        ShopDetailsComponent,
        FreeShippingMethodComponent,
        FlatRateShippingMethodComponent,
        FedexShippingMethodComponent,
        UpsShippingMethodComponent,
        PaymentSettingsComponent,
        AuthorizeSettingsComponent,
        AISuggestion,
        AIQusSuggestion,
        AISuggestionOperation
    ],
    entryComponents: [ImagemanagerpopupComponent, ProductSuccessComponent, AddCouponComponent, AddDeliveryComponent, DeletePopupComponent, ConfirmationDialogComponent, PaypalModalComponent, AISuggestion, AIQusSuggestion, AISuggestionOperation],
    providers: [ConfigService,BlogService,  ProductService, DatePipe, CustomerGroupService, PromocodeService, SettingsSandbox, RecordAudioService, CookieService, CommonService, SettingsService, PaymentSandbox, LoyaltyPromotionService, AISandbox, ProductSandbox, PaymentService, OrderService, OrderSandbox, { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
})
export class SharedModule {
}
