import { NgModule } from '@angular/core';
import { NgxAuthorizenetComponent } from './ngx-authorizenet.component';



@NgModule({
  declarations: [
    NgxAuthorizenetComponent
  ],
  imports: [
  ],
  exports: [
    NgxAuthorizenetComponent
  ]
})
export class NgxAuthorizenetModule { }
