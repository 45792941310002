import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../../../core/common/common.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
  status: boolean = false;
  public show = false;
  @Input('name') name = '';
  @Input('message') message = '';
  public menuData: any;

  constructor(
    public cookieService: CookieService,
    private commonService: CommonService,
  ) {

  }
  ngOnInit() {
    let show = this.cookieService.get(this.name);

    if (show == "1") {
      this.show = false;
    } else {
      let params = {};

      this.commonService.getHelpInformations(params).toPromise().then((response) => {
        let arr = response.data;

        this.menuData = arr.find(o => o.menuName === this.name);
        this.show = true;
      });
    }

  }

  change(event) {
    if (event.target.checked) {
      this.cookieService.set(this.name, "1");
    } else {
      this.cookieService.set(this.name, "0");
    }
  }

  clickEvent() {
    this.status = !this.status;

  }
}
