import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { RecordAudioService } from "../../../../core/record-audio/record-audio.service";
import { CommonService } from "../../../../core/common/common.service";
import { AISandbox } from "../../../../core/ai/ai.sandbox";
import { SettingsService } from "../../../../core/settings/settings.service";
import { Router } from "@angular/router";
import { ProductService } from "../../../../core/product/product.service";
import { ToastrService } from "ngx-toastr";
import { LoyaltyPromotionService } from "../../../../core/loyalty-promotion/loyalty-promotion.service";
import moment from "moment-timezone";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingsSandbox } from "../../../../core/settings/settings.sandbox";
import { PromocodeService } from "../../../../core/promocode/promocode.service";
import { DatePipe } from "@angular/common";
import { ProductSandbox } from "../../../../core/product/product.sandbox";

@Component({
    selector: 'ai-suggestion-modal',
    templateUrl: './ai-suggestion.component.html',
    styleUrls: ['./ai-suggestion.component.scss']
})
export class AISuggestion implements OnInit, AfterViewInit {

    public audioElement: any;
    public audioElementSource: any;
    public showTextArea = false;

    public subscriptions = [];

    public showPromotionForm = false;
    public promotionForm: FormGroup;

    public dropdownSettings = {};

    public response = null;

    public showPromotionValue = false;

    public speaking = false;
    public searching = false;

    public operationText = ``;
    public suggestedText = ``;
    public text = '';
    public voiceText = '';
    public isTextSearch = false;
    public showImagePrompt = false;
    public imagePrompt = '';



    public loading = false;
    constructor(
        public recorder: RecordAudioService,
        public aiSandbox: AISandbox,
        public loyaltyPromotionService: LoyaltyPromotionService,
        public commonService: CommonService,
        public settingService: SettingsService,
        public sandbox: SettingsSandbox,
        public productSandbox: ProductSandbox,
        private productService: ProductService,
        public datePipe: DatePipe,
        public toastr: ToastrService,
        public promocodeService: PromocodeService,
        public router: Router,
        public fb: FormBuilder,
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {


    }

    ngOnInit() {



        this.dropdownSettings = {
            singleSelection: false,
            idField: 'productId',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            allowSearchFilter: true
        };

        this.getProductList();

    }


    getProductList() {
        const params: any = {};
        params.limit = '';
        params.offset = '';
        params.count = '';
        params.keyword = '';
        this.productSandbox.getProductList(params);
    }

    ngAfterViewInit(): void {
        // this.audioElement = document.getElementsByClassName('audio-element')[0];
    }

    getOperationSuggestions() {
        if (!this.text) {
            return;
        }
        this.isTextSearch = true;
        this.searching = true;
        this.suggestedText = '';
        this.commonService.getRedirectUrl({
            prompt: this.text
        }).toPromise().then((response) => {

            console.log(response);
            this.searching = false;
            this.voiceText = response.voiceText;
            this.operationText = response.data;
            this.isTextSearch = false;
            if (this.operationText) {
                this.close();
                if (this.operationText === '/promotions/newsletter-template?create=1') {
                    localStorage.setItem("textForNewsletter", this.text);
                    this.router.navigate(['/promotions/newsletter-template'], { queryParams: { created: 1 } });
                } if (this.operationText === '/products/add') {
                    localStorage.setItem("textForProduct", this.text);
                    this.productService.insertProductInBackground({
                        prompt: this.text
                    }).toPromise().then(() => {
                        this.toastr.success('Your product is been created, you will find it under your products tab for review', 'Success')
                        this.close();
                    });
                } else {
                    this.router.navigate([this.operationText]);
                }

            } else {
                this.operationText = 'Could not find any action. Please try with a different phrase.'
            }
        }).catch(err => {
            this.searching = false;
            console.log(" **** err ", err);
        })
    }


    getSuggestions() {
        if (!this.text) {
            return;
        }
        this.isTextSearch = true;
        this.searching = true;
        this.showTextArea = false;
        this.showPromotionForm = false;
        this.suggestedText = '';
        this.commonService.getSuggestion({
            text: this.text
        }).toPromise().then((response) => {
            console.log("********  seggesiotn *******");
            console.log(response);

            this.voiceText = response.voiceText;
            this.suggestedText = response.data;
            this.isTextSearch = false;

            if (response.type == 1) {
                if (this.suggestedText) {
                    console.log(" ****  this.suggestedText ", this.suggestedText);
                    if (this.suggestedText.trim().startsWith('/promotions/create-promotional-codes')) {

                        this.promotionForm = this.fb.group({
                            description: [''],
                            promotionalCode: ['', Validators.required],
                            promotionalValue: [0.0],
                            type: ['', Validators.required],
                            startDate: ['', Validators.required],
                            endDate: ['', Validators.required],
                            productIds: [[]]
                        });

                        this.subscriptions.push(this.promotionForm.controls['type'].valueChanges.subscribe((data) => {
                            if ((data == 5) || (data == 2)) {
                                this.showPromotionValue = true;
                                this.promotionForm.controls['promotionalValue'].setValidators([Validators.required]);
                                this.promotionForm.controls['promotionalValue'].updateValueAndValidity();
                            } else {
                                this.showPromotionValue = false;
                                this.promotionForm.controls['promotionalValue'].clearValidators();
                                this.promotionForm.controls['promotionalValue'].updateValueAndValidity();
                            }
                        }));

                        this.commonService.parseLoyaltyPromotion({
                            prompt: this.text
                        }).toPromise().then((response) => {

                            this.getProductList();
                            this.response = response;
                            let types = {
                                "2 For 1": 1,
                                "Half Price": 3,
                                "Buy 1 Get 1 Free": 4,
                                "Percentage Discount": 5,
                                "Amount Discount": 2
                            }

                            this.promotionForm.controls['type'].setValue(types[response.data.promotionType] + "");
                            this.promotionForm.controls['promotionalValue'].setValue(response.data.amount == 'NA' ? 0 : parseFloat(response.data.amount));
                            this.promotionForm.controls['description'].setValue(response.data.description);
                            this.promotionForm.controls['promotionalCode'].setValue(response.data.code == 'NA' ? '' : response.data.code);

                            if (response.data.startDate !== 'NA') {
                                let dateVal = response.data.startDate.split('/');

                                const startDate = { day: +dateVal[0], month: +dateVal[1], year: +dateVal[2] };
                                this.promotionForm.controls['startDate'].setValue(startDate);
                            }

                            if (response.data.endDate !== 'NA') {
                                let dateVal = response.data.endDate.split('/');

                                const endDate = { day: +dateVal[0], month: +dateVal[1], year: +dateVal[2] };
                                this.promotionForm.controls['endDate'].setValue(endDate);
                            }

                            if (this.promotionForm.valid && (!this.text.toLowerCase().includes('product'))) {

                                this.searching = false;
                                this.showPromotionForm = false;
                                this.showImagePrompt = true;
                                this.close();
                                this.savePromocode();


                            } else {
                                this.searching = false;
                                this.showPromotionForm = true;
                            }


                            console.log(" **** response  ", response);
                        })
                    } else if (this.suggestedText.trim().startsWith('/promotions/create-loyalty-promotion')) {

                        this.promotionForm = this.fb.group({
                            description: [''],
                            promotionalValue: [0.0],
                            type: ['', Validators.required],
                            startDate: ['', Validators.required],
                            endDate: ['', Validators.required],
                            productIds: [[]]
                        });

                        this.subscriptions.push(this.promotionForm.controls['type'].valueChanges.subscribe((data) => {
                            if ((data == 5) || (data == 2)) {
                                this.showPromotionValue = true;
                                this.promotionForm.controls['promotionalValue'].setValidators([Validators.required]);
                                this.promotionForm.controls['promotionalValue'].updateValueAndValidity();
                            } else {
                                this.showPromotionValue = false;
                                this.promotionForm.controls['promotionalValue'].clearValidators();
                                this.promotionForm.controls['promotionalValue'].updateValueAndValidity();
                            }
                        }));

                        this.commonService.parseLoyaltyPromotion({
                            prompt: this.text
                        }).toPromise().then((response) => {

                            this.getProductList();
                            this.response = response;
                            let types = {
                                "2 For 1": 1,
                                "Half Price": 3,
                                "Buy 1 Get 1 Free": 4,
                                "Percentage Discount": 5,
                                "Amount Discount": 2
                            }

                            this.promotionForm.controls['type'].setValue(types[response.data.promotionType] + "");
                            this.promotionForm.controls['promotionalValue'].setValue(response.data.amount == 'NA' ? 0 : parseFloat(response.data.amount));
                            this.promotionForm.controls['description'].setValue(response.data.description);

                            if (response.data.startDate !== 'NA') {
                                let dateVal = response.data.startDate.split('/');

                                const startDate = { day: +dateVal[0], month: +dateVal[1], year: +dateVal[2] };
                                this.promotionForm.controls['startDate'].setValue(startDate);
                            }

                            if (response.data.endDate !== 'NA') {
                                let dateVal = response.data.endDate.split('/');

                                const endDate = { day: +dateVal[0], month: +dateVal[1], year: +dateVal[2] };
                                this.promotionForm.controls['endDate'].setValue(endDate);
                            }

                            if (this.promotionForm.valid && (!this.text.toLowerCase().includes('product'))) {

                                this.searching = false;
                                this.showPromotionForm = true;

                                this.displayImagePrompt();

                            } else {
                                this.searching = false;
                                this.showPromotionForm = true;
                            }


                            console.log(" **** response  ", response);
                        })
                    } else if (this.suggestedText.trim().startsWith('/promotions/newsletter-template')) {
                        this.searching = false;
                        this.close();
                        localStorage.setItem("textForNewsletter", this.text);
                        this.router.navigate(['/promotions/newsletter-template'], { queryParams: { created: 1 } });
                    } else if (this.suggestedText.trim().startsWith('/products/add')) {
                        this.searching = false;
                        localStorage.setItem("textForProduct", this.text);
                        this.productService.insertProductInBackground({
                            prompt: this.text
                        }).toPromise().then(() => {
                            this.toastr.success('Your product is been created, you will find it under your products tab for review', 'Success')
                            this.close();
                        });
                    } else {
                        this.searching = false;
                        this.close();
                        this.router.navigate([this.suggestedText]);
                    }

                } else {
                    this.operationText = 'Could not find any action. Please try with a different phrase.'
                }
            } else {
                this.searching = false;
                this.showTextArea = true;
            }

        }).catch(err => {
            this.searching = false;
            console.log(" **** err ", err);
        })
    }

    start() {
        console.log(" *** start ");
        this.recorder.start()
            .then(() => { //on success
                this.speaking = true;
                this.suggestedText = '';
                console.log("Recording Audio...")
            })
            .catch(error => { //on error
                this.speaking = false;
                //No Browser Support Error
                if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
                    console.log("To record audio, use browsers like Chrome and Firefox.");
                    //Error handling structure
                    switch (error.name) {
                        case 'AbortError': //error from navigator.mediaDevices.getUserMedia
                            console.log("An AbortError has occured.");
                            break;
                        case 'NotAllowedError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A NotAllowedError has occured. User might have denied permission.");
                            break;
                        case 'NotFoundError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A NotFoundError has occured.");
                            break;
                        case 'NotReadableError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A NotReadableError has occured.");
                            break;
                        case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
                            console.log("A SecurityError has occured.");
                            break;
                        case 'TypeError': //error from navigator.mediaDevices.getUserMedia
                            console.log("A TypeError has occured.");
                            break;
                        case 'InvalidStateError': //error from the MediaRecorder.start
                            console.log("An InvalidStateError has occured.");
                            break;
                        case 'UnknownError': //error from the MediaRecorder.start
                            console.log("An UnknownError has occured.");
                            break;
                        default:
                            console.log("An error occured with the error name " + error.name);
                    };
                }
            });
    }

    copyText(inputElement) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    }

    saveLoyalty() {
        this.searching = true;
        this.showPromotionForm = false;
        this.showImagePrompt = false;
        let params = {
            imageUrl: 'assets/imgs/loyal-promo-1.jpg',
            ...this.promotionForm.value,
            minimumPurchaseAmount: 0.1,
            maximumPurchaseAmount: 100,
            maxUsage: 1,
            imagePrompt: this.imagePrompt,
            maxUsagePerUser: 1,
            status: 0,
            productIds: this.promotionForm.controls['productIds'].value.map(data => data.productId),

        }
        this.imagePrompt = '';

        if (this.promotionForm.value.startDate) {
            const date = this.promotionForm.value.startDate;
            const fDate = new Date(date.year, date.month - 1, date.day);
            params.startDate = this.datePipe.transform(fDate, 'yyyy/MM/dd');
        }
        if (this.promotionForm.value.endDate) {
            const date = this.promotionForm.value.endDate;
            const eDate = new Date(date.year, date.month - 1, date.day);
            params.endDate = this.datePipe.transform(eDate, 'yyyy/MM/dd');
        }

        this.searching = false;
        this.showPromotionForm = false;

        this.toastr.success('Your loyalty promotion is being created in the background', 'Success')
        this.close();
        this.loyaltyPromotionService.createLoyaltyPromotion(params).toPromise().then((response) => {
            this.searching = false;


        });
    }


    displayImagePrompt() {

        if (this.promotionForm.valid) {
            this.showImagePrompt = true;
            this.showPromotionForm = false;
        }
    }

    goBack() {
        this.showImagePrompt = false;
        this.showPromotionForm = true;
    }


    savePromocode() {
        //  this.showImagePrompt = false;
        let params = {
            ...this.promotionForm.value,
            productIds: this.promotionForm.controls['productIds'].value.map(data => data.productId),
            minimumPurchaseAmount: 0.1,
            maximumPurchaseAmount: 100,

            maxUsage: 1,
            maxUsagePerUser: 1,
            status: 0,
            startDate: moment(this.response.data.startDate, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            endDate: moment(this.response.data.endDate, 'DD/MM/YYYY').format('YYYY/MM/DD')
        }

        if (this.promotionForm.value.startDate) {
            const date = this.promotionForm.value.startDate;
            const fDate = new Date(date.year, date.month - 1, date.day);
            params.startDate = this.datePipe.transform(fDate, 'yyyy/MM/dd');
        }
        if (this.promotionForm.value.endDate) {
            const date = this.promotionForm.value.endDate;
            const eDate = new Date(date.year, date.month - 1, date.day);
            params.endDate = this.datePipe.transform(eDate, 'yyyy/MM/dd');
        }

        this.searching = false;

        this.promocodeService.createPromocode(params).toPromise().then((response) => {
            this.searching = false;
            this.close();
            this.toastr.success('Your promocde has been created', 'Success')
        });

    }

    fillText() {
        console.log(" ***  this.suggestedText ", this.voiceText, this.suggestedText);
        this.aiSandbox.textSuccess({ text: this.suggestedText, voiceText: this.voiceText, type: 1 });
        this.send();
    }

    append() {
        console.log(" ***  this.suggestedText ", this.voiceText);
        this.aiSandbox.textSuccess({ text: this.suggestedText, voiceText: this.voiceText, append: true, type: 2 });
        this.send();
    }

    stop(type = 0) {
        console.log("** stopeed ")
        this.speaking = false;
        this.recorder.stop()
            .then(([buffer, audioAsblob]) => { //stopping makes promise resolves to the blob file of the recorded audio
                console.log("stopped with audio Blob:", audioAsblob);
                this.isTextSearch = true;
                if (type == 0) {
                    this.playAudio(audioAsblob);
                } else {
                    this.transcribeAudio(audioAsblob);
                }

            })
            .catch(error => {
                //Error handling structure
                switch (error.name) {
                    case 'InvalidStateError': //error from the MediaRecorder.stop
                        console.log("An InvalidStateError has occured.");
                        break;
                    default:
                        console.log("An error occured with the error name " + error.name);
                };

            });
    }

    playAudio(recorderAudioAsBlob) {

        //read content of files (Blobs) asynchronously
        let reader = new FileReader();
        console.log(" ***  recorderAudioAsBlob ", recorderAudioAsBlob);
        //once content has been read
        reader.onload = (e) => {
            //store the base64 URL that represents the URL of the recording audio
            let base64URL = e.target.result;


            this.searching = true;
            this.suggestedText = '';
            this.commonService.getSuggestion({
                audio: base64URL
            }).toPromise().then((response) => {
                this.searching = false;
                this.suggestedText = response.data.message.content;
                this.voiceText = response.voiceText;
                console.log(" *** response ", response);
            }).catch(err => {
                this.searching = false;
                console.log(" **** err ", err);
            })
            //If this is the first audio playing, create a source element
            //as pre populating the HTML with a source of empty src causes error
            // if (!this.audioElementSource) //if its not defined create it (happens first time only)
            //     this.createSourceForAudioElement();

            //set the audio element's source using the base64 URL
            //  this.audioElementSource.src = base64URL;

            //set the type of the audio element based on the recorded audio's Blob type
            // let BlobType = recorderAudioAsBlob.type.includes(";") ?
            //     recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(';')) : recorderAudioAsBlob.type;
            // this.audioElementSource.type = BlobType

            //call the load method as it is used to update the audio element after changing the source or other settings
            // this.audioElement.load();

            //play the audio after successfully setting new src and type that corresponds to the recorded audio
            // console.log("Playing audio...");
            // this.audioElement.play();


        };

        //read content and convert it to a URL (base64)
        reader.readAsDataURL(recorderAudioAsBlob);
    }

    speakText(textToSpeak) {
        let speakData = new SpeechSynthesisUtterance();
        speakData.volume = 1; // From 0 to 1
        speakData.text = textToSpeak;
        speakData.lang = 'en';

        speechSynthesis.speak(speakData);
    }

    transcribeAudio(recorderAudioAsBlob) {

        //read content of files (Blobs) asynchronously
        let reader = new FileReader();
        console.log(" ***  recorderAudioAsBlob ", recorderAudioAsBlob);
        //once content has been read
        reader.onload = (e) => {
            //store the base64 URL that represents the URL of the recording audio
            let base64URL = e.target.result;
            this.searching = true;
            this.suggestedText = '';
            this.commonService.getTranslation({
                audio: base64URL
            }).toPromise().then((response) => {
                this.searching = false;
                this.operationText = response.data;
                this.voiceText = response.voiceText;
                console.log(" *** response ", response);
            }).catch(err => {
                this.searching = false;
                console.log(" **** err ", err);
            })
            //If this is the first audio playing, create a source element
            //as pre populating the HTML with a source of empty src causes error
            // if (!this.audioElementSource) //if its not defined create it (happens first time only)
            //     this.createSourceForAudioElement();

            //set the audio element's source using the base64 URL
            //  this.audioElementSource.src = base64URL;

            //set the type of the audio element based on the recorded audio's Blob type
            // let BlobType = recorderAudioAsBlob.type.includes(";") ?
            //     recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(';')) : recorderAudioAsBlob.type;
            // this.audioElementSource.type = BlobType

            //call the load method as it is used to update the audio element after changing the source or other settings
            // this.audioElement.load();

            //play the audio after successfully setting new src and type that corresponds to the recorded audio
            // console.log("Playing audio...");
            // this.audioElement.play();


        };

        //read content and convert it to a URL (base64)
        reader.readAsDataURL(recorderAudioAsBlob);
    }

    createSourceForAudioElement() {
        let sourceElement = document.createElement("source");
        this.audioElement.appendChild(sourceElement);

        this.audioElementSource = sourceElement;
    }

    send() {
        this.dialogRef.close(true);
    }
    close() {
        this.dialogRef.close(false);
    }
}